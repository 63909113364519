import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Hooks from '../../components/hooks/Hooks.js';
import Loading from '../../components/loading/Loading';
// import {getWebhooks} from '../../podcastApi.js';
import {paystatusTranslation, secsToFormatedDate} from '../../utils/helperFuncs.js';
import './Invoices.scss';

/**
 * @return {User} React.fragment
 */
function Invoices({uData}) {
  // Rapyd
  const [rapydLoading, setrapydLoading] = useState(true);
  const [invoices, setInvoices] = useState();
  const [HooksDisp, setHooksDisp] = useState(false);
  const [cusid, setCusid] = useState();
  // const [hooks, setHooks] = useState();
  const openHooks = () => {
    setHooksDisp(true);
  };
  const isInvoiceWithinDiscountPeriod = (invoice) => {
    try {
      const invoiceCreatedAt = new Date(invoice.created_at);
      const discountStart = new Date(invoice.discount.start * 1000);
      const discountDurationMonths = invoice.discount.coupon.duration_in_months;
      const discountEnd = new Date(discountStart);
      discountEnd.setMonth(discountEnd.getMonth() + discountDurationMonths);
      return invoiceCreatedAt >= discountStart && invoiceCreatedAt <= discountEnd;
    } catch (e) {
      return false;
    };
  };

  const calculatePaidAmount = (invoice) => {
    return isInvoiceWithinDiscountPeriod(invoice) ? 0 : invoice.total;
  };
  /**
   * sæki hér plans hjá rapyd
   */
  useEffect( () => {
    if (!!uData) {
      setCusid(uData.rapydData.id);
      if (!!uData.invoices) {
        // {!!el.discount.coupon ? el.discount.coupon.percent_off + '% afsláttur. '+ (100 - el.discount.coupon.percent_off) * 1290 + ' Kr. greiddar': ''}
        const invs = uData.invoices.map((el, index)=>
          <div key={index} className="c-user-info-val">
            <div className="c-user-info-val-disp">{secsToFormatedDate(el.created_at/1000)}</div>
            {el.status == 'paid' ? <div className="c-user-info-val-val">{calculatePaidAmount(el)} Kr. - Steve Áskrift - {paystatusTranslation(el.status)}</div> :
              <Button label={el.subtotal + 'Kr. - Steve Áskrift - ' + paystatusTranslation(el.status)} className="p-button-text p-button-plain sd-exclude sd-cancel" icon="pi pi-info-circle" iconPos="right" onClick={openHooks}/>
            }
          </div>,
        );
        setInvoices(invs);
      }
      setrapydLoading(false);
    };
  }, [uData]);

  const navigate = useNavigate();
  const gotoSubscribe = () => {
    navigate('/minarsidur', {replace: false});
  };
  return (
    <div className="c-user-details">
      {<Loading isBlocked={rapydLoading}></Loading>}
      <Hooks openSkilmalar={HooksDisp} setskilmalarDisp={setHooksDisp} cusId={cusid}/>
      <div className="c-user-box">
        <section>
          <div className="c-user-info">
            <div className="c-user-info-val c-user-header">
              <div className="c-user-info-val-disp"><i className="pi pi-user mr-2"></i></div>
              <div className="c-user-info-val-val"><b>Greiðslusaga</b></div>
            </div>
            {invoices}
            <div className="c-user-info-val">
              <Button label="Til baka" className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" icon="pi pi-chevron-left" onClick={gotoSubscribe}/>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Invoices;
Invoices.propTypes = {
  uData: PropTypes.any,
};
