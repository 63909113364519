
/* eslint-disable valid-jsdoc */
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './Cookies.scss';
/**
* @return {header}
*/
export default function Cookies() {
  const products = [{
    name: 'G_ENABLED_IDPS',
    url: '365datascience.com',
    type: 'Functional',
    desc: 'The cookie is used by Google and is used for Google Single Sign On.',
    duration: '7978 years',
  }, {
    name: 'G_ENABLED_IDPS',
    url: '365datascience.com',
    type: 'Functional',
    desc: 'Used for secure login to the website with a Google account.',
    duration: '95745 month',
  }];
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const from = state ? (state.from.pathname || state.nextPath) : '/forsida';
  const goBack = () => {
    navigate(from, {state: {nextPath: '/', from: location}, replace: false});
  };
  /**
* @return {render}
*/
  return (
    <div className="c-sd-cookies-container">
      <div className="c-sd-cookies">
        <div className="c-sd-cookies-header">
          <h1>Kökurnar</h1>
        </div>
        <div className="c-sd-cookie-info">
          <h3>Um vefkökur</h3>
          <p>
            Vefkökur (e. cookies) eru litlar textaskrár sem eru vistaðar á tölvunni þinni eða öðrum snjalltækjum sem þú notar til að heimsækja vefsíðu í fyrsta sinn. Vefkökurnar gera það að verkum að vefsíðan man eftir þér og hvernig þú notaðir síðuna í hvert sinn sem þú heimsækir hana aftur. Vefkökur innihalda ekki persónuupplýsingar á borð við nafnið þitt, netfang, símanúmer eða kennitölu.
          </p>
          <h3>Hvernig við notum við vefkökur</h3>
          <p>
            Vefkökur sem teljast nauðsynlegar gera notandanum kleift að ferðast um vefsíðuna og nota þá virkni sem síðan býður upp á. Vefkökur sem notaðar eru til að bæta virkni vefsíðunnar og auka þannig þjónustu við notendur gera svo með því að t.d. muna hvaða vörur voru settar í körfu eða innskráningu á Mínar síður.
          </p>
          <h3>Kökurnar á stevadagskra.is</h3>
          <DataTable value={products} className="c-cookie-data" responsiveLayout="scroll">
            <Column field="name" header="Nafn"></Column>
            <Column field="url" header="Lén"></Column>
            <Column field="type" header="Tegund"></Column>
            <Column field="desc" header="Lýsing"></Column>
            <Column field="duration" header="Endist"></Column>
          </DataTable>
          <div className="c-cookies-back">
            <Button label="Til baka" id="cookies_btn" className="p-button-link font-medium no-underline ml-2  cursor-pointer c-nyskr-lnk sd-exclude" onClick={goBack}/>
          </div>
        </div>
      </div>
    </div>
  );
};
