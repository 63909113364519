/* eslint-disable indent */
/* eslint-disable comma-dangle */
import {getCoupons} from '../../podcastApi.js';
const data = async () => {
  const firebUsers = await getCoupons();
  console.log(firebUsers);
  return firebUsers;
};
export const firebaseUsers = {

  getData() {
   return data();
},

getCustomersSmall() {
   return Promise.resolve(this.getData().slice(0, 10));
},

getCustomersMedium() {
   return Promise.resolve(this.getData());
},

getCustomersLarge() {
   return Promise.resolve(this.getData().slice(0, 200));
},

getCustomersXLarge() {
   return Promise.resolve(this.getData());
},

};
