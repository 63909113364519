import 'font-awesome/css/font-awesome.min.css';
import {Button} from 'primereact/button';
import {Sidebar} from 'primereact/sidebar';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Skilmalar from '../../components/skilmalar/Skilmalar.js';
import './Footer.scss';
/**
 * @return {header}
 */
function Footer() {
  const [visibleBottom, setVisibleBottom] = useState(!localStorage.getItem('cookieAcknowledged'));
  const [skilmalarDisp, setskilmalarDisp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const cookieAccept = () => {
    setVisibleBottom(false);
    localStorage.setItem('cookieAcknowledged', true);
  };
  const nySkra = () => {
    navigate('/kokur', {state: {nextPath: '/', from: location}, replace: false});
  };
  const sendMail = () => {
    window.location = 'mailto:admin@stevedagskra.is';
  };
  const openSkilmalar = () => {
    setskilmalarDisp(true);
  };

  return (
    <footer>
      <Skilmalar openSkilmalar={skilmalarDisp} setskilmalarDisp={setskilmalarDisp}/>
      <div className="footer-container">
        <div className="c-footer-info">
          <ul>
            <li>
              <p className="css-1yy5aal">© 2022 Steve Dagskrá</p>
            </li>
            <li onClick={openSkilmalar}>
              <p className="css-1yy5aal">Skilmálar</p>
            </li>
            <li onClick={nySkra}>
              <p className="css-1yy5aal">Kökur</p>
            </li>
            <li onClick={sendMail}>
              <p className="css-1yy5aal">Hafa samband</p>
            </li>
          </ul>

        </div>
        <div className="social-container">
          <div>
            <span>
              <a href="https://open.spotify.com/show/6hdPMDRwV9s1s0MlSTwoBZ" target="_blank" rel="noopener noreferrer" >
                <i className="fa fa-spotify" style={{'fontSize': '2em', 'color': '#1ed760'}}></i>
              </a>
            </span>
            <span>
              <a href="https://soundcloud.com/stevedagskra" target="_blank" rel="noopener noreferrer" >
                <i className="fa fa-soundcloud" style={{'fontSize': '2em', 'color': '#ff5500'}}></i>
              </a>
            </span>
          </div>
          <div>
            <span>
              <a href="https://twitter.com/stevedagskra?lang=en" target="_blank" rel="noopener noreferrer" >
                <i className="pi pi-twitter" style={{'fontSize': '2em', 'color': '#1da1f2'}}></i>
              </a>
            </span>
            <span>
              <a href="https://www.instagram.com/stevedagskra/?hl=en" target="_blank" rel="noopener noreferrer" >
                <i className="pi pi-instagram" style={{'fontSize': '2em', 'color': '#c32aa3'}}></i>
              </a>
            </span>
          </div>
        </div>
      </div>
      <Sidebar visible={visibleBottom} position="bottom" className="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap cookie-banner" onHide={() => cookieAccept()} modal={false} dismissable>
        <div className="font-bold mr-8">Helvítis kökurnar</div>
        <div className="align-items-center hidden lg:flex">
          <span className="line-height-3">Þessi vefur notar vefkökur (e. cookies) til að bæta upplifun notenda.</span>
        </div>
        <Button label="Lesa meira" id="cookies_btn" className="p-button-link font-medium no-underline ml-2 sd-text-orange cursor-pointer c-nyskr-lnk sd-exclude" onClick={nySkra}/>
      </Sidebar>
    </footer>
  );
}
export default Footer;
