import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {Menubar} from 'primereact/menubar';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Navbar} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {googleLogoutFirebase} from '../../podcastApi.js';
import {Toast} from 'primereact/toast';
import Auth from '../../utils/Auth';
import './Header.scss';
/**
 * @return {header}
 */
function Header({uData, authenticated, isAdmin, payload}) {
  const [userName, setuserName] = useState();
  const [headerloading, setHeaderloading] = useState(true);
  const [hamburerOpen, sethamburerOpen] = useState(false);
  const [dropIcon, setDropicon] = useState(false);
  const [list, setList] = useState();
  const menu = useRef(null);
  const navigate = useNavigate();
  const handleOnClick = useCallback(() =>
    navigate('/innskraning', {replace: false}), [navigate]);
  const [end, setEnd] = useState();
  const handleLogout = useCallback(() => {
    const doProc = async () => {
      await googleLogoutFirebase();
      Auth.deauthenticateUser();
      window.location.href = '/';
    };
    doProc();
  }, []);
  const toastBC = useRef(null);
  const showConfirm = (payload) => {
    if (payload?.notification) {
      toastBC.current.show({severity: 'info', sticky: true, content: (
        <div className="flex flex-row" style={{flex: '1'}}>
          <div className="p-2">
            <img src={payload?.notification?.image} style={{width: '56px'}}></img>
          </div>
          <div className="flex flex-column align-items-center text-center">
            <div><b>{payload?.notification?.title}</b></div>
            <p>{payload?.notification?.body}</p>
          </div>
        </div>
      )});
    };
  };
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!payload) {
      showConfirm(payload);
    };
  }, [payload]);
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!uData) {
      setuserName(uData?.gData?.name);
    };
  }, [uData]);
  const hiddenMenu = () => {
    sethamburerOpen(false);
  };
  const shownMenu = () => {
    sethamburerOpen(true);
  };
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (authenticated !== undefined) {
      if (authenticated) {
        setEnd(<div>
          <Menu model={list} popup ref={menu} className="p-header-menu" onHide={hiddenMenu} onShow={shownMenu} id="popup_menu"></Menu>
          {dropIcon}
        </div>);
      } else {
        setEnd(<Button label="Innskráning" className="p-button-outlined p-button-secondary c-sd-black c-sd-login-btn" icon="pi pi-user" iconPos="left" onClick={handleOnClick}/>);
      };
      setHeaderloading(false);
    };
  }, [authenticated, dropIcon]);


  const getUserName = () => {
    return <>Hæ, <br></br>{userName}</>;
  };
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!isAdmin) {
      setList( [
        {
          label: getUserName() || '',
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Forsíða',
              icon: 'pi pi-fw pi-home',
              command: (e) => {
                navigate('/forsida', {replace: false});
              },
            },
            {
              label: 'Þættir',
              icon: 'pi pi-fw pi-play',
              command: (e) => {
                navigate('/hlusta', {replace: false});
              },
            },
            {
              label: 'Sjónvarpsþættir',
              icon: 'pi pi-fw pi-youtube',
              command: (e) => {
                navigate('/horfa', {replace: false});
              },
            },
            {
              label: 'Streymi',
              icon: 'pi pi-fw pi-video',
              command: (e) => {
                navigate('/streymi', {replace: false});
              },
            },
            {
              label: 'Pubquiz',
              icon: 'pi pi-fw pi-question',
              command: (e) => {
                navigate('/pubquiz', {replace: false});
              },
            },
            {
              label: 'Mínar síður',
              icon: 'pi pi-fw pi-user',
              command: (e) => {
                navigate('/minarsidur', {replace: false});
              },
            },
            {
              label: 'Útskráning',
              icon: 'pi pi-fw pi-power-off',
              command: (e) => {
                handleLogout();
              },
            },
          ],
        },
      ]);
    } else {
      setList([
        {
          label: getUserName() || '',
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Forsíða',
              icon: 'pi pi-fw pi-home',
              command: (e) => {
                navigate('/forsida', {replace: false});
              },
            },
            {
              label: 'Þættir',
              icon: 'pi pi-fw pi-play',
              command: (e) => {
                navigate('/hlusta', {replace: false});
              },
            },
            {
              label: 'Sjónvarpsþættir',
              icon: 'pi pi-fw pi-youtube',
              command: (e) => {
                navigate('/horfa', {replace: false});
              },
            },
            {
              label: 'Streymi',
              icon: 'pi pi-fw pi-video',
              command: (e) => {
                navigate('/streymi', {replace: false});
              },
            },
            {
              label: 'Pubquiz',
              icon: 'pi pi-fw pi-question',
              command: (e) => {
                navigate('/pubquiz', {replace: false});
              },
            },
            {
              label: 'Mínar síður',
              icon: 'pi pi-fw pi-user',
              command: (e) => {
                navigate('/minarsidur', {replace: false});
              },
            },
            {
              label: 'Kerfisstjóri',
              icon: 'pi pi-fw pi-users',
              command: (e) => {
                navigate('/admin/gogn', {replace: false});
              },
            },
            {
              label: 'Útskráning',
              icon: 'pi pi-fw pi-power-off',
              command: (e) => {
                handleLogout();
              },
            },
          ],
        },
      ]);
    }
  }, [isAdmin, userName]);
  const toggleMenu = (event) => {
    menu.current.toggle(event);
  };
  useEffect( () => {
    setDropicon(
        <div className="c-sd-hamburger nav-container"><input className="checkbox" checked={hamburerOpen} type="checkbox" onChange={toggleMenu}
          aria-controls="popup_menu" aria-haspopup name="" id="" />
        <div className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div> </div>);
  }, [hamburerOpen]);

  return (
    <header>
      <Navbar sticky="top" fixed="top">
        <Toast ref={toastBC}/>
        <Menubar
          start={<React.Fragment><div className="heading sd-font">
            <div className="heading-image">
              <Link to={authenticated ? '/forsida' : '/'}><div><span className="header-site-title c-sd-black">Steve Dagskrá</span></div></Link>
            </div>
          </div>
          </React.Fragment>}
          /* model={items2}*/
          end = { headerloading ? '' : end }
        />
      </Navbar>
    </header>
  );
}
export default Header;
Header.propTypes = {
  uData: PropTypes.any,
  authenticated: PropTypes.any,
  isAdmin: PropTypes.any,
  payload: PropTypes.any,
};
