import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
// import "primereact/resources/themes/md-light-deeppurple/theme.css";
/**
 * primereact/resources/themes/bootstrap4-light-blue/theme.css
primereact/resources/themes/bootstrap4-light-purple/theme.css
primereact/resources/themes/bootstrap4-dark-blue/theme.css
primereact/resources/themes/bootstrap4-dark-purple/theme.css
primereact/resources/themes/md-light-indigo/theme.css
primereact/resources/themes/md-light-deeppurple/theme.css
primereact/resources/themes/md-dark-indigo/theme.css
primereact/resources/themes/md-dark-deeppurple/theme.css
primereact/resources/themes/mdc-light-indigo/theme.css
primereact/resources/themes/mdc-light-deeppurple/theme.css
primereact/resources/themes/mdc-dark-indigo/theme.css
primereact/resources/themes/mdc-dark-deeppurple/theme.css
primereact/resources/themes/tailwind-light/theme.css
primereact/resources/themes/fluent-light/theme.css
primereact/resources/themes/lara-light-blue/theme.css
primereact/resources/themes/lara-light-indigo/theme.css
primereact/resources/themes/lara-light-purple/theme.css
primereact/resources/themes/lara-light-teal/theme.css
primereact/resources/themes/lara-dark-blue/theme.css
primereact/resources/themes/lara-dark-indigo/theme.css
primereact/resources/themes/lara-dark-purple/theme.css
primereact/resources/themes/lara-dark-teal/theme.css
primereact/resources/themes/saga-blue/theme.css
primereact/resources/themes/saga-green/theme.css
primereact/resources/themes/saga-orange/theme.css
primereact/resources/themes/saga-purple/theme.css
primereact/resources/themes/vela-blue/theme.css
primereact/resources/themes/vela-green/theme.css
primereact/resources/themes/vela-orange/theme.css
primereact/resources/themes/vela-purple/theme.css
primereact/resources/themes/arya-blue/theme.css
primereact/resources/themes/arya-green/theme.css
primereact/resources/themes/arya-orange/theme.css
primereact/resources/themes/arya-purple/theme.css
primereact/resources/themes/nova/theme.css
primereact/resources/themes/nova-alt/theme.css
primereact/resources/themes/nova-accent/theme.css
primereact/resources/themes/luna-amber/theme.css
primereact/resources/themes/luna-blue/theme.css
primereact/resources/themes/luna-green/theme.css
primereact/resources/themes/luna-pink/theme.css
primereact/resources/themes/rhea/theme.css
 */
import 'primereact/resources/themes/lara-light-blue/theme.css';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import './App.css';
import Audioplayer from './components/audioplayer/Audioplayer';
import Layout from './components/layout/Layout';
import './components/layout/layout.scss';
import Tabmenu from './components/tabmenu/Tabmenu';
import {addData, getGoogleAuth, getUser, onMessageListener} from './podcastApi.js';
import {getSubinvoice, getSubscription, getUserinfov2} from './rapydApi.js';
import Addepisode from './routes/addepisode/Addepisode';
import Admin from './routes/admin/Admin';
import Askriftir from './routes/askriftir/Askriftir';
import Blog from './routes/blog/Blog';
import Cookies from './routes/cookies/Cookies';
import Coupons from './routes/coupons/Coupons';
import Episodes from './routes/episodes/Episodes';
import Forsida from './routes/forsida/Forsida';
import Giftcard from './routes/giftcard/Giftcard';
import Offer from './routes/offer/Offer';
import Hlusta from './routes/hlusta/Hlusta';
import Home from './routes/home/Home';
import Invoices from './routes/invoices/Invoices';
import Pubquiz from './routes/pubquiz/Pubquiz.js';
import Login from './routes/login/Login';
import NotFound from './routes/not-found/NotFound';
import Payerror from './routes/payerror/Payerror';
import Register from './routes/register/Register';
import Stream from './routes/stream/Stream';
import User from './routes/user/User';
import Video from './routes/video/Video';
import Charts from './routes/charts/Charts';
// import './sd-theme.scss';
import {setGoogleLoginData} from './tokenUtils';
import RequireAdmin from './utils/RequireAdmin';
import RequireAuth from './utils/RequireAuth';
// import 'primereact/resources/themes/luna-amber/theme.css';
// import 'primereact/resources/themes/md-dark-deeppurple/theme.css';
/**
 * main function
 * @return {app} main app
 */
function App() {
  const [authenticated, setAuthenticated] = useState();
  const [isAdmin, setisAdmin] = useState();
  const [refreshKey, setrefreshKey] = useState(0);
  const [uData, setUdata] = useState(secureLocalStorage.getItem('userData'));
  const [gauth, setGauth] = useState();
  const [audioinstance, setaudioinstance] = useState();
  const [audioStatus, setaudioStatus] = useState();
  const [currentEpisode, setcurrentEpisode] = useState();
  const [audioList, setaudioList] = useState([]);
  const [currentTime, setcurrentTime] = useState(0);
  const [notification, setNotification] = useState({title: '', body: ''});

  onMessageListener().then((payload) => {
    console.log(payload);
    setNotification(payload);
  }).catch((err) => console.log('failed: ', err));
  useEffect( () => {
    // Sæki google auth
    let mount = true;
    // Sæki google auth
    if (mount) {
      const getData = async () => {
        const auth = await getGoogleAuth();
        auth.onAuthStateChanged( async (authUser) => {
          if ( authUser ) {
            setAuthenticated(true);
            setGauth(authUser);
          } else {
            setAuthenticated(false);
            setGauth(null);
          };
        });
      };
      getData();
    };
    return () => {
      mount = false;
    };
  }, [refreshKey]);

  const refreshData = (refreshGoogle = true, refreshRapyd = true) => {
    const getData = async () => {
      // ----------------------
      /* let timestampAge = 9999;
      const renderTime = new Date().getTime();
      if (!!secureData) {
        timestampAge = (renderTime - secureData.timeStamp) / (1000);
      }
      // Ef eldri en 15 sek, endyrnýja
      if (!secureData || timestampAge > 15) {
      };*/
      // ---------------------
      const userData = {};
      const googleData = await getUser();
      userData.googleData = googleData;
      try {
        if (googleData.isAdmin) {
          setisAdmin(true);
        } else {
          setisAdmin(false);
        }
      } catch (e) {
        setisAdmin(false);
      }
      if (googleData) {
        const epStatus = JSON.stringify(googleData.episodeStatus || {});
        localStorage.setItem('episodeStatus', epStatus);
      };
      // SÆki viðbótargögn í firebase
      if (refreshRapyd) {
        try {
          let rapydUser;// = await getUserinfo(authUser.email);
          // Sæki rapyd notanda, ef hann finnst ekki bý ég hann til með addData
          if (!!userData.googleData.rapyd.id) {
            rapydUser = await getUserinfov2(userData.googleData.rapyd.id);
          } else {
            rapydUser = await addData(authUser.email, authUser.displayName);
          };
          if (rapydUser?.status?.status=='SUCCESS' && rapydUser?.data) {
            userData.rapydData = rapydUser.data;
            if ( !!rapydUser.data.subscriptions ) {
              const subsArr = rapydUser.data.subscriptions.data;
              userData.allSubscription = subsArr;
              const sub = rapydUser.data.subscriptions.data[0];
              switch (sub.status) {
                case 'active':
                  userData.isSub = true;
                  break;
                case 'unpaid':
                  userData.isSub = false;
                  const subDetails = await getSubscription(sub.id);
                  if (subDetails.status.status == 'SUCCESS') {
                    userData.activeSub = subDetails.data;
                    const tmpCard = rapydUser.data.payment_methods.data.find((o) => o.id === subDetails.data.payment_method);
                    userData.activeSubPaymentinfo = tmpCard;
                  };
                  try {
                    const subInvoice = await getSubinvoice(sub.id);
                    if (subInvoice.status.status == 'SUCCESS') {
                      userData.invoices = subInvoice.data;
                      userData.paymentStatus = subInvoice.data[0].status;
                      userData.Offerpayment = {sub: sub, subInvoice: subInvoice};
                    };
                  } catch (e) {
                    console.log(e);
                  };
                  break;
                case 'canceled':
                  // Hefur ekki sub hjá rapyd
                  userData.isSub = false;
                  localStorage.removeItem('lastPlayStatus');
                  break;
                case 'trialing':
                  userData.isSub = true;
                  break;
                case 'pastdue':
                  userData.isSub = false;
                  localStorage.removeItem('lastPlayStatus');
                  break;
                default:
                  break;
              }
            } else {
              userData.isSub = false;
              localStorage.removeItem('lastPlayStatus');
            };
          } else {
            userData.rapydError = true;
          };
        } catch (e) {
          userData.rapydError = true;
        };
      };
      try {
        if (googleData?.isMedia) {
          userData.isSub = true;
          userData.isMedia = true;
        };
      } catch (e) {
      };
      userData.googleData = googleData;
      userData.timeStamp = new Date().getTime();
      userData.gData = googleData;
      secureLocalStorage.setItem('userData', userData);
      setUdata(userData);
    }; // Function end
    getData();
  };
  /*
   * Sæki hér notanda upplýsingar
   */
  useEffect( () => {
    let mount = true;
    if (mount) {
      if (gauth) {
        // Ef eldri en 15 sek, endyrnýja
        refreshData();
      } else {
        setUdata(null);
      };
    };
    return () => {
      mount = false;
      // setUdata(null);
    };
  }, [gauth]);

  return (
    <Router>
      <div className="App container">
        <Audioplayer
          authenticated={authenticated}
          setaudioStatus={setaudioStatus}
          currentEpisode={currentEpisode}
          setcurrentEpisode={setcurrentEpisode}
          audioList={audioList}
          setAudioList={setaudioList}
          audioInstance={audioinstance}
          setAudioInstance={setaudioinstance}
          setcurrentTime={setcurrentTime}
          currentTime={currentTime}
        >
          <Layout uData={uData} setrefreshKey={setrefreshKey} refreshKey={refreshKey} setAuthenticated={setAuthenticated} authenticated={authenticated} isAdmin={isAdmin} payload={notification}>
            <Routes>
              <Route exact path="/" element={<Home authenticated={authenticated} setAudioList={setaudioList}
                setcurrentEpisode={setcurrentEpisode}
                setcurrentTime={setcurrentTime}
                audioInstance={audioinstance}
                currentEpisode={currentEpisode}
                audioStatus={audioStatus}
                currentTime={currentTime}/>} />
              <Route exact path="/blackfriday" element={<Giftcard authenticated={authenticated}
                uData={uData}/>} />
              <Route exact path="/gjafabref" element={<Giftcard authenticated={authenticated}
                uData={uData}/>} />
              <Route exact path="/tilbod" element={<Offer authenticated={authenticated}
                uData={uData}/>} />
              <Route exact path="/pubquiz" element={<Pubquiz />} />
              <Route exact path="/innskraning" element={
                <Login
                  setGoogleLoginData={setGoogleLoginData}
                  refreshData={refreshData} />}
              />
              <Route path="/:id" element={<Blog uData={uData}
                authenticated={authenticated}
                setAudioList={setaudioList}
                setcurrentEpisode={setcurrentEpisode}
                setcurrentTime={setcurrentTime}
                audioInstance={audioinstance}
                currentEpisode={currentEpisode}
                audioStatus={audioStatus}
                currentTime={currentTime}/>} />
              <Route element={<RequireAuth authenticated={authenticated} />}>
                <Route path="/forsida" element={<Forsida uData={uData}
                  setAudioList={setaudioList}
                  setcurrentEpisode={setcurrentEpisode}
                  setcurrentTime={setcurrentTime}
                  audioInstance={audioinstance}
                  currentEpisode={currentEpisode}
                  audioStatus={audioStatus}
                  currentTime={currentTime}
                  refreshData={refreshData}
                />} />
                <Route path="/hlusta" element={<Hlusta uData={uData}
                  setAudioList={setaudioList}
                  setcurrentEpisode={setcurrentEpisode}
                  setcurrentTime={setcurrentTime}
                  audioInstance={audioinstance}
                  currentEpisode={currentEpisode}
                  audioStatus={audioStatus}
                  currentTime={currentTime}
                />} />
                <Route path="/minarsidur" element={<User uData={uData}/>} />
                <Route path="/kvittanir" element={<Invoices uData={uData}/>} />
                <Route path="/askriftir" element={<Askriftir authenticated={authenticated} uData={uData} refreshData={refreshData} />} />
                <Route path="/hlusta" element={<Episodes uData={uData}/>} />
                <Route path="/horfa" element={<Video uData={uData} />} />
              </Route>
              <Route path="/nyskra" element={<Register refreshData={refreshData}/>} />
              <Route path="/streymi" element={<Stream uData={uData}/>} />
              <Route path="/payerror" element={<Payerror />} />
              <Route path="/kokur" element={<Cookies />} />
              <Route path="*" element={<NotFound />} />
              <Route element={<RequireAdmin isAdmin={isAdmin} />}>
                <Route element={<Tabmenu/>}>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/admin/gjafabref" element={<Coupons />} />
                  <Route path="/admin/thaettir" element={<Addepisode />} />
                  <Route path="/admin/gogn" element={<Charts/>} />
                </Route>
              </Route>
            </Routes>
          </Layout>
        </Audioplayer>
      </div>
    </Router>
  );
}

export default App;
