/**
 * function that sets Steve login token to localstorage
 * @param {token} userToken
 */
function setToken(userToken) {
  localStorage.setItem('token', JSON.stringify(userToken.token));
  localStorage.setItem('isAuthenticated', true);
  localStorage.setItem('userInfo', JSON.stringify(userToken.userInfo));
  localStorage.setItem('authType', JSON.stringify(userToken.authType));
}

/**
 * @return {token} returns the localstorage token
 */
function getToken() {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}
/**
 * function that sets google's token to localstorage
 * @param {token} googleToken
 */
function setGoogleLoginData(googleToken) {
  localStorage.setItem('googleLoginData', googleToken);
}

module.exports = {
  setToken,
  getToken,
  setGoogleLoginData,
};
