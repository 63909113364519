import PropTypes from 'prop-types';
import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Loading from '../components/loading/Loading';
/**
 * function for protected routes
 * @return {boolean}
 */
export default function RequireAuth({authenticated}) {
  const location = useLocation();
  if (authenticated === undefined) {
    return <Loading isBlocked={true}></Loading>;
  };
  if (!authenticated) {
    return <Navigate replace to='/' state={{from: location}} />;
  }
  return <Outlet />;
};
RequireAuth.propTypes = {
  authenticated: PropTypes.any,
};
