import secureLocalStorage from 'react-secure-storage';
/**
 * class Auth for authentication purposes
 */
class Auth {
  /**
   * Function returns if user is authenticated
   * @return {string} name
   */
  static getUserName() {
    const user = JSON.parse(localStorage.getItem('userInfo')) ?
      JSON.parse(localStorage.getItem('userInfo')).name : 'Notandi';
    return user;
  }
  /**
   * Function returns if user is authenticated
   * @return {string} name
   */
  static getuserInfo() {
    const user = JSON.parse(localStorage.getItem('userInfo')) ?
      JSON.parse(localStorage.getItem('userInfo')) : {};
    return user;
  }
  /**
   * Function deauthenticates user
   */
  static deauthenticateUser() {
    localStorage.removeItem('authType');
    localStorage.removeItem('lastPlayId');
    secureLocalStorage.removeItem('userData');
    localStorage.removeItem('lastPlayStatus');
    localStorage.removeItem('episodeStatus');
  }
}
export default Auth;
