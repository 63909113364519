/* eslint-disable valid-jsdoc */
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
// import {useNavigate} from 'react-router-dom';
import {addUserpaymentmethod} from '../../rapydApi.js';
import './CcForm.scss';
/**
 * @return {number} number
 */
function _handlingMonthYear(number) {
  return number
      .replace(
          /^([1-9]\/|[2-9])$/g, '0$1/',
      ).replace(
          /^(0[1-9]|1[0-2])$/g, '$1/',
      ).replace(
          /^([0-1])([3-9])$/g, '0$1/$2',
      ).replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2',
      ).replace(
          /^([0]+)\/|[0]+$/g, '0',
      ).replace(
          /[^\d\/]|^[\/]*$/g, '',
      ).replace(
          /\/\//g, '/',
      );
}
/**
 * @return {boolean} ans
 */
function validForm(card1, cardOwner, expDate, cvc) {
  if ( card1 && cardOwner && expDate && cvc ) {
    return false;
  }
  return true;
};
/**
* @return {header}
*/
function CcForm({refreshKey, setRefreshKey, cusId, setBlockedDocument, refreshData}) {
  const toast = useRef(null);
  const [loading2, setLoading2] = useState(false);
  // const navigate = useNavigate();
  // card info
  const [cardOwner, setcardOwner] = useState();
  const [card1, setcard1] = useState();
  const [expDate, setexpDate] = useState();
  const [cvc, setcvc] = useState();
  const [confirmDisabled, setconfirmDisabled] = useState(true);
  const [ccValid, setccValid] = useState(false);
  const [expValid, setexpValid] = useState(false);
  const [cvcValid, setcvcValid] = useState(false);

  // hook fyrir valda kortategund, visa eða mastercard
  const [cardType, setcardType] = useState('');
  // card error hooks
  const [ccNumberErr, setccNumberErr] = useState('');

  // Stilli hér kortategund
  const cardInput1 = (e) => {
    if (e.target.value!=undefined) {
      if (e.target.value.charAt(0)=='4') {
        // visa
        setcardType('is_visa_card');
        setccNumberErr('');
      } else if (e.target.value.charAt(0)=='5') {
        // mastercard
        setcardType('is_mastercard_card');
        setccNumberErr('');
      } else if (e.target.value =='') {
        setccNumberErr('');
      } else {
        setccNumberErr('p-invalid');
      };
    }
    if (e.target.value.length==16) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
      setccValid(true);
      setconfirmDisabled(validForm(true, expValid, cvcValid, cardOwner));
    } else {
      setccValid(false);
      setconfirmDisabled(validForm(false, expValid, cvcValid, cardOwner));
    };
    setcard1(e.target.value.replace(/\D/g, ''));
  };
  // exp stillingar
  const cardInput5 = (e) => {
    if (e.target.value.length==5) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
      setexpValid(true);
      setconfirmDisabled(validForm(ccValid, true, cvcValid, cardOwner));
    } else {
      setexpValid(false);
      setconfirmDisabled(validForm(ccValid, false, cvcValid, cardOwner));
    };
    setexpDate(_handlingMonthYear(e.target.value));
  };
  // öryggisnr stillingar
  const cardInput6 = (e) => {
    if (e.target.value.length==3) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
      setcvcValid(true);
      setconfirmDisabled(validForm(ccValid, expValid, true, cardOwner));
    } else {
      setcvcValid(false);
      setconfirmDisabled(validForm(ccValid, expValid, false, cardOwner));
    };
    setcvc(e.target.value);
  };

  /**
   * Main function to add credit card to user
   * First sets loading on button, then the whole document.
   *
   * It posts to rapyd the credit card info. In Iceland a 3d
   * hook should appear on users screen.
   *
   */
  const addCard = () => {
    const getData = async () => {
      setLoading2(true);
      setBlockedDocument(true);
      let postData;
      try {
        postData = await addUserpaymentmethod(cardType, card1.replaceAll('-', ''), expDate.split('/')[0], expDate.split('/')[1], cvc, cardOwner, cusId);
      } catch (e) {
        toast.current.show({severity: 'error', summary: 'Villa að bæta við korti', detail: 'Gátum ekki bætt við korti', life: 5000});
      };
      if (postData.status.status=='SUCCESS') {
        if (postData.data.redirect_url) {
          window.location.href = postData.data.redirect_url;
        } else {
          refreshData();
          setRefreshKey(refreshKey + 1);
        };
      } else {
        switch (postData.status.error_code) {
          case 'INVALID_CARD_NUMBER':
            toast.current.show({severity: 'error', summary: 'Villa að bæta við korti', detail: 'Ekki gilt kortanúmer'});
          default:
            toast.current.show({severity: 'error', summary: 'Villa að bæta við korti', detail: postData.status?.message, life: 5000});
        }
      };
      setLoading2(false);
      // setBlockedDocument(false);
    };
    getData();
  };
  return (
    <>
      <div className="c-cc-form">
        <Toast ref={toast}></Toast>
        <form>
          <div className="card-info-form">
            <div>
              <div className="card">
                <div className="p-fluid formgrid grid">
                  <div className="field col-12 md:col-12">
                    <label htmlFor="basic">Nafn korthafa</label>
                    <InputText value={cardOwner} required={true} autoComplete="cc-name" tabIndex="1" onChange={(e) => setcardOwner(e.target.value)} />
                  </div>
                  <div className="field col-12 md:col-12">
                    <label htmlFor="basic">Kortanúmer</label>
                    <div className="card-inputs">
                      <div className="field col-12 md:col-12">
                        <span className="p-input-icon-left">
                          <i className="pi pi-credit-card" />
                          <InputText className={ccNumberErr} required={true} autoComplete="cc-number" id="card1" type="tel" inputMode="numeric" maxLength="16" tabIndex="2" value={card1} onChange={(e) => cardInput1(e)}></InputText>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="ssn">Gildistími</label>
                    <span className="p-input-icon-left">
                      <i className="pi pi-calendar" />
                      <InputText id="ssn" name="cc-exp" required={true} autoComplete="cc-exp" tabIndex="3" value={expDate} type="tel" inputMode="numeric" maxLength="5" placeholder="mm/yy" onChange={(e) => cardInput5(e)}></InputText>
                    </span>
                  </div>
                  <div className="field col-12 md:col-2">
                    <label htmlFor="cvc">Öryggisnúmer</label>
                    <InputText id="cvc" name="cccvc" required={true} autoComplete="cc-csc" mask="999" tabIndex="4" value={cvc} type="tel" inputMode="numeric" maxLength="3" placeholder="XXX" onChange={(e) => cardInput6(e)}></InputText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-submit">
            <Button label="Bæta við korti" tabIndex="5" disabled={confirmDisabled} className="w-full p-button-lg" loading={loading2} onClick={addCard} />
          </div>
        </form>
      </div>
    </>
  );
};
export default CcForm;
CcForm.propTypes = {
  cusId: PropTypes.any,
  setUsercc: PropTypes.func,
  usercc: PropTypes.any,
  refreshKey: PropTypes.any,
  setRefreshKey: PropTypes.any,
  setBlockedDocument: PropTypes.any,
  refreshData: PropTypes.any,
};
