
/* eslint-disable valid-jsdoc */
import {Button} from 'primereact/button';
import {Image} from 'primereact/image';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import errimg from '../../images/carderr.png';
import './Payerror.scss';
/**
* @return {header}
*/
export default function Payerror() {
  const navigate = useNavigate();
  const getSubscriptionPage = () => {
    navigate('/askriftir', {replace: true});
  };
  /**
* @return {render}
*/
  return (
    <div className="c-sd-aboutus-container c-font">
      <div className="c-sd-aboutus">
        <div className="c-sd-host c-sd-host-left" >
          <div className="c-sd-host-img">
            <Image src={errimg} template="p-image-action" alt="Image Text" />
          </div>
          <div className="c-sd-host-info">
            <div className="c-sd-host-txt">
              <p>
                Það virðist hafa komið upp villa í greiðslu eða að bæta við korti.<br></br><br></br>
                Ef þú varst að kaupa gjafabréf.<br></br>
                1. Athugaðu hvort heimild sé á kortinu<br></br>
                2. Athugaðu hvort kortið sé útrunnið <br></br>
                Reyndu svo aftur<br></br><br></br>
                Ef þú ert að reyna bæta við korti, prufaðu að: <br></br>
                1. Eyða kortinu í áskriftarsíðunni.<br></br>
                2. Bæta við nýju korti, passaðu að allar upplýsingar séu réttar.<br></br><br></br>
                Ef vandamálið heldur áfram þá geturu prufað annað kort <br></br>
                eða haft samband við <a href="mailto:admin@stevedagskra.is  ">admin@stevedagskra.is</a>
                <br></br>
                og við græjjum þetta
              </p>
            </div>
            <Button id="c_buy_sub" className="p-button-lg" style={{fontFamily: 'markPro'}} onClick={getSubscriptionPage}>Aftur á greiðslusíðu</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
