/* eslint-disable indent */
/* eslint-disable quote-props */
import {FilterMatchMode} from 'primereact/api';
import {Button} from 'primereact/button';
import {Chips} from 'primereact/chips';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {classNames} from 'primereact/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import Loading from '../../components/loading/Loading';
import {addCoupon} from '../../podcastApi.js';
import {createCoupon} from '../../rapydApi.js';
import {firebaseUsers} from './api';
import './Coupons.scss';

/**
 * @return {User} React.fragment
 */
function Coupons({uData}) {
  const toast = useRef(null);
  const [codes, setcodes] = useState([]);
  const [percent, setPercent] = useState(100);
  const [months, setMonths] = useState(3);
  const [users, setUsers] = useState(null);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

 // hér
 useEffect(() => {
  firebaseUsers.getCustomersMedium().then((data) => {
      setUsers(getFbuserdata(data));
      setLoading(false);
 });
  initFilters();
}, [refresh]);

const getFbuserdata = (data) => {
  return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
 });
};

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      const _filters = {...filters};

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
 };

  const initFilters = () => {
      setFilters({
          global: {value: null, matchMode: FilterMatchMode.CONTAINS},
          redeemed: {value: null, matchMode: FilterMatchMode.EQUALS},
          code: {value: null, matchMode: FilterMatchMode.EQUALS},
     });
      setGlobalFilterValue('');
 };
 const showForm = () => {
   setVisible(!visible);
 };

  const renderHeader = () => {
      return (
          <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Leita..." />
                  <Button type="button" icon="pi pi-plus" className="sd-exclude" onClick={showForm}></Button>
              </span>
          </div>
      );
 };

 const isSubtemplate = (rowData) => {
  return <i className={classNames('pi', {'text-green-500 pi-check-circle': rowData.redeemed, 'text-red-500 pi-times-circle': !rowData.redeemed})}></i>;
};
 const issubfilterTemplate = (options) => {
    return (
        <div className="flex align-items-center gap-2">
            <label htmlFor="verified-filter" className="font-bold">
                Notað
            </label>
            <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        </div>
    );
};
  const header = renderHeader();

  const submitCoupon = (percent2, months2, string) => {
    const submitData = async (percent2, months2, string) => {
      const rapydData = await createCoupon(percent2, months2, string);
      if (rapydData.status.status == 'SUCCESS') {
        await addCoupon(string, rapydData.data.id);
        toast.current.show({severity: 'success', summary: 'Aðgerð tókst', detail: 'Búið að búa til gjafabréf', life: 3000});
      } else {
        toast.current.show({severity: 'warning', summary: 'Aðgerð tókst ekki', detail: 'Villa hjá rapyd', life: 3000});
      };
    };
    submitData(percent2, months2, string);
  };
  const createCoupons = async () => {
    const loadData = async () => {
      const tmpArr = codes;
      tmpArr.forEach((obj) => {
        submitCoupon(percent, months, obj);
      });
      setcodes([]);
    };
    await loadData();

    setTimeout(() => {
      setRefresh(refresh+1);
      setVisible(false);
    }, 1500);
    // setLoading(false);
  };
  const footerContent = (
    <div className="c-giftcard-submit">
      <Button label="Búa til gjafabréf" className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" onClick={createCoupons}/>
  </div>
);
  return (
    <div className="c-coupon-container">
      {<Loading isBlocked={loading} propmessage='Bý til gjafabréf'></Loading>}
      <Toast ref={toast}></Toast>
      <div className="c-coupon-ir">
        <div className="card">
          <DataTable value={users} style={{width: '100vw', maxWidth: '600px'}} paginator showGridlines rows={10} loading={loading} dataKey="uid"
            filters={filters} globalFilterFields={['code', 'email']} header={header}dataK
            emptyMessage="No customers found.">
            <Column field="code" header="Kóði" filter filterPlaceholder="Search by name" style={{minWidth: '4rem'}} />
            <Column field="redeemed" header="Notað" dataType="boolean" bodyClassName="text-center" style={{minWidth: '3rem'}} body={isSubtemplate} filter filterElement={issubfilterTemplate} />
          </DataTable>
        </div>
      </div>
      <Dialog header="Búa til gjafabréf" footer={footerContent} visible={visible} style={{width: '100vw', maxWidth: '600px'}} onHide={() => setVisible(false)}>
        <div className="c-user-box">
          <div className="c-giftcard-container">
            <div className="c-giftcard-input">
              <h5>Kóðar</h5>
              <Chips value={codes} style={{width: '24rem'}} addOnBlur={true} onChange={(e) => setcodes(e.value)} separator="," />
            </div>
            <div>
              <h5>% afsláttur</h5>
              <InputNumber inputId="minmax-buttons" style={{width: '12rem'}} value={percent} onValueChange={(e) => setPercent(e.value)} mode="decimal" showButtons min={0} max={100} />
            </div>
            <div>
              <h5>Fjöldi mánuða</h5>
              <InputNumber inputId="minmax-buttons2" style={{width: '12rem'}} value={months} onValueChange={(e) => setMonths(e.value)} mode="decimal" showButtons min={1} max={100} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default Coupons;
Coupons.propTypes = {
  uData: PropTypes.any,
};
