/* eslint-disable indent */
/* eslint-disable quote-props */
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {InputSwitch} from 'primereact/inputswitch';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {SelectButton} from 'primereact/selectbutton';
import {Sidebar} from 'primereact/sidebar';
import {Tag} from 'primereact/tag';
import {Toast} from 'primereact/toast';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {classNames} from 'primereact/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {getWebhooks} from '../../podcastApi.js';
import {createCoupon, getSubinvoice, getUserinfov2, permdeleteSubscription, subscribe, toggleBanned} from '../../rapydApi.js';
import {getErrormessage, secsToFormatedDate} from '../../utils/helperFuncs.js';
import './Admin.scss';
import {firebaseUsers} from './api';

/**
 * @return {User} React.fragment
 */
function Admin({uData}) {
  // const [episodes, setEpisodes] = useState([]);
  const [users, setUsers] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [displaycoupon, setDisplaycoupon] = useState(null);
  const [percent, setPercent] = useState(100);
  const [months, setMonths] = useState(3);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [hooks, setHooks] = useState(<li>Engin gögn fundust</li>);
  const [visibleRight, setVisibleRight] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [rapydUser, setRapydUser] = useState('');
  const [refreshKey, setRefreshkey] = useState(0);
  const [createsubscription, setCreatesubscription] = useState(false);
  const [dropdownvals, setDropdownvals] = useState([]);
  const options = ['Nei', 'Já'];
  const [onoff, setOnoff] = useState(options[0]);
  const [cardErr, setcardErr] = useState(false);
  const [selectedcard, setselectedcard] = useState(null);
  const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
  const plan = 'plan_107b536a8a5ecffdd0f8716423ffd64f'; // Harðkóðuð hér í admin. Óþarfi að sækja.
  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{minWidth: '12rem'}} />
    );
  };

  const getSeverity = (status) => {
      switch (status) {
          case 'unpaid':
              return 'danger';

          case 'paid':
              return 'success';

          case 'new':
              return 'info';

          case 'open':
              return 'warning';
          case 'active':
              return 'success';
          case 'canceled':
            return 'warning';

          case 'renewal':
              return null;
      }
  };
  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
};

const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
};
 // hér
 useEffect(() => {
  firebaseUsers.getCustomersMedium().then((data) => {
      setUsers(getFbuserdata(data));
      setLoading(false);
 });
  initFilters();
}, []);
useEffect(() => {
  if (onoff == 'Já') {
    setDisplaycoupon(true);
  } else {
    setDisplaycoupon(false);
  };
}, [onoff]);
 // hér
 useEffect(() => {
  if (!visibleRight) {
    setSelectedUser(null);
    setRapydUser(null);
  };
}, [visibleRight]);
const getFbuserdata = (data) => {
  return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
 });
};

  const formatDate = (value) => {
      return value.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
     });
 };

  const clearFilter = () => {
      initFilters();
 };

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      const _filters = {...filters};

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
 };

  const initFilters = () => {
      setFilters({
          global: {value: null, matchMode: FilterMatchMode.CONTAINS},
          name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          email: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          representative: {value: null, matchMode: FilterMatchMode.IN},
          date: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
          balance: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          status: {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          activity: {value: null, matchMode: FilterMatchMode.BETWEEN},
          verified: {value: null, matchMode: FilterMatchMode.EQUALS},
          isSub: {value: null, matchMode: FilterMatchMode.EQUALS},
          isAdmin: {value: null, matchMode: FilterMatchMode.EQUALS},
     });
      setGlobalFilterValue('');
 };

  const renderHeader = () => {
      return (
          <div className="flex justify-content-between">
              <Button type="button" icon="pi pi-filter-slash" className="sd-exclude" label="Hreinsa leit" outlined onClick={clearFilter} />
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText className="p-max40vw" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Leita" />
              </span>
          </div>
      );
 };
 const dateBodyTemplate2 = (rowData) => {
   const dags = new Date(rowData.created.seconds * 1000);
  return formatDate(dags);
};
const dateBodyTemplate3 = (rowData) => {
  const dags = new Date(rowData.period_start );
 return formatDate(dags);
};

  const dateFilterTemplate = (options) => {
      return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
 };
 const isSubtemplate = (rowData) => {
  return <i className={classNames('pi', {'text-green-500 pi-check-circle': rowData.isSub, 'text-red-500 pi-times-circle': !rowData.isSub})}></i>;
};
const isAdmintemplate = (rowData) => {
  return <i className={classNames('pi', {'text-green-500 pi-check-circle': rowData.isAdmin, 'text-red-500 pi-times-circle': !rowData.isAdmin})}></i>;
};
 const issubfilterTemplate = (options) => {
    return (
        <div className="flex align-items-center gap-2">
            <label htmlFor="verified-filter" className="font-bold">
                Er í áskrift
            </label>
            <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        </div>
    );
};
const isadminfilterTemplate = (options) => {
    return (
        <div className="flex align-items-center gap-2">
            <label htmlFor="verified-filter" className="font-bold">
                Er kerfisstjóri
            </label>
            <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        </div>
    );
};
  const header = renderHeader();
  const userSelected = (rowData) => {
    setRapydUser(rowData.value.rapyd.id);
    setSelectedUser(rowData.value);
    setVisibleRight(true);
    setSubscriptions(null);
    setSelectedSub(null);
    setInvoices(null);
  };
  useEffect(() => {
    const getRdata = async () => {
        const userData = {};
        // Sæki rapyd notanda, ef hann finnst ekki bý ég hann til með addData
        const rapyduInfo = await getUserinfov2(rapydUser);
        if (rapyduInfo.status.status=='SUCCESS' && !!rapyduInfo.data) {
          try {
            if (rapyduInfo.data?.metadata?.isBanned) {
              setChecked1(true);
            } else {
              setChecked1(false);
            };
          } catch (e) {
            console.log('Villa í metadata', e);
          };
          try {
            const tmp = [];
            rapyduInfo.data.payment_methods.data.forEach((itemRef) => {
              const disp = itemRef.type == 'is_mastercard_card' ? 'Mastercard' : 'Visa';
              // All the items under listRef.
              const tmpImg = {name: `****${itemRef.last4} ${itemRef.expiration_month}/${itemRef.expiration_year} ${disp}`, code: itemRef.id};
              tmp.push(tmpImg);
            });
            setDropdownvals(tmp);
          } catch (e) {
            console.log(e);
          };
          setcardErr(false);
          userData.rapydData = rapyduInfo.data;
          if ( !!rapyduInfo.data.subscriptions ) {
            const subsArr = rapyduInfo.data.subscriptions.data;
            userData.allSubscription = subsArr;
            // Tékka á aldri
            // const renderTime = new Date().getTime();
            // const timestampAge = ((renderTime/1000) - rapyduInfo.data.subscriptions.data[0].current_period_start) / (60*60*24);
            // Ef eldri en 3 dagar, cancela
            setSubscriptions(getFbuserdata(subsArr));
            // subsArr.find((o) => (o.status === 'active' || o.status === 'trialing') && secsValid(o.current_period_end));
          } else {
            userData.isSub = false;
            // toast.current.show({severity: 'info', summary: 'Villa í tengingu', detail: 'Við náum ekki sambandi við greiðsluaðila. Við biðjumst velvirðingar.', sticky: true});
          };
        };
    };
    const getHooks = async () => {
      try {
        const hooks = await getWebhooks(rapydUser);
        // setHooks(hooks);
        const wHsorted = hooks.sort(
          (objA, objB) => Number(objB.created_at) - Number(objA.created_at),
        );
        if (wHsorted.length > 0) {
          setHooks( wHsorted.map((el, index)=><><li key={el.created_at}>
            <div className="c-wh-container">
              <div className="c-wh-date">
                {secsToFormatedDate(el.created_at)}
              </div>
              <div className="c-wh-message">
                {el.data.failure_message || getErrormessage(el.data.error_code) || 'Óþekkt villuskilaboð. Vinsamlegast hafðu samband við admin@stevedagskra.is'}
              </div>
            </div>
          </li></>));
        } else {
          setHooks(<li>Engin gögn fundust</li>);
        }
      } catch (e) {
        setHooks(<li>Engin gögn fundust</li>);
      };
    };
    if (!!rapydUser) {
      getRdata();
      getHooks();
    };
  }, [rapydUser, refreshKey]);

  const subSelect = (rowData) => {
    const getRdata = async (id) => {
      // Sæki rapyd notanda, ef hann finnst ekki bý ég hann til með addData
      try {
        const subInvoice = await getSubinvoice(id);
        if (subInvoice.status.status == 'SUCCESS') {
          setInvoices(getFbuserdata(subInvoice.data));
        };
      } catch (e) {
        console.log(e);
      };
  };
    setSelectedSub(rowData.value);
    if (!!rapydUser) {
      getRdata(rowData.value.id);
    };
    // setSelectedUser(rowData.value);
  };
  const deleteSub = () => {
    confirm2();
  };
  const accept = () => {
    const cancelSub = async () => {
      const rapyduInfo = await permdeleteSubscription(selectedSub.id);
      if (rapyduInfo.status.status=='SUCCESS') {
        toast.current.show({severity: 'info', summary: 'Staðfest', detail: 'Áskrift eytt', life: 3000});
        setRefreshkey(refreshKey+1);
      } else {
        toast.current.show({severity: 'warning', summary: 'Villa kom upp', detail: 'Villa kom upp. Hafðu samband við forritara', life: 3000});
      };
    };
    cancelSub();
  };

  const reject = () => {
    toast.current.show({severity: 'warn', summary: 'Rejected', detail: 'Hætt við', life: 3000});
  };
  const confirm2 = () => {
    confirmDialog({
      message: 'Viltu eyða þessari áskrift?',
      header: 'Eyða áskrift',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };
  const createSubaccept = () => {
    const createSub = async () => {
      let coupon = '';
      if (onoff == 'Já') {
        const rapydData = await createCoupon(percent, months, 'admin');
        if (rapydData.status.status == 'SUCCESS') {
          coupon = rapydData.data.id;
          toast.current.show({severity: 'success', summary: 'Aðgerð tókst', detail: 'Búið að búa til gjafabréf', life: 3000});
        } else {
          toast.current.show({severity: 'warning', summary: 'Aðgerð tókst ekki', detail: 'Villa hjá rapyd', life: 3000});
        };
      };
      // Búa til áskriftina
      const sub = await subscribe( selectedcard.code, plan, rapydUser, coupon);
      if (sub.status.status=='SUCCESS') {
        toast.current.show({severity: 'success', summary: 'Vel gert!', detail: 'Áskrift tilbúin!'});
        setRefreshkey(refreshKey+1);
      } else {
        toast.current.show({severity: 'error', summary: 'Villa kom upp við skráningu', detail: 'Prufaðu að fara til baka og reyna aftur.'});
        setRefreshkey(refreshKey+1);
      };
    };
    if (selectedcard && plan && rapydUser) {
      createSub();
    };
  };
  const confirmSub = () => {
    confirmDialog({
      message: 'Viltu stofna áskrift?',
      header: 'Stofna áskrift',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-success',
      accept: createSubaccept,
      reject,
    });
  };
  const footerContent = (
    <div>
      <Button label="Hætta við" icon="pi pi-times" onClick={() => setCreatesubscription(false)} className="p-button-text" />
      <Button label="Búa til áskrift" size="lg" className="p-button p-component p-button-success sd-exclude" onClick={() => {
                  confirmSub();
                  }}/>
    </div>
  );
  const toggleBan = (value, cusId) => {
    try {
      if (cusId) {
        if (value) {
          // promptAllownotification();
          console.log('banna');
          toggleBanned(cusId, true);
          setChecked1(true);
        } else {
          console.log('afbanna');
          toggleBanned(cusId, false);
          setChecked1(false);
        };
      } else {
        console.log('missing customer id');
      };
    } catch (e) {
      console.log(e);
    };
  };
  return (
      <div className="sd-dashboard">
        <Toast ref={toast} />
        <div className="sd-dashboard-ir">
          <div className="sd-dashboard-ir-left">
            <div className="card">
              <DataTable value={users} paginator showGridlines stripedRows rows={10} loading={loading} dataKey="uid"
                      filters={filters} globalFilterFields={['name', 'email']} header={header} selectionMode="single" selection={selectedUser} onSelectionChange={userSelected} dataK
                      emptyMessage="No customers found." className="c-sd-ir">
                <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{minWidth: '4rem'}} />
                <Column field="email" header="Netfang" filter filterPlaceholder="Search by email" style={{minWidth: '4rem'}} />
                <Column header="Date" field="created.seconds" dataType="date" style={{minWidth: '4rem'}} body={dateBodyTemplate2} filter filterElement={dateFilterTemplate} />
                <Column field="isSub" header="Er í áskrift" dataType="boolean" bodyClassName="text-center" style={{minWidth: '3rem'}} body={isSubtemplate} filter filterElement={issubfilterTemplate} />
                <Column field="isAdmin" header="Er kerfisstjóri" dataType="boolean" bodyClassName="text-center" style={{minWidth: '3rem'}} body={isAdmintemplate} filter filterElement={isadminfilterTemplate} />
              </DataTable>
            </div>
          </div>
          <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} className="askur" footer={isSubtemplate}>
            <div className="sd-dashboard-ir-right">
              {!!selectedUser ? <div className="sd-userInfo">
                <div className="sd-userInfo-detail">
                  <h3>Nafn:</h3>
                  <p>{selectedUser.name}</p>
                </div>
                <div className="sd-userInfo-detail">
                  <h3>Netfang:</h3>
                  <p>{selectedUser.email}</p>
                </div>
                <div className="sd-userInfo-detail">
                  <h3>Stofnaður:</h3>
                  <p>{formatDate(new Date(selectedUser.created.seconds * 1000))}</p>
                </div>
                <div className="sd-userInfo-detail">
                  <h3>Rapyd ID:</h3>
                  <p>{selectedUser.rapyd.id}</p>
                </div>
                <div className="sd-userInfo-detail">
                  <h3>Banna RSS:</h3>
                  <InputSwitch checked={checked1} onChange={(e) => toggleBan(e.value, selectedUser?.rapyd?.id)} />
                </div>
              </div> : <></>}
              <div className="sd-firebase-users">
                <Fieldset legend="Áskriftir">
                  <DataTable value={subscriptions} paginator showGridlines rows={10} loading={loading} dataKey="id"
                          selectionMode="single" selection={selectedSub} onSelectionChange={subSelect} dataK
                          emptyMessage="Engar áskriftir fundust.">
                      <Column field="id" header="Id" style={{minWidth: '4rem'}} />
                      <Column field="status" header="Staða" showFilterMenu={false} filterMenuStyle={{width: '14rem'}} style={{minWidth: '4rem'}} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
                  </DataTable>
                </Fieldset>
              </div>
              <div className="sd-firebase-users">
                <Fieldset legend="Greiðslur">
                  <DataTable value={invoices} paginator showGridlines rows={10} loading={loading} dataKey="id"
                          dataK emptyMessage="Engir reikningar.">
                      <Column field="id" header="Id" style={{overflow: 'hidden'}} />
                      <Column field="status" header="Staða" showFilterMenu={false} filterMenuStyle={{width: '14rem'}} style={{minWidth: '4rem'}} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
                      <Column header="Greiðsludags." field="period_start" dataType="date" style={{minWidth: '4rem'}} body={dateBodyTemplate3} />
                  </DataTable>
                </Fieldset>
              </div>
              <div className="sd-firebase-users">
                <Fieldset legend="Greiðslu villur">
                  <ul>
                    {hooks}
                  </ul>
                </Fieldset>
              </div>
            </div>
            <div className="c-subscription-actions">
              <div className="c-sub-form">
                <Button label="Eyða áskrift" size="lg" className={selectedSub && selectedSub.status != 'canceled' ? 'p-button p-component p-button-danger sd-exclude' : 'p-button p-component p-button-danger sd-exclude p-disabled'} onClick={deleteSub}/>
                <Button label="Búa til áskrift" size="lg" className="p-button p-component p-button-success sd-exclude" onClick={() => {
                  setCreatesubscription(true);
                  }}/>
              </div>
            </div>
            <ConfirmDialog />
            <Dialog header="Búa til áskrift" visible={createsubscription} style={{width: '95vw'}} onHide={() => setCreatesubscription(false)} footer={footerContent}>
              <div>
                <div>
                  <h3>Stofna áskrift fyrir</h3>
                <p>{selectedUser ? selectedUser.name : ''}</p>
                </div>
                <h3>Kort notanda*</h3>
                <Dropdown className={cardErr ? 'p-invalid w-full md:w-14rem' : 'w-full md:w-14rem'} value={selectedcard} onChange={(e) => setselectedcard(e.value)} options={dropdownvals} optionLabel="name"
                    placeholder="Veldu kort" />
              </div>
              <div>
                <h3>Gjafabréf</h3>
                <SelectButton value={onoff} onChange={(e) => setOnoff(e.value)} options={options} />
              </div>
              {displaycoupon && <div>
                <div>
                  <h5>% afsláttur</h5>
                  <InputNumber inputId="minmax-buttons" style={{width: '12rem'}} value={percent} onValueChange={(e) => setPercent(e.value)} mode="decimal" showButtons min={0} max={100} />
                </div>
                <div>
                  <h5>Fjöldi mánuða</h5>
                  <InputNumber inputId="minmax-buttons2" style={{width: '12rem'}} value={months} onValueChange={(e) => setMonths(e.value)} mode="decimal" showButtons min={1} max={100} />
                </div>
              </div>}
            </Dialog>
          </Sidebar>
        </div>
      </div>
  );
}

export default Admin;
Admin.propTypes = {
  uData: PropTypes.any,
};
