import React, {useState, useEffect, useRef} from 'react';
import {Chart} from 'primereact/chart';
import {confirmPopup} from 'primereact/confirmpopup';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {generateAdminreport} from '../../rapydApi.js';
import {getSubdata} from '../../podcastApi.js';
import {Button} from 'primereact/button';
import './Charts.scss';
/**
 * @return {JSX} Charts
 */
export default function Charts() {
  const dt = useRef(null);
  const [data, setData] = useState();
  const [dispData, setdispData] = useState();
  const [selection, setselection] = useState('1');
  const [tabledata, setTabledata] = useState();
  const [dates2, setDates2] = useState([new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()]);
  const cols = [
    {field: 'dataId', header: 'Dags.'},
    {field: 'newSubscriptions', header: 'Nýjar áskriftir (þann dag)'},
    {field: 'canceledSubscriptions', header: 'Áskriftum sagt upp (þann dag)'},
    {field: 'activeSubsCount', header: 'Virkar áskriftir'},
    {field: 'subhascouponandactive', header: 'Virkar með gjafabréfi'},
    {field: 'activeCanceled', header: 'Virk en sagt upp'},
    {field: 'subhascoupon', header: 'Áskrift er með gjafabréf'},
    {field: 'subhascouponandiscanceled', header: 'Virk m. gjafabréfi en sagt upp'},
    {field: 'canceledSubsCount', header: 'Áskriftum sagt upp (samtals)'},
    {field: 'unpaidSubsCount', header: 'Ógreiddar áskriftir'},
    {field: 'processRenew', header: 'Áskriftir endyrnýjaðar (í keyrslu)'},
    {field: 'processDel', header: 'Áskriftum sagt upp (í keyrslu)'},
    {field: 'neversubbedCount', header: 'Notendur aldrei keypt áskrift'},
    {field: 'runtimeDuration', header: 'Keyrslutími'},
    {field: 'counter', header: 'Mánuðir ógreiddir v. rapyd'},
    {field: 'subsTocheck', header: 'Áskriftir sem þarf að skoða'},
  ];
  const selectionSelectItems = [
    {label: 'Í gær', value: '0'},
    {label: 'Síðasta vika', value: '1'},
    {label: 'Síðasti mánuður', value: '2'},
    {label: 'Síðustu 3 mánuðir', value: '3'},
    {label: 'Frá upphafi', value: '4'},
  ];
  useEffect(() => {
    const asyncData = async () => {
      const data = await getSubdata();
      setData(data);
    };
    asyncData();
  }, []);
  useEffect(() => {
    const currentDate = new Date();

    switch (selection) {
      case '0':
        // Í gær (yesterday)
        currentDate.setDate(currentDate.getDate() - 1);
        setDates2([currentDate, new Date()]);
        break;
      case '1':
        // Síðasta vika (last week)
        currentDate.setDate(currentDate.getDate() - 7);
        setDates2([currentDate, new Date()]);
        break;
      case '2':
        // Síðasti mánuður (last month)
        currentDate.setMonth(currentDate.getMonth() - 1);
        setDates2([currentDate, new Date()]);
        break;
      case '3':
        // Síðustu 3 mánuðir (last 3 months)
        currentDate.setMonth(currentDate.getMonth() - 3);
        setDates2([currentDate, new Date()]);
        break;
      case '4':
        // Frá upphafi (since November 9, 2022)
        const startDate = new Date('2022-11-09');
        setDates2([startDate, new Date()]);
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  }, [selection]);
  useEffect(() => {
    if (data) {
      // Step 1: Filter the data for the last 30 days
      const filteredData = data.filter((entry) => {
        const entryDate = new Date(entry.date.seconds * 1000); // Convert seconds to milliseconds
        return entryDate >= dates2[0] && entryDate <= dates2[1];
      });
      // Step 2: Order the filtered data by date
      const orderedData = filteredData.sort((a, b) => a.date.seconds - b.date.seconds);
      // Step 3: Calculate cumulative sums
      let cumulativeSum = 0;
      const chartData = orderedData.map((entry) => {
        cumulativeSum += (entry.newSubscriptions || 0) - (entry.canceledSubscriptions || 0);
        return {
          date: new Date(entry.date.seconds * 1000).toLocaleDateString(), // Convert seconds to milliseconds
          newSubscriptions: entry.newSubscriptions || 0,
          canceledSubscriptions: entry.canceledSubscriptions || 0,
          cumulativeSum,
          dags: entry.date.seconds,
        };
      });
      const orderedChartdata = chartData.sort((a, b) => a.dags - b.dags);
      // Step 4: Prepare data for the chart
      const chartFormattedData = {
        labels: orderedChartdata.map((entry) => entry.date),
        datasets: [
          {
            type: 'bar',
            label: 'Nýjar áskriftir',
            backgroundColor: '#66BB6A',
            data: orderedChartdata.map((entry) => entry.newSubscriptions),
          },
          {
            type: 'bar',
            label: 'Áskriftum sagt upp',
            backgroundColor: '#FFA726',
            data: orderedChartdata.map((entry) => entry.canceledSubscriptions),
          },
          {
            type: 'line',
            label: 'Summa mismuns',
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            data: orderedChartdata.map((entry) => entry.cumulativeSum),
          },
        ],
      };
      setTabledata(filteredData.sort((a, b) => b.date.seconds - a.date.seconds));
      setdispData(chartFormattedData);
    }
  }, [data, dates2]);
  const [lightOptions] = useState({
    maintainAspectRatio: false,
    aspectRatio: .6,
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
      y: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  });
  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tabledata);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'tabledata');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly});
  };
  const header = (
    <div className="flex align-items-center export-buttons">
      <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
      <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
    </div>
  );
  const acceptReport = () => {
    generateAdminreport();
  };
  const reject = () => {
  };
  const confirm1 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: <p>Viltu fá sendan nýjan lista?<br></br> Keyrsla tekur ~mínútu</p>,
      icon: 'pi pi-envelope',
      className: 'c-stadfesta',
      accept: acceptReport,
      reject,
      acceptClassName: 'p-button-warning',
      acceptLabel: 'Já',
      rejectLabel: 'Nei',
    });
  };
  return (
    <div className="c-data-container flex flex-wrap pt-2">
      <div className="sticky top-0 c-filters p-2 flex flex-column align-items-left">
        <Dropdown className="m-2" value={selection} options={selectionSelectItems} onChange={(e) => setselection(e.value)} placeholder="Veldu tímabil"/>
        <Calendar className="m-2" id="range" value={dates2} onChange={(e) => setDates2(e.value)} selectionMode="range" readOnlyInput />
        <Button label="Fá sendan áskrifendalista" onClick={(e) => confirm1(e)} className="p-button-warning m-2" />
      </div>
      <div className="c-data">
        <Chart className="c-data-chart m-auto" type="bar" data={dispData} options={lightOptions} />
        <DataTable className="m-auto" ref={dt} value={tabledata} header={header} dataKey="id" size="small" responsiveLayout="scroll">
          {
            cols.map((col, index) => <Column key={index} field={col.field} header={col.header} sortable/>)
          }
        </DataTable>
      </div>
    </div>
  );
}
