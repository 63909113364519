
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {getWebhooks} from '../../podcastApi.js';
import {getErrormessage, secsToFormatedDate} from '../../utils/helperFuncs.js';
import './Hooks.scss';

const Hooks = (props) => {
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [paydata, setPaydata] = useState(false);

  useEffect( async () => {
    setDisplayBasic2(props.openSkilmalar);
  }, [props.openSkilmalar]);

  useEffect( async () => {
    const getWeb = async () => {
      let wH = await getWebhooks(props.cusId);
      wH = wH.filter((item) => item.type === 'PAYMENT_FAILED');
      const wHsorted = wH.sort(
          (objA, objB) => Number(objB.created_at) - Number(objA.created_at),
      );
      setPaydata( wHsorted.map((el, index)=><div key={el.created_at}><li>
        <div className="c-wh-container">
          <div className="c-wh-date">
            {secsToFormatedDate(el.created_at)}
          </div>
          <div className="c-wh-message">
            {el.data?.failure_message || getErrormessage(el.data.error_code) || 'Óþekkt villuskilaboð. Vinsamlegast hafðu samband við admin@stevedagskra.is'}
          </div>
        </div>
      </li></div>));
      // setHooks(wH);
    };
    if (props.cusId) {
      getWeb();
    };
  }, [props.cusId]);

  const onHide = () => {
    props.setskilmalarDisp(false);
  };

  const renderFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Loka" icon="pi pi-times" onClick={(e) => onHide()} className="p-button-text sd-exclude p-button-normal" />
      </div>
    );
  };
  const renderdispFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Loka" icon="pi pi-times" onClick={(e) => onHide()} className="p-button-text sd-exclude p-button-normal" />
      </div>
    );
  };

  return (
    <Dialog header="Greiðsluskilaboð" visible={displayBasic2} className="c-privacy-wndw" footer={!!props.accept ? renderFooter('displayBasic2') : renderdispFooter()} onHide={() => onHide('displayBasic2')}>
      <ul>
        {paydata}
      </ul>
    </Dialog>
  );
};
export default Hooks;
Hooks.propTypes = {
  openSkilmalar: PropTypes.bool,
  accept: PropTypes.func,
  setskilmalarDisp: PropTypes.func,
  cusId: PropTypes.any,
};
