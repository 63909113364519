/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable indent */
import {getDownloadURL, listAll, ref, uploadBytesResumable} from 'firebase/storage';
import {confirmDialog} from 'primereact/confirmdialog'; // To use confirmDialog method
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {FileUpload} from 'primereact/fileupload';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {ProgressBar} from 'primereact/progressbar';
import {Tag} from 'primereact/tag';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import BlogForm from '../../components/blogform/BlogForm.js';
import Loading from '../../components/loading/Loading';
import {addEpisodev2, getUrl, getPodcasts, storage} from '../../podcastApi.js';
import {sendNotifications} from '../../rapydApi.js';
import {seconds2HHMM, toDateTime} from '../../utils/helperFuncs.js';
import {InputSwitch} from 'primereact/inputswitch';
import './Addepisode.scss';
/**
 * @param  {date} date
 * @return {date} date
 */
function getNextFriday(date = new Date()) {
  const dateCopy = new Date(date.getTime());
  let nextFriday = new Date(
      dateCopy.setDate(
          dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7 || 7),
      ),
  );
  nextFriday = nextFriday.setHours(12, 0, 0);
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(nextFriday/1000);
  return t;
}
/**
 * @param  {seconds} seconds
 * @return {date} date
 */
function calculateDurationComponents(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.ceil(seconds % 60);
  return {hours, minutes, seconds: remainingSeconds};
};
/**
 * @return {User} React.fragment
 */
function Addepisode({uData}) {
  const toast = useRef(null);
  const [imgSize, setImgsize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const sounduploadRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [loadingmsg, setLoadingmsg] = useState('Hleð síðu');
  const [episodeImg, setepisodeImg] = useState();
  const [date7, setDate7] = useState(getNextFriday());
  const [demoimg, setDemoimg] = useState(null);
  const [epname, setepname] = useState('Extra Steve Dagskrá');
  const [description, setdescription] = useState('Lýsing þáttar');
  const [imgfile, setImgfile] = useState('');
  const [eptype, setType] = useState(false);
  const [audiofile, setAudiofile] = useState('');
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSeconds] = useState(0);
  const [videoerr, setVideoerr] = useState(false);
  const [imgpath, setimgpath] = useState();
  const [audiopath, setaudiopath] = useState();
  const [tags, setTags] = useState(['fös', 'steve']);
  const [imgfileErr, setimgfileErr] = useState(false);
  const [dropdownvals, setDropdownvals] = useState([]);
  const [videovals, setVideovals] = useState([]);
  const [date7Err, setdate7Err] = useState(false);
  const [descriptionErr, setdescriptionErr] = useState(false);
  const [lengthErr, setlengthErr] = useState(false);
  const [epnameErr, setepnameErr] = useState(false);
  const [checked1, setChecked1] = useState(true);
  const [audiodemo, setaudiodemo] = useState();
  const [imagedownloadurl, setImagedownloadurl] = useState();
  const [episodeCount, setEpisodeCount] = useState(/* your initial value */);
  const audioRef = useRef();

  const onLoadedMetadata = () => {
      if (audioRef.current) {
          const {hours, minutes, seconds} = calculateDurationComponents(audioRef.current.duration);
          setHours(hours);
          setMins(minutes);
          setSeconds(seconds);
      };
  };
  // progress
  const [percent, setPercent] = useState(0);
  const [selectedimg, setselectedimg] = useState({
    'name': 'myndir/hestehele.png',
    'code': 'myndir/hestehele.png',
  });
  const [videofile, setVideofile] = useState();
  const handleUpload = async (file, type) => {
    if (!file) {
        alert('Please upload an image first!');
        return;
    }
    let storageRef;
    if (type === 'audio') {
        storageRef = ref(storage, `/thaettir/${file.name}`);
    } else {
        storageRef = ref(storage, `/myndir/${file.name}`);
    };
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Use try-catch to handle errors during upload
    try {
        await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                    );
                    setPercent(percent);
                    if (type === 'audio') {
                        setLoadingmsg(`[${percent}%] Hleð þætti`);
                    } else {
                        setLoadingmsg(`[${percent}%] Hleð mynd`);
                    }
                },
                (err) => {
                    reject(err);
                },
                () => {
                    // Upload completed successfully
                    resolve();
                },
            );
        });
        // The upload is complete, get the download URL
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        if (type == 'image') {
          setImagedownloadurl(url);
        };
    } catch (error) {
        console.error(error);
    }
};
useEffect(() => {
  if (selectedimg) {
    // setDemoimg(selectedimg.code);
    fileUploadRef.current.clear();
    setimgpath(selectedimg.code);
    setImg(selectedimg.code);
  };
}, [selectedimg]);
const setImg = (url) => {
  const getImageurl = async () => {
    try {
      if (url) {
        const tmpImg = await getUrl(url);
        setImagedownloadurl(tmpImg);
        setepisodeImg(tmpImg);
        setDemoimg(tmpImg);
        // setImageLoaded(true);
      };
    } catch (e) {
      console.log(e);
      setepisodeImg('https://firebasestorage.googleapis.com/v0/b/steve-prod.appspot.com/o/steveicon.png?alt=media&token=9968a4e4-428c-4f4c-9002-16a2d201d3f6');
    };
  };
  getImageurl();
};
  useEffect(() => {
    const tmp = [];
    const listRef = ref(storage, 'myndir');
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        // All the items under listRef.
        const tmpImg = {name: itemRef._location.path_, code: itemRef._location.path_};
        tmp.push(tmpImg);
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    setDropdownvals(tmp);
  }, []);
  useEffect(() => {
    const tmp = [];
    const listRef = ref(storage, 'video');
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        // All the items under listRef.
        const tmpImg = {name: itemRef._location.path_, code: itemRef._location.path_};
        tmp.push(tmpImg);
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    setVideovals(tmp);
  }, []);
  // byrjun
  const onTemplateSelect = (e) => {
    const _totalSize = e.files[0].size;
    setimgpath(`myndir/${e.files[0].name}`); // á að vera myndir á raun
    setselectedimg(null);
    setImgfile(e.files[0]);
    setImgsize(_totalSize);
    setDemoimg(e.files[0].objectURL);
  };
  const onAudioselect = (e) => {
    setAudiofile(e.files[0]);
    setaudiopath(`thaettir/${e.files[0].name}`); // á að vera thaettir á raun
    const _totalSize = e.files[0].size;
    setTotalSize(_totalSize);
    const objectUrl = URL.createObjectURL(e.files[0]);
    setaudiodemo(objectUrl);
  };
  const onTemplateRemove = (file, callback) => {
    // setTotalSize(0);
    setimgpath(null);
    setImgfile(null);
    setImgsize(0);
    setDemoimg('  ');
    callback();
  };

  const onTemplateClear = () => {
    // setTotalSize(0);
    setimgpath(null);
    setImgfile(null);
    setImgsize(0);
    setDemoimg('');
  };
  const onTemplateRemoveaudio = (file, callback) => {
    setTotalSize(0);
    setAudiofile(null);
    setaudiopath(null);
    callback();
    setHours(0);
    setMins(0);
    setSeconds(0);
  };

  const onTemplateClearaudio = () => {
    setAudiofile(null);
    setaudiopath(null);
    setTotalSize(0);
    setHours(0);
    setMins(0);
    setSeconds(0);
  };

  const headerTemplate = (options) => {
    const {className, chooseButton, uploadButton, cancelButton} = options;
    const value = imgSize/10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(imgSize) : '0 B';
    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '150px', height: '2px', marginLeft: 'auto'}}></ProgressBar>
      </div>
    );
  };
  const audioHeadertemplate = (options) => {
    const {className, chooseButton, cancelButton} = options;
    const value = totalSize/3000000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 300 MB`} style={{width: '150px', height: '2px', marginLeft: 'auto'}}></ProgressBar>
      </div>
    );
  };
  const itemTemplate = (file, props) => {
    return (

      <div className="flex align-items-center flex-wrap justify-content-between">
        <div className="flex align-items-center flex-column" style={{width: '40%'}}>
          {file.objectURL ? <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> : <></>}
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger sd-exclude" onClick={() =>
          onTemplateRemove(file, props.onRemove)} />
      </div>
    );
  };
  const itemTemplate2 = (file, props) => {
    return (

      <div className="flex align-items-center flex-wrap justify-content-between">
        <div className="flex align-items-center" style={{width: '40%'}}>
          {file.objectURL ? <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> : <></>}
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger sd-exclude" onClick={() => onTemplateRemoveaudio(file, props.onRemove)} />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5 c-empty-i"></i>
        <span className="my-5 c-empty-span">Veljið mynd</span>
      </div>
    );
  };
  const emptyTemplate2 = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-volume-up mt-3 p-5 c-empty-i"></i>
        <span className="my-5 c-empty-span">Veljið hljóðskrá</span>
      </div>
    );
  };
  // end
  const validate = () => {
    if (!imgfile || !(imgpath && selectedimg)) {
      setimgfileErr(true);
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Vantar mynd', life: 3000});
    } else {
      setimgfileErr(false);
    }
    if (!audiofile) {
      setimgfileErr(true);
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Engin mp3/mp4 skrá fannst', life: 3000});
    } else {
      setimgfileErr(false);
    }
    if (!date7) {
      setdate7Err(true);
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Hvenær á þátturinn að koma út?', life: 3000});
    } else {
      setdate7Err(false);
    }
    if (!description) {
      setdescriptionErr(true);
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Lýsingu vantar', life: 3000});
    } else {
      setdescriptionErr(false);
    }
    if (!hours && !mins && !secs) {
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Hversu langur er þátturinn?', life: 3000});
      setlengthErr(true);
    } else {
      setlengthErr(false);
    }
    if (!epname) {
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Vantar nafn þáttar', life: 3000});
      setepnameErr(true);
    } else {
      setepnameErr(false);
    }
    if (eptype && !videofile) {
      toast.current.show({severity: 'error', summary: 'Error', detail: 'Vantar slóð á vídeó', life: 3000});
      setVideoerr(true);
    } else {
      setVideoerr(false);
    }
  };
  useEffect(() => {
    const getData = async () => {
      const data = await getPodcasts();
      const count = data.length;
      setEpisodeCount(count + 1);
    };
    getData();
  }, []);
  const createAddepisode = () => {
    const loadData = async () => {
      if (!selectedimg && imgfile) {
        setLoadingmsg('[0%] Hleð mynd');
        await handleUpload(imgfile, 'image');
      };
      await handleUpload(audiofile, 'audio'),
      setLoadingmsg('Skrái þátt');
      let episodeType = 'audio';
      if (eptype) {
        episodeType = 'video';
      };
      await addEpisodev2(date7, description, audiopath, totalSize, imgpath, epname, (hours * 3600) + (mins * 60) + (secs), checked1, episodeCount, episodeType, videofile?.code);
      setLoadingmsg('Sendi tilkynningar');
      try {
        const ans = await sendNotifications(epname, description, imagedownloadurl);
        console.log(ans);
      } catch (e) {
        setTimeout(function() {
          setLoadingmsg('Villa kom upp að senda tilkynningar. Þáttur er í lagi samt.');
        }, 3000); // 3000 milliseconds = 3 seconds
      };
      setloading(false);
      toast.current.show({severity: 'success', summary: 'Aðgerð tókst', detail: 'Þáttur vistaður!', life: 3000});
      setTimeout(() => {
        window.location = '/';
      }, 1000);
    };
    setloading(true);
    if ((imgfile || (imgpath && selectedimg)) && audiofile && description && epname && !(!hours && !mins && !secs) && date7 && ((eptype && videofile) || !eptype)) {
      loadData();
    } else {
      setloading(false);
      validate();
    };
  };
  const confirm = () => {
    confirmDialog({
      message: 'Eru allar upplýsingar réttar?',
      header: 'Staðfesta',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Já',
      rejectLabel: 'Nei',
      accept: () => createAddepisode(),
    });
  };
  const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined sd-exclude'};
  const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined sd-exclude'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined sd-exclude'};
  return (
    <div className="c-episode-container">
      <Toast ref={toast} />
      {<Loading isBlocked={loading} propmessage={loadingmsg}></Loading>}
      <div className="c-episode-box">
        <div className="c-episode-box-div c-episode-example">
          <h2>Sýnidæmi</h2>
          <div className="c-home c-blog-container c-blog-demo">
            <BlogForm imageLoaded={!!demoimg}
              episodeImg={demoimg}
              name={epname}
              playBtn={true}
              playBtnelement={<Button className="p-button-rounded p-button-text p-button-plain sd-exclude p-button-lg" disabled name="deleteButton" label="Spila" rounded text icon="pi pi-lock"/>}
              description={description}
              createdTime={toDateTime(date7.getTime() / 1000)}
              duration={seconds2HHMM((hours * 3600) + (mins * 60) + (secs))}
              type={eptype ? 'video' : 'audio'}
              fileName='video/S1E5_IA.mp4'
              ></BlogForm>
          </div>
        </div>
        <div className="c-episode-box-div c-episode-files">
          <h2>1. Veljið mynd</h2>
          <FileUpload ref={fileUploadRef} name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" maxFileSize={30000000}
            onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
            <div className="c-episode-box-dropdown justify-content-center">
              <h4>Eða notið gamla</h4>
              <Dropdown className={imgfileErr ? 'p-invalid w-full md:w-14rem' : 'w-full md:w-14rem'} value={selectedimg} onChange={(e) => setselectedimg(e.value)} options={dropdownvals} optionLabel="name"
                  placeholder="Velja mynd" />
            </div>
          <h2>2. Veljið hljóðskrá</h2>
          <FileUpload ref={sounduploadRef} name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" accept="audio/*" maxFileSize={10000000000}
             onSelect={onAudioselect} onError={onTemplateClearaudio} onClear={onTemplateClearaudio}
            headerTemplate={audioHeadertemplate} itemTemplate={itemTemplate2} emptyTemplate={emptyTemplate2}
            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        <div>
          <audio
              ref={audioRef}
              src={audiodemo}
              onLoadedMetadata={onLoadedMetadata}
          >
              <source src={audiodemo} type="audio/mpeg" />
          </audio>
        </div>
        </div>
        <div className="c-episode-box-div c-episode-form">
          <div className="c-giftcard-input">
            <h2>3. Skráið upplýsingar</h2>
          </div>
          <div className="c-giftcard-input">
            <h3>Þáttur númer</h3>
            <InputText className={epnameErr ? 'p-invalid' : ''} value={episodeCount} onChange={(e) => setEpisodeCount(e.target.value)}/>
          </div>
          <div className="c-giftcard-input">
            <h3>Nafn þáttar</h3>
            <InputText className={epnameErr ? 'p-invalid' : ''} value={epname} onChange={(e) => setepname(e.target.value)}/>
          </div>
          <div>
            <h3>Lýsing</h3>
            <InputTextarea className={descriptionErr ? 'p-invalid' : ''} value={description} onChange={(e) => setdescription(e.target.value)} />
          </div>
          <div>
            <h3>Útgáfudagur</h3>
            <Calendar id="time24" className={date7Err ? 'p-invalid' : ''} value={date7} onChange={(e) => setDate7(e.value)} showTime showSeconds />
          </div>
          <div>
            <h3>Tögg</h3>
            <Chips value={tags} onChange={(e) => setTags(e.value)} />
          </div>
          <div>
            <h3>Er vídeó</h3>
            <InputSwitch checked={eptype} className={videoerr ? 'p-invalid' : ''} onChange={(e) => setType(e.value)} />
          </div>
          {eptype && <div>
            <h3>Slóð á vídeó</h3>
            <Dropdown className={imgfileErr ? 'p-invalid w-full md:w-14rem' : 'w-full md:w-14rem'} value={videofile} onChange={(e) => setVideofile(e.value)} options={videovals} optionLabel="name"
                  placeholder="Velja mynd" />
          </div>}
          <div>
            <h3>Lengd</h3>
            <div className="c-episode-length">
              <span>Klukkustundir</span>
              <InputNumber className={lengthErr ? 'p-invalid' : ''} style={{width: '1rem'}} value={hours} mode="decimal" showButtons onValueChange={(e) => setHours(e.value)} min={0} max={24} />
              <span>Múnútur</span>
              <InputNumber className={lengthErr ? 'p-invalid' : ''} style={{width: '1rem'}} value={mins} mode="decimal" showButtons onValueChange={(e) => setMins(e.value)} min={0} max={59} />
              <span>Sekúndur</span>
              <InputNumber className={lengthErr ? 'p-invalid' : ''} style={{width: '1rem'}} value={secs} mode="decimal" showButtons onValueChange={(e) => setSeconds(e.value)} min={0} max={59} />
            </div>
            <div className="c-episode-length mb-3">
              <span>Birta strax</span>
              <InputSwitch checked={checked1} onChange={(e) => setChecked1(e.value)} />
            </div>
          </div>
        </div>
      </div>
      <div className="c-button-container">
        <div className="c-giftcard-submit">
          <Button label="Vista" size="lg" className="p-button p-component p-button-warning sd-exclude sd-cancel" onClick={confirm}/>
        </div>
      </div>
    </div>
  );
}
export default Addepisode;
Addepisode.propTypes = {
  uData: PropTypes.any,
  formatSize: PropTypes.any,
  onRemove: PropTypes.any,
};
