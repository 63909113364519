import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Toast} from 'primereact/toast';
import {classNames} from 'primereact/utils';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Skilmalar from '../../components/skilmalar/Skilmalar.js';
import {addData, firebaseRegister, googleLoginFirebase} from '../../podcastApi.js';
// import {CountryService} from '../service/CountryService';
import './Register.scss';
/**
 * @return {Register} dom
 */
export default function Register({refreshData}) {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const [existsErr, setExistsErr] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [skilmalarDisp, setskilmalarDisp] = useState(false);
  const [checked, setChecked] = useState(false);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const [skilmalartext, setSkilmalartext] = useState(false);
  const toast = useRef(null);

  // constants to navigate
  const navigate = useNavigate();
  const state = location.state;
  const from = state ? (state.nextPath || state.from.pathname) : '/forsida';

  // const countryservice = new CountryService();
  let name = '';
  let email = '';
  if (location.state) {
    name = location.state.name;
    email = location.state.email;
  };
  const defaultValues = {
    name: name,
    email: email,
    password: '',
    password2: '',
    accept: false,
  };

  /* useEffect(() => {
      countryservice.getCountries().then(data => setCountries(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  */
  const {control, formState: {errors}, register, watch, setValue, handleSubmit} = useForm({defaultValues});
  const onSubmit = async (data) => {
    setBlockedDocument(true);
    setLoading1(true);
    setFormData(data);
    const res = await firebaseRegister(
        data,
    );
    setLoading1(false);
    if (res.success) {
      refreshData();
      setShowMessage(true);
      // navigate('/forsida', {replace: true});
    } else {
      if (res=='auth/email-already-in-use') {
        setExistsErr(true);
      } else {
        toast.current.show({severity: 'warn', summary: 'Rejected', detail: res.message, life: 3000});
      };
    }
    setBlockedDocument(false);
  };
  const openAgreement = () => {
    setskilmalarDisp(true);
  };
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  const dialogFooter = <div className="flex justify-content-center"><Button label="Í lagi" className="p-button-text" onClick={() => navigate(from, {replace: true})}/></div>;
  const passwordHeader = <h6>Veldu lykilorð</h6>;
  const passwordFooter = (
    <>
      <Divider />
      <p className="mt-2">Uppástungur</p>
      <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
        <li>A.m.k. einn lástafur</li>
        <li>A.m.k. einn hástafur</li>
        <li>Einn tölustafur</li>
        <li>Lágmark 8 stafa orð</li>
      </ul>
    </>
  );
  const checkAgreement = (val) => {
    setChecked(val);
    setSkilmalartext(false);
    setValue('accept', val, {shouldValidate: true});
  };
  /**
   * @param  {loginInfo} googleData
   */
  const googleFbLogin = async () => {
    if (checked) {
      setBlockedDocument(true);
      const res = await googleLoginFirebase();
      if (res.success) {
        if (res.dbStatus == 'USER_DOES_NOT_EXIST') {
          await addData(res.email, res.userInfo.name);
        };
        refreshData();
        navigate(from, {replace: true, state: res.userInfo});
      }
      setBlockedDocument(false);
    } else {
      setSkilmalartext(true);
      setValue('accept', false, {shouldValidate: true});
    };
  };
  return (
    <div className="form-demo c-login-frm sd-dark-theme">
      <Toast ref={toast} />
      {<Loading isBlocked={blockedDocument} propmessage='Nýskráning í gangi'></Loading>}
      <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '30vw'}}>
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i className="pi pi-check-circle" style={{fontSize: '5rem', color: 'var(--green-500)'}}></i>
          <h5>Skráning tókst!</h5>
          <p style={{lineHeight: 1.5, textIndent: '1rem'}}>
            Takk fyrir skráninguna <b>{formData.name}</b>. Nú getur þú skráð þig inn undir <b>{formData.email}</b> á innskráningarsíðu héðan í frá.
          </p>
        </div>
      </Dialog>
      <div className="flex justify-content-center">
        <div className="card">
          <div className="c-sd-login-head">
            <h1 className="text-center text-900">Nýskráning</h1>
            <Button className="p-button-outlined p-button-warning w-full sd-exclude sd-google-login" onClick={googleFbLogin}>
              <i className="pi pi-google px-2"></i><span className="px-3">Nýskrá mig með Google</span></Button>
            {skilmalartext && <span className="sd-skilmalar-text">Til að nýskrá sig þarf að samþykkja skilmála</span>}
            <div className="sd-divider">
              <Divider align="center">
                <b>Eða</b>
              </Divider>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="field">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-user" />
                <Controller name="name" control={control} rules={{required: 'Nafn má ekki vera tómt.'}} render={({field, fieldState}) => (
                  <InputText id={field.name} {...field} autoComplete="name" className={classNames({'p-invalid': fieldState.invalid}, 'p-inputtext-lg block')} />
                )} />
                <label htmlFor="name" className={classNames({'p-error': errors.name})}>Nafn*</label>
              </span>
              {getFormErrorMessage('name')}
            </div>
            <div className="field">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <Controller name="email" control={control}
                  rules={{required: 'Svæði má ekki vera tómt og verður að vera gilt netfang', pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Netfang er ekki gilt. T.d. example@email.com'}}}
                  render={({field, fieldState}) => (
                    <InputText id={field.name} {...field} autoComplete="username" className={classNames({'p-invalid': fieldState.invalid}, 'p-inputtext-lg block')} />
                  )} />
                <label htmlFor="email" className={classNames({'p-error': !!errors.email})}>Póstfang*</label>
              </span>
              {getFormErrorMessage('email')}
              {existsErr ? <small id="username2-help" className="p-error">Netfang er þegar skráð.</small> : ''}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller name="password" control={control} {...register('password', {required: true})} rules={{required: 'Lykilorð má ekki vera tómt.'}} render={({field, fieldState}) => (
                  <Password id={field.name} {...field} autoComplete="new-password" toggleMask className={classNames({'p-invalid': fieldState.invalid}, 'p-inputtext-lg block')} header={passwordHeader} footer={passwordFooter} />
                )} />
                <label htmlFor="password" className={classNames({'p-error': errors.password})}>Lykilorð*</label>
              </span>
              {getFormErrorMessage('password')}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller name="password2" control={control} {...register('password2', {required: true, validate: (val: string) => {
                  if (watch('password') != val) {
                    return 'Lykilorð er ekki eins';
                  }
                },
                })} rules={{required: 'Lykilorð má ekki vera tómt.'}} render={({field, fieldState}) => (
                  <Password id={field.name} {...field} autoComplete="new-password" toggleMask className={classNames({'p-invalid': fieldState.invalid}, 'p-inputtext-lg block')} header={passwordHeader} footer={passwordFooter} />
                )} />
                <label htmlFor="password2" className={classNames({'p-error': errors.password2})}>Lykilorð aftur*</label>
              </span>
              {getFormErrorMessage('password2')}
            </div>
            <div className="field-checkbox">
              <Controller name="accept" control={control} rules={{required: true}} render={({field, fieldState}) => (
                <>
                  <Checkbox inputId={field.name} onChange={(e) => checkAgreement(e.checked)} checked={checked} className={classNames({'p-invalid': fieldState.invalid})} />
                  <Skilmalar openSkilmalar={skilmalarDisp} accept={checkAgreement} setskilmalarDisp={setskilmalarDisp}/>
                </>
              )} />
              <label htmlFor="accept" className={classNames({'p-error': errors.accept})}>Ég samþykki <label className="p-linklike sd-text-orange" onClick={openAgreement}>skilmálana*</label></label>
            </div>
            <Button type="submit" loading={loading1} label="Staðfesta" className="mt-2 p-button--purple" />
          </form>
        </div>
      </div>
    </div>
  );
}
Register.propTypes = {
  refreshData: PropTypes.any,
};
