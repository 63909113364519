import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Hooks from '../../components/hooks/Hooks.js';
import Loading from '../../components/loading/Loading';
import {getSubinvoice, getSubscription, permdeleteSubscription} from '../../rapydApi.js';
import {paystatusTranslation, secsToFormatedDate, statusTranslation} from '../../utils/helperFuncs.js';
import Allownotification from '../../components/notification/Allownotification.js';
import './User.scss';

/**
 * @return {User} React.fragment
 */
function User({uData}) {
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const [userCreated, setuserCreated] = useState();
  const [userUid, setuserUid] = useState();
  const [firebError, setFirebError] = useState(false);
  const [saveBtnTooltipText, setSaveBtnTooltipText] = useState('Smelltu til að afrita hlekk');
  // Rapyd
  const [subStatus, setsubStatus] = useState('');
  const [allSubs, setAllsubs] = useState([]);
  const [paymentStatus, setpaymentStatus] = useState();
  const [subCreated, setsubCreated] = useState();
  const [subNextpayment, setsubNextpayment] = useState();
  const [subPeriod, setsubPeriod] = useState();
  const [isSub, setisSub] = useState(false);
  const [rapydLoading, setrapydLoading] = useState(true);
  const [rapydError, setRapydError] = useState(false);
  const [canceled, setcanceled] = useState();
  const [coupon, setCoupon] = useState();
  const [last4, setlast4] = useState();
  const [media, setMedia] = useState(false);
  const [HooksDisp, setHooksDisp] = useState(false);
  const [cusid, setCusid] = useState(false);
  const [triggeropen, setTriggeropen] = useState(false);
  const getRange = (from, to) => {
    return secsToFormatedDate(from) + '-' + secsToFormatedDate(to);
  };

  const deleteSub = (id, sub) => {
    const del = async () => {
      try {
        await permdeleteSubscription(sub.el.id);
        window.location.href = '/minarsidur';
      } catch (e) {
      };
    };
    del();
  };
  /**
   * sæki hér plans hjá rapyd
   */
  useEffect( () => {
    const getData = async (data) => {
      const userspageData = data;
      if ( userspageData.rapydData?.subscriptions ) {
        const subsArr = userspageData.rapydData.subscriptions.data;
        userspageData.allSubscription = subsArr;
        const sub = userspageData.rapydData.subscriptions.data[0];
        if (sub.status != 'canceled') {
          const subDetails = await getSubscription(sub.id);
          if (subDetails.status?.status == 'SUCCESS') {
            userspageData.activeSub = subDetails.data;
            const tmpCard = userspageData.rapydData.payment_methods.data.find((o) => o.id === subDetails.data.payment_method);
            userspageData.activeSubPaymentinfo = tmpCard;
          };
          try {
            const subInvoice = await getSubinvoice(sub.id);
            if (subInvoice.status?.status == 'SUCCESS') {
              userspageData.invoices = subInvoice.data;
              userspageData.paymentStatus = subInvoice.data[0].status;
              if (sub.status == 'unpaid') {
                userspageData.Offerpayment = {sub: sub, subInvoice: subInvoice};
              };
            };
          } catch (e) {
            console.log(e);
          };
        }
      };
      setCusid(userspageData.rapydData?.id);
      if (!!userspageData.activeSubPaymentinfo) {
        setlast4(userspageData.activeSubPaymentinfo.last4);
      };
      if (!!userspageData.gData) {
        setuserName(userspageData.gData.name || 'Gat ekki sótt grunn upplýsingar');
        setuserEmail(userspageData.gData.email);
        try {
          setuserCreated(secsToFormatedDate(userspageData.gData.created.seconds));
        } catch (e) {
        };
        setuserUid(userspageData.gData.uid);
      } else {
        setFirebError(true);
      };
      if (!!userspageData.activeSub) {
        setcanceled(userspageData.activeSub.cancel_at_period_end);
        setsubStatus(statusTranslation(userspageData.activeSub.status));
        setsubCreated(secsToFormatedDate(userspageData.activeSub.created_at));
        setsubNextpayment(secsToFormatedDate(userspageData.activeSub.current_period_end));
        setsubPeriod(secsToFormatedDate(userspageData.activeSub.current_period_start) + '-' + secsToFormatedDate(userspageData.activeSub.current_period_end));
        try {
          if (!!userspageData.activeSub.discount && userspageData.activeSub.discount.coupon.duration == 'repeating' && userspageData.activeSub.discount.valid ) {
            const tmpcoupon = userspageData.activeSub.discount.coupon;
            setCoupon(`${userspageData.activeSub.discount.number_of_uses} af ${tmpcoupon.duration_in_months} mánuðum nýttir af gjafabréfi`);
          }
        } catch (e) {
          console.log(e);
        }
      };
      if (!userspageData.rapydData) {
        setRapydError(!userspageData.rapydData);
      };
      try {
        setMedia(!!userspageData.isMedia);
      } catch (e) {
      };
      try {
        setpaymentStatus(paystatusTranslation(userspageData.paymentStatus));
        if (!!userspageData.allSubscription) {
          const oldSubs = userspageData.allSubscription.filter((o) => o .status != 'active' && o.status != 'canceled' && o.id != userspageData.activeSub.id);
          if (!!oldSubs) {
            setAllsubs(
                oldSubs.map((el, index)=>
                  <div className="c-user-info-val" key={index}>
                    <div className="c-user-info-val-disp">{getRange(el.current_period_start, el.current_period_end)}</div>
                    <div className={el.status == 'active' ? 'c-user-info-val-val c-user-info-val-success' : 'c-user-info-val-val c-user-info-val-warn'}>{paystatusTranslation(el.status)}
                    </div>
                    {el.status != 'canceled' ? <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text sd-exclude" onClick={(e) => deleteSub(e, {el})} aria-label="Cancel" /> : <></>}
                  </div>));
          }
        };
      } catch (e) {
        console.log(e);
      };
      setisSub(!!userspageData.activeSub);
      setrapydLoading(false);
    };
    if (!!uData) {
      getData(uData);
    } else {
      setrapydLoading(false);
    };
  }, [uData]);
  const navigate = useNavigate();
  const gotoSubscribe = () => {
    navigate('/askriftir', {replace: false});
  };
  const gotoInvoices = () => {
    navigate('/kvittanir', {replace: false});
  };
  const copyToclipboard = () => {
    setSaveBtnTooltipText('Slóð afrituð');
    // https://api.stevedagskra.is/rss/
    navigator.clipboard.writeText(`https://feed.stevedagskra.is/rss/v1/${uData.rapydData.id}/stevedagskra.rss`);
  };
  const openHooks = () => {
    setHooksDisp(true);
  };
  return (
    <div className="c-user-details">
      {<Loading isBlocked={rapydLoading} propmessage='[ ] Sæki gögnin þín'></Loading>}
      <Hooks openSkilmalar={HooksDisp} setskilmalarDisp={setHooksDisp} cusId={cusid}/>
      <div className="c-user-box">
        {!firebError && <section>
          <div className="c-user-info">
            <div className="c-user-info-val c-user-header">
              <div className="c-user-info-val-disp"><i className="pi pi-user mr-2"></i></div>
              <div className="c-user-info-val-val"><b>Aðgangur</b></div>
            </div>
            <div className="c-user-info-val">
              <div className="c-user-info-val-disp">Nafn</div>
              <div className="c-user-info-val-val">{userName}</div>
            </div>
            <div className="c-user-info-val">
              <div className="c-user-info-val-disp">Netfang</div>
              <div className="c-user-info-val-val">{userEmail}</div>
            </div>
            <div className="c-user-info-val">
              <div className="c-user-info-val-disp">Stofnaður</div>
              <div className="c-user-info-val-val">{userCreated}</div>
            </div>
            <div className="c-user-info-val">
              <div className="c-user-info-val-disp">Einkenni</div>
              <div className="c-user-info-val-val">{userUid}</div>
            </div>
          </div>
        </section>}
        <section>
          {firebError && <div className="c-user-info"><div className="c-user-info-val"><div className="c-user-info-val-disp">Villa að sækja gögn</div><div className="c-user-info-val-val c-user-info-val-warn">Gátum ekki sótt gögn í gagnagrunn.</div></div></div>}
          <div className="c-user-info">
            <div className="c-user-info-val c-user-header">
              <div className="c-user-info-val-disp"><i className="pi pi-ticket mr-2"></i></div>
              <div className="c-user-info-val-val"><b>Áskrift</b></div>
            </div>
            {!!media ? <div className="c-user-info-val">
              <div className="c-user-info-val-disp">Tegund áskriftar</div>
              <div className="c-user-info-val-val">Fjölmiðlapassi</div>
            </div> : ''}
            {!rapydLoading && !rapydError && isSub && <>
              {subStatus != 'Virk áskrift' &&
                  <div className="c-user-info-val"><Button label={'Afhverju virkar ekki áskriftin mín?' } className="p-button-text p-button-plain sd-exclude sd-cancel p-button-warning" tooltip="Smelltu hér til að sjá skilaboð frá greiðsluaðila" tooltipOptions={{position: 'bottom'}} icon="pi pi-info-circle" iconPos="right" onClick={openHooks}/></div>
              }
              <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Staða</div>
                <div className="c-user-info-val-val">{subStatus}</div>
              </div>
              <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Staða greiðslu</div>
                <div className="c-user-info-val-val">{paymentStatus}</div>
              </div>
              <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Hóf áskrift</div>
                <div className="c-user-info-val-val">{subCreated}</div>
              </div>
              <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Næsta greiðsla</div>
                <div className="c-user-info-val-val">{!canceled ? subNextpayment : <span className="c-info-danger">Áskrift sagt upp. Virk til {subNextpayment}</span>}</div>
              </div>
              <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Núverandi tímabil</div>
                <div className="c-user-info-val-val">{subPeriod}</div>
              </div>
              <div className="c-user-info-val">
                {!!last4 ? <><div className="c-user-info-val-disp">Kortaupplýsingar</div>
                  <div className="c-user-info-val-val">**** {last4}</div></> :
                  <Button icon="pi pi-chevron-right" label="Greiðsluleið vantar" iconPos="right" onClick={gotoSubscribe} className="p-button-rounded p-button-text p-button-plain sd-exclude sd-cancel" aria-label="Filter" />}
              </div>
              {!!coupon ? <div className="c-user-info-val">
                <div className="c-user-info-val-disp">Gjafabréf</div>
                <div className="c-user-info-val-val">{coupon}</div>
              </div> : <div className="c-user-info-val">
                <Button label="Virkja gjafabréf" className="p-button-text p-button-plain sd-exclude sd-cancel" icon="pi pi-chevron-right" iconPos="right" onClick={gotoSubscribe}/>
              </div>}
            </>}
          </div>
          {!rapydLoading && isSub && <div className="c-user-info">
            <div className="c-user-info-val">
              <Button label="Greiðslusaga" className="p-button-text p-button-plain sd-exclude sd-cancel" icon="pi pi-chevron-right" iconPos="right" onClick={gotoInvoices}/>
            </div>
            <div className="c-user-info-val">
              <Button label="Breyta áskrift" className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" icon="pi pi-chevron-right" iconPos="right" onClick={gotoSubscribe}/>
            </div>
          </div>}
          {rapydError && <div className="c-user-info"><div className="c-user-info-val"><div className="c-user-info-val-disp">Villa að sækja gögn</div><div className="c-user-info-val-val c-user-info-val-warn">Ekki næst samband við greiðsluaðila</div></div></div>}
          {!rapydLoading && !isSub && !rapydError && <div className="c-user-info">
            <div className="c-user-info-val">
              <div className="c-user-info-val-disp"></div>
              <div className="c-user-info-val-val"><Button label="Gerast áskrifandi" className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" onClick={gotoSubscribe}/></div>
            </div>
          </div>}
        </section>
        <section>
          <div className="c-user-info">
            <div className="c-user-info-val c-user-header">
              <div className="c-user-info-val-disp"><i className="pi pi-user mr-2"></i></div>
              <div className="c-user-info-val-val"><b>Stillingar</b></div>
            </div>
            <div className="c-user-info-val">
              <Button label="RSS" className="p-button-text p-button-plain sd-exclude sd-cancel" tooltip={saveBtnTooltipText} tooltipOptions={{position: 'bottom'}} icon="pi pi-copy" iconPos="right" onClick={copyToclipboard}/>
            </div>
            <div className="c-user-info-val">
              <Button label="Tilkynningar" className="p-button-text p-button-plain sd-exclude sd-cancel" icon="pi pi-bell" iconPos="right" onClick={() => setTriggeropen(true)}/>
            </div>
          </div>
          <Allownotification popupallowed={true} setTriggeropen={setTriggeropen} triggeropen={triggeropen} ></Allownotification>
        </section>
        {!!allSubs.length > 0 &&<section>
          <div className="c-user-info">
            <div className="c-user-info-val c-user-header">
              <div className="c-user-info-val-disp"><i className="pi pi-clock mr-2"></i></div>
              <div className="c-user-info-val-val"><b>Eldri áskriftir</b></div>
            </div>
            {allSubs}
          </div>
        </section>}
      </div>
    </div>
  );
}
export default User;
User.propTypes = {
  uData: PropTypes.any,
};
