import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import './Pubquiz.scss';
import {getEvent} from '../../podcastApi.js';

/**
 * @return {User} React.fragment
 */
function Pubquiz({uData}) {
  const [showBanner, setShowBanner] = useState(false); // State to control the visibility of the banner
  const [formurl, setFormurl] = useState(false);
  const navigate = useNavigate();
  const gotoSubscribe = () => {
    navigate('/forsida', {replace: false});
  };
  useEffect(() => {
    const getData = async () => {
      const data = await getEvent();
      // Convert Unix timestamp to milliseconds
      setFormurl(data.skraningUrl);
      const eventDate = new Date(data.dagsetning.seconds * 1000);
      const currentDate = new Date();
      // Check if the event date has passed
      setShowBanner(currentDate < eventDate);
    };
    getData();
  }, []);
  return (
    <div className="c-pq-body">
      <div className="c-pq-head">
        <div className="c-user-info-val">
          <Button label="Til baka" className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" icon="pi pi-chevron-left" onClick={gotoSubscribe}/>
        </div>
      </div>
      <div className="c-pq">
        <div className="c-pq-mid">
          {showBanner ? <iframe
            src={formurl}
            width="640"
            height="1702"
            style={{maxWidth: '100vw'}}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading...
          </iframe> : 'Enginn viðburður væntanlegur'}
        </div>
      </div>
    </div>
  );
}
export default Pubquiz;
Pubquiz.propTypes = {
  uData: PropTypes.any,
};
