import {BlockUI} from 'primereact/blockui';
import {Button} from 'primereact/button';
import {Image} from 'primereact/image';
import PropTypes from 'prop-types';
// import React, {useRef, useState} from 'react';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {ControlBar, Player} from 'video-react';
import 'video-react/dist/video-react.css';
import {getUrl} from '../../podcastApi.js';
// import {addCoupon} from '../../podcastApi.js';
// import {createCoupon} from '../../rapydApi.js';
import './Video.scss';
/**
 * @param  {object} episodes
 * @param  {string} type
 *
 * @return {object} in order
 */
function sort(episodes, type) {
  let returnArr = episodes;
  if (type=='D') {
    returnArr = episodes.sort(
        (objA, objB) => Number(objB.Created) - Number(objA.Created),
    );
  };
  if (type=='A') {
    returnArr = episodes.sort(
        (objA, objB) => Number(objA.Created) - Number(objB.Created),
    );
  };
  if (type=='R') {
    returnArr = episodes;
    returnArr.sort((a, b) => (a.order < b.order) ? 1 : ((b.order < a.order) ? -1 : 0));
  }
  if (type=='P') {
    returnArr = episodes;
    returnArr.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
  }
  return returnArr;
};
/**
 * @return {User} React.fragment
 */
function Video({uData}) {
  const [epurl, setEpurl] = useState();
  const [play, setPlay] = useState(false);
  const [defaultImg, setdefaultImg] = useState();
  const [player, setPlayer] = useState();
  const [playerref, setPlayerref] = useState();
  const [blockedPanel, setblockedPanel] = useState(true);
  const [episodes, setEpisodes] = useState([]);
  const eps = [
    {
      name: 'Extra Völlurinn // Fjölnir',
      description: 'Fyrir fyrsta heimaleik Fjölnis í efstu deild í tvö ár fara Steve bræður og reyna að finna hvar stemmningin á leikdag er í Grafarvoginum.',
      imgUrl: 'myndir/fjolnir.PNG',
      epUrl: 'video/S1E1_Fjolnir.mp4',
      length: '19m',
      order: 1,
    },
    {
      name: 'Origi völlurinn // Valur',
      description: 'Föstudagsleikur á fallegum sumardegi í Reykjavík. Fjósið, Club dub og færeyskur varnarleikur',
      imgUrl: 'myndir/valur.PNG',
      epUrl: 'video/S1E2_Valur.mp4',
      length: '17m',
      order: 2,
    },
    {
      name: 'Würth völlurinn // Fylkir',
      description: 'Það er létt yfir lautaferð söng skáldið. Blásteinn, appelsínugult leður, núðlur og Tjörnes band. Allt þett og mikið meira þegar Steve kíkti á toppslag Fylkis og KR.',
      imgUrl: 'myndir/fylkir.png',
      epUrl: 'video/S1E3_Fylkir.mp4',
      length: '17m',
      order: 3,
    },
    {
      name: 'Kópavogsvöllur // Breiðablik',
      description: 'Steve Dagskrá mættu á Kópavogsvöllinn til að reyna slá áhorfendamet í Pepsi Max deild kvenna í toppslag Breiðabliks og Vals. Catalina, húðflúr og óvæntar hremmingar settur strik í reikninginn',
      imgUrl: 'myndir/blix.png',
      epUrl: 'video/S1E4_Blix.mp4',
      length: '19m',
      order: 4,
    },
    {
      name: 'Norðurálsvöllur // ÍA',
      description: 'Gamla Akraborgin var endurvakin þegar Steve mættu sjóleiðis á skipaskaga. Rigning, falinn keilustaður og farið yfir söguna á þessu frægasta fótboltaplássi landsins.',
      imgUrl: 'myndir/ia.png',
      epUrl: 'video/S1E5_IA.mp4',
      length: '22m',
      order: 5,
    },
    {
      name: 'Steve Hátíðar Dagskrá',
      description: 'Í þessari áramótabombu Steve gera þeir upp íslandsmótið sem aldrei kláraðist. Áhöfn ársins, best klæddi þjálfarinn og stóra reimamál Kidda Jóns er meðal þess sem er krufið.',
      imgUrl: 'myndir/hatid.png',
      epUrl: 'video/S1E6_hatid.mp4',
      length: '1h23m',
      order: 6,
    },
  ];


  useEffect(() => {
    const workData = () => {
      const tmpEps = [];
      eps.forEach( async (e) => {
        const tmpA = e;
        try {
          const url = await getUrl(e.imgUrl);
          tmpA.imgUrl2 = url;
        } catch (e) {
          console.log(e);
        };
        tmpEps.push(tmpA);
        updateEps(tmpEps);
      });
    };
    workData();
  }, []);
  const updateEps = async (arr) => {
    const wcEps = sort(arr, 'P');

    try {
      const url = await getUrl(wcEps[0].epUrl);
      // setdefaultImg(imgpath);
      setdefaultImg(wcEps[0].imgUrl2);
      setEpurl(url);
    } catch (e) {
      console.log(e);
    };
    setEpisodes( wcEps);
  };
  useEffect(() => {
    if (!!uData) {
      if (uData.isSub) {
        setblockedPanel(false);
      };
    };
    if (!play) {
      setPlayer(<><Player playsInline
        ref={(player) => {
          setPlayerref(player);
        }} poster={defaultImg}>
        <source
          src={epurl}
        />
        <ControlBar autoHide={true} />
      </Player></>);
    } else {
      setPlayer(<><Player playsInline autoPlay
        ref={(player) => {
          setPlayerref(player);
        }} poster={defaultImg}>
        <source
          src={epurl}
        />
        <ControlBar autoHide={true} />
      </Player></>);
    }
    if (playerref && epurl) {
      playerref.load();
    };
  }, [epurl, defaultImg, play, uData]);

  const playEpisode = async (path, imgpath) => {
    try {
      const url = await getUrl(path);
      setEpurl(url);
      setPlay(true);
      // setdefaultImg(imgpath);
    } catch (e) {
      console.log(e);
      alert(`Villa kom upp: ${e}`);
    };
  };
  const changeEpisode = async (path, imgpath) => {
    try {
      const url = await getUrl(path);
      setPlay(false);
      setEpurl(url);
      setdefaultImg(imgpath);
    } catch (e) {
      alert(`Villa kom upp: ${e}`);
    };
  };
  if (uData) {
    if (uData.isSub) {

    } else {

    }
  };

  return (
    <div className="c-video-container">
      <Helmet>
        <title>Sjónvarpsþættir</title>
      </Helmet>
      <h1>Sjónvarpsþættir</h1>
      <div className="c-video-player">
        <BlockUI blocked={blockedPanel} template={<i className='pi pi-lock' style={{'fontSize': '3rem', 'zIndex': '985'}} />}>
          {player}
        </BlockUI>
      </div>
      <div className="c-video-playlist">
        <div className="c-title">
          1. Þáttaröð
        </div>
        <div>
          <ul>
            {blockedPanel ? episodes?.map((value)=> {
              return <><li key={Math.floor(Math.random() * 100000000)}>
                <BlockUI blocked={blockedPanel? true : false} template={<i className='pi pi-lock' style={{'fontSize': '3rem', 'zIndex': '985'}} />}>
                  <div className="c-episode-item">
                    <div className="c-episode-controls">
                      <div className="c-episode-info">
                        <div className="c-episode-img-text" onClick={() => changeEpisode(value.epUrl, value.imgUrl2)}>
                          <div className="c-episode-image">
                            <Image src={value.imgUrl2}></Image>
                          </div>
                          <div className="c-episode-text">
                            <div className="c-episode-title">
                              {value.name}
                            </div>
                            <div className="c-episode-description">
                              {value.length}
                            </div>
                          </div>
                        </div>
                        <div className="c-episode-playbtn"><Button icon="pi pi-play" className="p-button-secondary p-button-rounded p-button-outlined sd-exclude" aria-label="Bookmark" onClick={() => playEpisode(value.epUrl)}/></div>
                      </div>
                    </div>
                    <div className="c-episode-about">
                      {value.description}</div>
                  </div>
                </BlockUI>
              </li></>;
            }) : episodes?.map((value)=> {
              return <><li key={Math.floor(Math.random() * 100000000)}>
                <div className="c-episode-item">
                  <div className="c-episode-controls">
                    <div className="c-episode-info">
                      <div className="c-episode-img-text" onClick={() => changeEpisode(value.epUrl, value.imgUrl2)}>
                        <div className="c-episode-image">
                          <Image src={value.imgUrl2}></Image>
                        </div>
                        <div className="c-episode-text">
                          <div className="c-episode-title">
                            {value.name}
                          </div>
                          <div className="c-episode-description">
                            {value.length}
                          </div>
                        </div>
                      </div>
                      <div className="c-episode-playbtn"><Button icon="pi pi-play" className="p-button-secondary p-button-rounded p-button-outlined sd-exclude" aria-label="Bookmark" onClick={() => playEpisode(value.epUrl)}/></div>
                    </div>
                  </div>
                  <div className="c-episode-about">
                    {value.description}</div>
                </div>
              </li></>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Video;
Video.propTypes = {
  uData: PropTypes.any,
};
