/* eslint no-unused-vars: ['error', { 'varsIgnorePattern': '[aA]pp' }]*/
/* eslint-disable no-console */
/* eslint-disable new-cap */
import axios from 'axios';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const analytics = getAnalytics(app);
// const { publicRuntimeConfig } = getConfig();
// const { apiUrl } = publicRuntimeConfig;

// LOCAL
// const baseURL = 'http://127.0.0.1:8080/api';
// TEST
// const baseURL = 'https://steve-api-365112.de.r.appspot.com/api';
// RAUN
const baseURL = 'https://steve-dagskra-api.ew.r.appspot.com/api';
const version = '/v1';

// Replace 'your_username' and 'your_password' with your actual credentials
const username = process.env.REACT_APP_SD_USERNAME;
const password = process.env.REACT_APP_SD_PASSWORD;

// Create an Axios instance with default headers
const axiosWithAuth = axios.create({
  auth: {
    username,
    password,
  },
});
/* todo isomorphic-fetch og útfæra köll í vefþjónustu með slóð úr config */

/**
 * @param  {string} path
 * @param  {json} data
 */
async function axiosPost(path, data) {
  return axiosWithAuth.post(baseURL + version + path, data);
};
/**
 * @param  {string} path
 */
async function axiosDelete(path) {
  return axiosWithAuth.delete(baseURL + version + path);
};
/**
 * @param  {string} path
 */
async function axiosGet2(path) {
  return axiosWithAuth.get(baseURL + version + path);
};
/**
 * @param  {epname} epname
 * @param  {description} description
 * @param  {imagedownloadurl} imagedownloadurl
 * @param  {url} url
 */
export async function sendNotifications(epname, description, imagedownloadurl, url) {
  const data =
  {
    'title': epname,
    'body': description,
    'image': imagedownloadurl,
    'url': url,
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/sendMessage';

  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}

/**
 * @param  {epname} epname
 * @param  {description} description
 * @param  {imagedownloadurl} imagedownloadurl
 * @param  {url} url
 */
export async function generateAdminreport() {
  // Get the date for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
  const day = String(tomorrow.getDate()).padStart(2, '0');
  const datestr = `${year}-${month}-${day}`;
  const data =
  {
    'generateHistory': false,
    'sendEmail': true,
    'cleanupCoupons': false,
    'date': datestr,
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/generate-report-and-send-email';

  const response = true;
  try {
    axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response;
}
/**
 * @param  {cusId} cusId
 * @param  {banned} banned
 */
export async function toggleBanned(cusId, banned) {
  const data =
  {
    'isBanned': banned,
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/customers/'+cusId;

  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {user_email} email
 * @param  {user_name} userName
 */
export async function createOrGetRapydUser(email, userName) {
  // vantar virkni t.a. sækja notandanafn
  const userExists = await getUserinfo(email);
  if ( userExists.status.status = 'SUCCESS' ) {
    // Ef notandi er ekki til búa hann til
    if ( userExists.data.length == 0) {
      const path = '/customers';
      const data = {
        'name': userName,
        'email': email,
      };
      let response;
      try {
        response = await axiosPost(path, data);
      } catch (e) {
        return (e);
      }
      return response.data.response;
    } else {
      // ef notandinn er til skila cus_id
      return userExists;
    }
  } else {
    return userExists;
  };
}

/**
 * @param  {subscription} subscription
 */
export async function checkSubstatus(subscription) {
  // vantar virkni t.a. sækja notandanafn
  const path = '/payments/subscriptions/'+subscription;

  let response;
  try {
    response = await axiosGet2(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {ccIdRapyd} cardId
 * @param  {planid} planId
 * @param  {customer} customerId
 * @param  {customer} coupon
 */
export async function subscribe(cardId, planId, customerId, coupon) {
  const data =
  {
    'customer': customerId,
    'plan': planId,
    'payment_method': cardId,
    'coupon': coupon,
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/payments/subscriptions';

  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {asdf} type
 * @param  {asdf} number
 * @param  {asdf} expMonth
 * @param  {asdf} expYr
 * @param  {asdf} cvv
 * @param  {asdf} name
 * @param  {asdf} cusId
 */
export async function addUserpaymentmethod(type, number, expMonth, expYr, cvv, name, cusId) {
  const data =
  {
    'type': type,
    'fields': {
      'number': number,
      'expiration_month': expMonth,
      'expiration_year': expYr,
      'cvv': cvv,
      'name': name,
    },
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/customers/'+ cusId +'/payment_methods';

  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {asdf} type
 * @param  {asdf} number
 * @param  {asdf} expMonth
 * @param  {asdf} expYr
 * @param  {asdf} cvv
 * @param  {asdf} name
 * @param  {asdf} cusId
 * @param  {asdf} paymentMethodid
 */
export async function updateUserpaymentmethod(type, number, expMonth, expYr, cvv, name, cusId, paymentMethodid) {
  const data =
  {
    'type': type,
    'fields': {
      'number': number,
      'expiration_month': expMonth,
      'expiration_year': expYr,
      'cvv': cvv,
      'name': name,
    },
  };
  // vantar virkni t.a. sækja notandanafn
  const path = '/customers/'+ cusId +'/payment_methods/'+paymentMethodid;

  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} userId
 * @param  {userinfo} paymentMethod
 */
export async function deletePaymentmethod(userId, paymentMethod) {
  const path = '/customers/'+ userId +'/payment_methods/'+paymentMethod;

  let response;
  try {
    response = await axiosDelete(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 */
export async function deleteSubscription(subId) {
  const path = '/payments/subscriptions/'+ subId;
  let response;
  try {
    response = await axiosDelete(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 */
export async function permdeleteSubscription(subId) {
  const path = '/payments/delsubscriptions/'+ subId;
  let response;
  try {
    response = await axiosDelete(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 * @param {data} data
 */
export async function updateSubscription(subId, data) {
  const path = '/payments/subscriptions/'+ subId;
  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 */
export async function getSubscription(subId) {
  const path = '/payments/subscriptions/'+ subId;
  let response;
  try {
    response = await axiosGet2(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} couponId
 */
export async function getCoupon(couponId) {
  const path = '/coupons/'+ couponId;
  let response;
  try {
    response = await axiosGet2(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} percent
 * @param  {userinfo} durationMonths
 * @param  {userinfo} code
 */
export async function createCoupon(percent, durationMonths, code) {
  const path = '/coupons';
  const data = {
    percent: percent,
    duration_in_months: durationMonths,
    code: code,
  };
  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} email
 * @param  {userinfo} cusId
 */
export async function getCheckout(email, cusId) {
  const path = '/checkout';
  const data = {
    email: email,
    cus_id: cusId,
  };
  let response;
  try {
    response = await axiosPost(path, data);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {invoiceId} invoiceId
 */
export async function getInvoice(invoiceId) {
  const path = '/invoice/'+ invoiceId;
  let response;
  try {
    response = await axiosGet2(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 */
export async function getSubinvoice(subId) {
  const path = '/invoices/'+ subId;
  let response;
  try {
    response = await axiosGet2(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {userinfo} subId
 */
export async function paySubinvoice(subId) {
  const path = '/invoices/'+ subId+'/pay';
  let response;
  try {
    response = await axiosPost(path);
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param {user_mail} userEmail
 */
export async function getUserinfo(userEmail) {
  const email = userEmail;
  const path = '/customers='+email;

  let response;
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param {cusId} cusId
 */
export async function getUserinfov2(cusId) {
  const path = '/customers/'+cusId;

  let response;
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {string} paymentType
 */
export async function getPaymentfields(paymentType) {
  // const path = '/v1/payment_methods/required_fields/'+paymentType;
  const path = '/getPaymentfields/' + paymentType;
  let response;
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {object} data
 */
export async function getProducts() {
  const path = '/products';

  let response;
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {object} data
 */
export async function getPlans() {
  const path = '/getPlans';

  let response;
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
/**
 * @param  {object} data
 */
export async function getPaymethods() {
  const path = '/payment_methods';
  let response;
  /* try {
    response = await getData(path, 'get');
  } catch (e) {
    return (e);
  }*/
  try {
    response = await axiosGet2(path, 'get');
  } catch (e) {
    return (e);
  }
  return response.data.response;
}
