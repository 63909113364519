
/* eslint-disable valid-jsdoc */
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {confirmDialog} from 'primereact/confirmdialog';
import {confirmPopup} from 'primereact/confirmpopup';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import Errormsg from '../../components/errormsg/Errormsg.js';
import {Menu} from 'primereact/menu';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CcForm from '../../components/ccform/CcForm.js';
import Giftcard from '../../components/giftcard/Giftcard.js';
import Loading from '../../components/loading/Loading';
import {updateCoupon} from '../../podcastApi.js';
import Retrypayment from '../../components/retrypayment/Retrypayment.js';
import {deletePaymentmethod, deleteSubscription, getPlans, getProducts, subscribe, updateSubscription, getSubinvoice, getSubscription} from '../../rapydApi.js';
import {paystatusTranslation, secsToDDMM, secsToFormatedDate, statusTranslation} from '../../utils/helperFuncs.js';
import './Askriftir.scss';
/**
* @return {header}
*/
export default function Askriftir({uData, refreshData}) {
  const toast = useRef(null);
  const menu = useRef(null);
  const navigate = useNavigate();
  const [productName, setproductName] = useState('');
  const [price, setPrice] = useState();
  const [errormessage, seterrormessage] = useState(false);
  // const [paymentMethods, setpaymentMethods] = useState([{name: 'Engin greiðsluleið í boði', key: 'A'}]);
  const [subUserload, setsubUserload] = useState(false);
  const [unsubLoad, setunsubLoad] = useState(false);
  const [loading, setloading] = useState(true);
  // card info
  const [usercc, setUsercc] = useState([]);

  const [showform, setShowform] = useState(false);
  const [cusId, setCusId] = useState();
  const [activeCC, setactiveCC] = useState();
  const [activesubscription, setActivesubscription] = useState();
  const [blockedDocument, setBlockedDocument] = useState(true);
  const [coupon, setCoupon] = useState();
  const [firebcouponId, setFirebcouponId] = useState();

  const [loadingMessage, setloadingMessage] = useState('[ ] Sæki upplýsingar');
  // hook fyrir valda kortategund, visa eða mastercard
  // Rapyd hooks
  const [subStatus, setsubStatus] = useState();
  const [displayHelp, setdisplayHelp] = useState(false);
  const [message, setMessage] = useState('');
  const [paymentStatus, setpaymentStatus] = useState();
  const [subCreated, setsubCreated] = useState();
  const [subNextpayment, setsubNextpayment] = useState();
  const [subPeriod, setsubPeriod] = useState();
  const [subId, setsubId] = useState();
  // const [isSub, setisSub] = useState(false);
  const [canceled, setcanceled] = useState();
  const [plan, setPlan] = useState();
  const [rapydLoading, setrapydLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [subCard, setsubCard] = useState();
  const [last4, setlast4] = useState();
  // const [activeIndex, setActiveIndex] = useState(null);
  /**
   * sæki hér plans hjá rapyd
   */
  useEffect( () => {
    setloading(true);
    const getData = async (data) => {
      const userspageData = data;
      if ( userspageData.rapydData?.subscriptions ) {
        const subsArr = userspageData.rapydData.subscriptions.data;
        userspageData.allSubscription = subsArr;
        const sub = userspageData.rapydData.subscriptions.data[0];
        if (sub.status != 'canceled') {
          const subDetails = await getSubscription(sub.id);
          if (subDetails.status.status == 'SUCCESS') {
            userspageData.activeSub = subDetails.data;
            const tmpCard = userspageData.rapydData.payment_methods.data.find((o) => o.id === subDetails.data.payment_method);
            userspageData.activeSubPaymentinfo = tmpCard;
          };
          try {
            const subInvoice = await getSubinvoice(sub.id);
            if (subInvoice.status.status == 'SUCCESS') {
              userspageData.invoices = subInvoice.data;
              userspageData.paymentStatus = subInvoice.data[0].status;
              if (sub.status == 'unpaid') {
                userspageData.Offerpayment = {sub: sub, subInvoice: subInvoice};
              };
            };
          } catch (e) {
            console.log(e);
          };
        };
      };
      if (!!userspageData.rapydData) {
        setCusId(userspageData.rapydData.id);
        if (!!userspageData.rapydData.payment_methods) {
          if (!!userspageData.rapydData.payment_methods.data) {
            try {
              setactiveCC(userspageData.rapydData.payment_methods.data[0]);
              const tmpCards = userspageData.rapydData.payment_methods.data.filter((o) => o.next_action == 'not_applicable');
              setUsercc(tmpCards);
              if (tmpCards.length > 0 ) {
                setShowform(false);
              } else {
                setShowform(true);
              }
            } catch (e) {
              console.log(e);
            };
          };
        } else {
          setShowform(true);
        };
      };
      if (!!userspageData.activeSubPaymentinfo) {
        setactiveCC(userspageData.activeSubPaymentinfo||userspageData.rapydData.payment_methods.data[0]);
        setsubCard(userspageData.activeSubPaymentinfo);
        setlast4(userspageData.activeSubPaymentinfo.last4);
        setActivesubscription(userspageData.activeSub);
      };
      if (!!userspageData.activeSub) {
        setcanceled(userspageData.activeSub.cancel_at_period_end);
        setsubStatus(statusTranslation(userspageData.activeSub.status));
        setsubCreated(secsToFormatedDate(userspageData.activeSub.created_at));
        setsubNextpayment(secsToFormatedDate(userspageData.activeSub.current_period_end));
        setsubPeriod(secsToDDMM(userspageData.activeSub.current_period_start) + '-' + secsToDDMM(userspageData.activeSub.current_period_end));
        setsubId(userspageData.activeSub.id);
        try {
          setpaymentStatus(paystatusTranslation(userspageData.paymentStatus));
        } catch (e) {
          console.log(e);
        };
      };
      // setisSub(userspageData.isSub);
      setloading(false);
      setrapydLoading(false);
      setsubUserload(false);
      setBlockedDocument(false);
    };
    if (!!uData) {
      getData(uData);
    };
  }, [uData, refreshKey]);
  /**
   * sæki hér plans hjá rapyd
   */
  useEffect( () => {
    // Sæki áskriftarleiðir
    const getData = async () => {
      const plans = await getPlans();
      setPlan(plans.data[0].id);
      const priceStr = plans.data[0].tiers[0].amount + ' ' + plans.data[0].currency + '/mán';
      setPrice(priceStr);
    };
    getData();
    return () => {

    };
  }, []);

  /**
   * sæki hér products hja rapyd
   */
  useEffect( () => {
    // Sæki vörur
    const getData = async () => {
      const prodResp = await getProducts();
      setproductName(prodResp.data[0].name);
    };
    getData();
    return () => {
    };
  }, []);

  const ccPicked = (event) => {
    setactiveCC(event);
  };
  const cancelCancel = () => {
    toast.current.show({severity: 'success', summary: 'Hætt við', detail: 'pheww... takk fyrir', life: 3000});
  };

  const delConfirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Ertu viss um að þú viljir segja upp áskrift?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger sd-exclude',
      acceptLabel: 'Já takk',
      rejectLabel: 'Nei',
      accept: deleteSub,
      reject: cancelCancel,
    });
  };
  const deleteSub = () => {
    const getData = async () => {
      setunsubLoad(true);
      const delSub = await deleteSubscription(subId);
      if ( delSub.status?.status == 'SUCCESS' ) {
        uData.activeSub.cancel_at_period_end = true;
        toast.current.show({severity: 'success', summary: 'Áskrift sagt upp', detail: 'Við þökkum viðskiptin. Þú getur enn hlustað til ' + subNextpayment, life: 3000});
        setsubCard(activeCC);
        setcanceled(true);
      } else {
        // toast.current.show({severity: 'error', summary: 'Aðgerð tókst ekki', detail: 'Villa kom upp', life: 3000});
        seterrormessage({userInfo: `Ekki tókst að segja upp áskriftinni. Prófaðu að endurhlaða síðu og reyna aftur. Ef það virkar ekki, endilega hafðu samband við admin@stevedagskra.is.\n\n Skilaboð greiðsluaðila: ${delSub.status?.message}`,
          error: delSub.status?.message || JSON.stringify(delSub)});
      }
      // Þarf að útfæra if success
      setunsubLoad(false);
    };
    getData();
    return () => {
    };
  };

  const undoCancel = () => {
    const getData = async () => {
      setunsubLoad(true);
      const data = {
        'cancel_at_period_end': false,
      };
      const delSub = await updateSubscription(subId, data);
      if ( delSub.status.status == 'SUCCESS' ) {
        uData.activeSub.cancel_at_period_end = false;
        toast.current.show({severity: 'success', summary: 'Hætt við uppsögn', detail: 'Takk fyrir!', life: 3000});
        setsubCard(activeCC);
        setcanceled(false);
      } else {
        // toast.current.show({severity: 'error', summary: 'Aðgerð tókst ekki', detail: 'Villa kom upp', life: 3000});
        seterrormessage({userInfo: `Ekki tókst að hætta við. Prófaðu að endurhlaða síðu og reyna aftur. Ef það virkar ekki, endilega hafðu samband við admin@stevedagskra.is. \n \n Skilaboð greiðsluaðila: ${delSub.status?.message}`,
          error: delSub.status?.message || JSON.stringify(delSub)});
      };
      // Þarf að útfæra if success
      setunsubLoad(false);
    };
    getData();
  };
  // Til þess að uppfæra greiðsluleið
  const updatePaymentmethod = () => {
    const getData = async () => {
      setloadingMessage('[ ] Uppfæri greiðsluleið');
      setBlockedDocument(true);
      const data = {
        'payment_method': activeCC.id,
      };
      const delSub = await updateSubscription(subId, data);
      if ( delSub.status.status == 'SUCCESS' ) {
        toast.current.show({severity: 'success', summary: 'Aðgerð tókst', detail: 'Greiðsluleið breytt', life: 3000});
        uData.activeSubPaymentinfo = activeCC;
        setlast4(activeCC.last4);
        setsubCard(activeCC);
      } else {
        // toast.current.show({severity: 'error', summary: 'Aðgerð tókst ekki', detail: 'Greiðsluleið ekki breytt', life: 3000});
        seterrormessage({userInfo: `Ekki tókst að breyta greiðsluleið. Prófaðu að endurhlaða síðu og reyna aftur. Ef það virkar ekki, endilega hafðu samband við admin@stevedagskra.is.\n Skilaboð greiðsluaðila: ${delSub.status?.message}`,
          error: delSub.status?.message || JSON.stringify(delSub)});
      }
      setBlockedDocument(false);
      setRefreshKey(refreshKey + 1);
    };
    getData();
    return () => {
    };
    // Þarf að útfæra if success
  };
  /**
   * Fall til að eyða korti
   */
  const accept = () => {
    const getData = async () => {
      setBlockedDocument(true);
      setloadingMessage('[ ] Eyði korti');
      const delCard = await deletePaymentmethod(cusId, activeCC.id);
      if ( delCard.status.status == 'ERROR' ) {
        if (delCard.status.error_code == 'ERROR_DELETE_PAYMENT_METHOD_IN_USE') {
          setMessage('Kort er með virka áskrift, segðu upp áskriftinni fyrst. Ath. ekki er rukkað þegar áskrift hefur verið sagt upp.');
          setdisplayHelp(true);
        } else {
          setMessage(delCard.status.message);
          setdisplayHelp(true);
        };
        setBlockedDocument(false);
      } else {
        // Núllstilli actice credit card
        const tmpArr = usercc.filter(function(item) {
          return item !== activeCC;
        });
        setUsercc(tmpArr);
        try {
          if (tmpArr.length == 0) {
            setShowform(true);
          };
        } catch (e) {
          console.log(e);
        };
        setactiveCC(null);
        // Refresh á töfluna
        refreshData();
        // setRefreshKey(refreshKey + 1);
        // window.location.href = '/askriftir';
      };
    };
    getData();
    return () => {
    };
  };
  const reject = () => {
  };
  const deleteCard = (e, a) => {
    confirmDialog({
      message: 'Ertu viss um að þú viljir eyða þessu korti?',
      header: 'Eyða greiðsluleið',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger sd-exclude',
      acceptLabel: 'Eyða korti',
      rejectLabel: 'Hætta við',
      accept,
      reject,
    });
  };
  /**
   * Main function to subscribe user to Steve dagskrá
   */
  const subscribeUser = () => {
    const getData = async () => {
      setsubUserload(true);
      setloadingMessage('[ ] Skrái áskrift');
      setBlockedDocument(true);
      const sub = await subscribe(activeCC.id, plan, cusId, coupon);
      if (sub.status.status=='SUCCESS') {
        if (!!firebcouponId) {
          updateCoupon(firebcouponId);
        };
        toast.current.show({severity: 'success', summary: 'Til hamingju', detail: 'Þú ert orðinn áskrifandi!'});
        await refreshData();
        setTimeout(() => {
          navigate('/forsida', {replace: true});
        }, 1500);
      } else {
        // toast.current.show({severity: 'error', summary: 'Villa kom upp við skráningu', detail: 'Prufaðu að fara til baka og reyna aftur.'});
        seterrormessage({userInfo: `Ekki tókst að búa til áskrift. Prófaðu að endurhlaða síðu og reyna aftur. Ef það virkar ekki, endilega hafðu samband við admin@stevedagskra.is. \n \n Skilaboð greiðsluaðila: ${sub.status?.message}`,
          error: sub.status?.message || JSON.stringify(sub)});
        refreshData();
      };
      setBlockedDocument(false);
    };
    getData();
  };
  const showPaymenform = (() => {
    setShowform(!showform);
  });
  /**
   *
   *  {Indicates the next action for completing the payment. Response only. One of the following values:
   *  3d_verification - The next action is 3DS authentication. To simulate 3DS authentication in the sandbox, see Simulating 3DS Authentication. Relevant only to card payments.
   *  pending_capture - The next action is pending the capture of the amount. Relevant only to card payments when the amount is not zero.
   *  pending_confirmation - The next action is pending the confirmation for the payment. Relevant to all payment methods excluding card payment.
   *  not_applicable - The payment has completed or the next action is not relevant.}
   *  @param {object} rowData
   *  @return {Element}
   */
  const imageBodyTemplate = (rowData) => {
    if (rowData.next_action == 'not_applicable') {
      return <img src={`${rowData.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />;
    };
    if (rowData.next_action == '3d_verification') {
      return <img src={`${rowData.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image status-error" />;
      // return <span className='customer-badge status-error'>Óstaðfest</span>;
    };
  };

  const footerTemplate = (rowData) => {
    /* return <><Button icon="pi pi-trash" className="p-button-warning sd-exclude" disabled={activeCC ? false : true} onClick={deleteCard}/>
      {subCard===activeCC || !subId ? '' : <Button label="Nota kort" className="p-button-secondary p-button-text sd-exclude" disabled={activeCC ? false : true} onClick={updatePaymentmethod}/>}
      <Button label="Bæta við korti" className="p-button-secondary p-button-text sd-exclude" onClick={showPaymenform}/>
    </>;*/
    const items = [
      {
        label: 'Bæta við',
        icon: 'pi pi-plus',
        command: () => {
          showPaymenform(true, 'ADD');
        },
      },
      {
        label: 'Nota kort',
        icon: 'pi pi-external-link',
        disabled: subCard===activeCC || !subId ? true : false,
        command: () => {
          updatePaymentmethod();
        },
      },
      {
        seperator: true,
      },
      {
        label: 'Eyða',
        icon: 'pi pi-times',
        disabled: !activeCC,
        command: () => {
          deleteCard();
        },
      },
    ];
    return <><Menu model={items} popup ref={menu} id="popup_menu" /><Button label="Aðgerðir" className="p-button-secondary p-button-text sd-exclude" icon="pi pi-chevron-down" iconPos="right" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup /></>;
  };
  const onHide = (name) => {
    setdisplayHelp(false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="Í lagi" onClick={() => onHide(name)} autoFocus />
      </div>
    );
  };
  /**
* @return {render}
*/
  return (
    <div className="c-fullscreen c-sd-orng-background c-payment-container">
      <Errormsg message={errormessage}></Errormsg>
      <div className="flex-center-container toast-cc">
        {<Loading isBlocked={blockedDocument} propmessage={loadingMessage}></Loading>}
        <Toast ref={toast}></Toast>
        <h1>{productName}</h1>
        <div className="payment-form">
          <div className="c-card-container">
            {usercc.length>0 && <><div className="c-cards-table">
              <DataTable value={usercc} selection={activeCC} className="c-cards-table-data" emptyMessage="Bættu við korti" headerStyle={{borderRadius: '1rem'}} footerStyle={{display: 'flex'}}
                onSelectionChange={(e) => ccPicked(e.value)} dataKey="id" responsiveLayout="scroll" loading={loading} footer={footerTemplate} >
                <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
                <Column field="last4" header="Kort"></Column>
                <Column field="name" header="Nafn korthafa"></Column>
                <Column field="image" body={imageBodyTemplate}></Column>
              </DataTable>
            </div></>}
            {showform && <Accordion multiple activeIndex={[0]}>
              <AccordionTab header="Skrá nýtt kort">
                <div className="c-card-form">
                  <CcForm key={activeCC} refreshKey={refreshKey} setRefreshKey={setRefreshKey} cusId={cusId} refreshData={refreshData} setBlockedDocument={setBlockedDocument} />
                </div>
              </AccordionTab>
            </Accordion>}
          </div>
          <div className="c-subscription-form">
            <Accordion multiple activeIndex={[1]}>
              <AccordionTab header="Gjafabréf">
                <Giftcard sub={activesubscription} refreshData={refreshData} setCoupon={setCoupon} setFirebcouponId={setFirebcouponId}></Giftcard>
              </AccordionTab>
              <AccordionTab header="Áskrift">
                {!rapydLoading && !uData.activeSub && <>
                  <div className="c-user-subscribe c-cards-receipt">
                    <div className="c-user-info">
                      <div className="c-user-info-val">
                        <div className="c-user-info-val-disp">{productName}</div>
                        <div className="c-user-info-val-val">{price}</div>
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <Divider align="center"></Divider>
                      <div className="c-user-info-val">
                        <div className="c-user-info-val-disp"><b>Samtals</b></div>
                        <div className="c-user-info-val-val"><b>{!coupon ? price : '0 Kr'}</b></div>
                      </div>
                      <div className="payment-submit">
                        <Button label={!coupon ? 'Gerast áskrifandi' : 'Nota gjafabréf'} tabIndex="5" disabled={!activeCC ? true : false} className="w-full p-button-lg c-fullw" loading={subUserload} onClick={subscribeUser} />
                      </div>
                    </div>
                  </div></>}
                {!rapydLoading && uData.activeSub &&
                <div className="c-user-sub-info">
                  <div className="c-user-info">
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Staða</div>
                      <div className="c-user-info-val-val">{subStatus}</div>
                    </div>
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Greiðsla</div>
                      <div className="c-user-info-val-val">{paymentStatus}</div>
                    </div>
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Hóf áskrift</div>
                      <div className="c-user-info-val-val">{subCreated}</div>
                    </div>
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Næsta greiðsla</div>
                      <div className="c-user-info-val-val">{!canceled ? subNextpayment : <span className="c-info-danger">Áskrift sagt upp. Virk til {subNextpayment}</span>}</div>
                    </div>
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Núverandi tímabil</div>
                      <div className="c-user-info-val-val">{subPeriod}</div>
                    </div>
                    <div className="c-user-info-val">
                      <div className="c-user-info-val-disp">Greiðsluleið</div>
                      <div className="c-user-info-val-val">***{last4}</div>
                    </div>
                  </div>
                  <div className="c-user-sub-cncl">
                    {subStatus == 'Ógreidd' ? <Retrypayment uData={uData} refreshData={refreshData} type='buttons' newcard={subCard!==activeCC ? activeCC : null}></Retrypayment> : !canceled ? <Button label="Segja upp áskrift" className="p-button-danger p-button-text c-fullw sd-exclude" loading={unsubLoad} onClick={delConfirm}/> : <Button label="Hætta við" className="p-button-success p-button-text sd-exclude" loading={unsubLoad} onClick={undoCancel}/>}
                  </div>
                </div>}
              </AccordionTab>
            </Accordion>
            <Dialog className="c-master-err-dialog" header="Skilaboð" visible={displayHelp} style={{width: '95vw'}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
              {message}
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};
Askriftir.propTypes = {
  uData: PropTypes.any,
  refreshData: PropTypes.any,
};
