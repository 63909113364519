import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import {updateListendata} from '../../podcastApi.js';
import './Audioplayer.scss';
const Audioplayer =({children, authenticated, setcurrentEpisode, audioInstance, setAudioInstance, setAudioList, audioList, setaudioStatus, setcurrentTime, currentTime, currentEpisode}) =>{
  /* if (!authenticated ) {
    return (
      <>
        {children}
      </>
    );
  }*/
  const toast = useRef(null);
  const setTimeback = () => {
    try {
      audioInstance.currentTime = Math.max(audioInstance.currentTime - 15, 0);
    } catch (e) {
      console.log('Aðgerð tókst ekki');
    };
  };
  const setTimeforward = () => {
    try {
      audioInstance.currentTime = Math.min(audioInstance.currentTime + 15, audioInstance.duration);
    } catch (e) {
      console.log('Aðgerð tókst ekki');
    };
  };
  const params = {
    // audio lists model
    audioLists: audioList,
    // default play index of the audio player  [type `number` default `0`]
    defaultPlayIndex: 0,
    // if you want dynamic change current play audio you can change it [type `number` default `0`]
    playIndex: 0,
    // color of the music player theme    [ type `string: 'light' or 'dark'  ` default 'dark' ]
    theme: 'dark',
    // Specifies movement boundaries. Accepted values:
    // - `parent` restricts movement within the node's offsetParent
    //    (nearest node with position relative or absolute), or
    // - a selector, restricts movement within the targeted node
    // - An object with `left, top, right, and bottom` properties.
    //   These indicate how far in each direction the draggable
    //   can be moved.
    bounds: 'body',
    quietUpdate: false,
    // Replace a new playlist with the first loaded playlist
    // instead of adding it at the end of it.
    // [type `boolean`, default `false`]
    clearPriorAudioLists: true,
    // Play your new play list right after your new play list is loaded turn false.
    // [type `boolean`, default `false`]
    autoPlayInitLoadPlayList: false,
    // Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
    // 'auto|metadata|none' 'true| false'
    preload: false,
    // Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
    glassBg: true,
    // The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
    remember: false,
    // The Audio Can be deleted  [type `Boolean`, default `true`]
    remove: true,
    // audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
    defaultPosition: {
      right: 50,
      bottom: 100,
    },
    // play mode text config of the audio player
    playModeText: {
      order: 'Röð',
      orderLoop: 'Endurtaka allt',
      singleLoop: 'Endurtaka',
      shufflePlay: 'Slembispilun',
    },
    // audio controller open text  [ type `String | ReactNode` default 'open']
    openText: 'Opna',
    // audio controller close text  [ type `String | ReactNode` default 'close']
    closeText: 'Loka',
    // audio theme switch checkedText  [ type `String | ReactNode` default '-']
    checkedText: '-',
    // audio theme switch unCheckedText [ type `String | ReactNode` default '-']
    unCheckedText: '-',
    // audio list panel show text of the playlist has no songs [ type `String` | ReactNode  default 'no music']
    notContentText: 'Engir þættir',
    responsive: true,
    // audio mode        mini | full          [type `String`  default `mini`]
    mode: isMobile ? 'mini' : 'full',
    /**
     * [ type `Boolean` default 'false' ]
     * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
     */
    once: false,
    // Whether the audio is played after loading is completed. [type `Boolean` default 'true']
    autoPlay: false,
    // Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
    toggleMode: true,
    // audio cover is show of the 'mini' mode [type `Boolean` default 'true']
    showMiniModeCover: true,
    // audio playing progress is show of the 'mini'  mode
    showMiniProcessBar: true,
    // audio controller is can be drag of the 'mini' mode     [type `Boolean` default `true`]
    drag: true,
    // drag the audio progress bar [type `Boolean` default `true`]
    seeked: true,
    // audio controller title [type `String | ReactNode`  default <FaHeadphones/>]
    // controllerTitle: <FaHeadphones />,
    // Displays the audio load progress bar.  [type `Boolean` default `true`]
    showProgressLoadBar: true,
    // play button display of the audio player panel   [type `Boolean` default `true`]
    showPlay: true,
    // reload button display of the audio player panel   [type `Boolean` default `true`]
    showReload: true,
    // download button display of the audio player panel   [type `Boolean` default `true`]
    showDownload: true,
    // loop button display of the audio player panel   [type `Boolean` default `true`]
    showPlayMode: false,
    // theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
    showThemeSwitch: true,
    // lyric display of the audio player panel   [type `Boolean` default `false`]
    showLyric: false,
    // Extensible custom content       [type 'Array' default '[]' ]
    extendsContent: [<><div className="c-sd-skip-btns"><button className="c-skip-btn" onClick={setTimeback}><i className="pi pi-replay c-sd-backwards" style={{'fontSize': '3em'}}></i>
    </button><button className="c-skip-btn" onClick={setTimeforward}><i className="pi pi-refresh c-sd-forwards" style={{'fontSize': '3em'}}></i></button></div></>],
    // destroy player button display  [type `Boolean` default `false`]
    showDestroy: false,
    // default volume of the audio player [type `Number` default `100` range `0-100`]
    defaultVolume: 80,
    // playModeText show time [type `Number(ms)` default `700`]
    playModeShowTime: 600,
    // Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
    loadAudioErrorPlayNext: true,
    // Auto hide the cover photo if no cover photo is available [type `Boolean` default `false`]
    autoHiddenCover: true,
    showMediaSession: true,
    // audio play handle
    onAudioPlay(audioInfo) {
      // console.log('audio playing', audioInfo);
      setaudioStatus(true);
      audioInstance.currentTime = currentTime;
      const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
      episodeStatus[currentEpisode] = currentTime;
      localStorage.setItem('episodeStatus', JSON.stringify(episodeStatus));
      // changeMediasession(audioInfo);
      // setPlay(true);
    },
    // audio pause handle
    onAudioPause(audioInfo) {
      const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
      episodeStatus[currentEpisode] = audioInfo.currentTime;
      localStorage.setItem('episodeStatus', JSON.stringify(episodeStatus));
      setcurrentTime(audioInfo.currentTime);
      updateListendata(episodeStatus);
      setaudioStatus(false);
    },
    // When the user has moved/jumped to a new location in audio
    onAudioSeeked(audioInfo) {
      const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
      episodeStatus[currentEpisode] = audioInfo.currentTime;
      localStorage.setItem('episodeStatus', JSON.stringify(episodeStatus));
      updateListendata(episodeStatus);
      setcurrentTime(audioInfo.currentTime);
    },
    // The single song is ended handle
    onAudioEnded(audioInfo) {
      const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
      episodeStatus[currentEpisode] = audioInfo.currentTime;
      localStorage.setItem('episodeStatus', JSON.stringify(episodeStatus));
      // swal('Audio is ended!', '', 'success')
      // console.log'audio ended', audioInfo);
      setcurrentTime(0);
      setTimeout(() => {
        checkQueue();
      }, 1000);
    },
    // audio load abort The target event like {...,audioName:xx,audioSrc:xx,playMode:xx}
    onAudioAbort(e) {
      console.log('audio abort', e);
    },
    // audio play progress handle
    onAudioProgress(audioInfo) {
      if (authenticated) {
        const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
        episodeStatus[currentEpisode] = audioInfo.currentTime;
        localStorage.setItem('episodeStatus', JSON.stringify(episodeStatus));
      };
      // window.dispatchEvent(new Event('storage'));
    },
    onBeforeAudioDownload() {
      /* return Promise.resolve({
        src: '1.mp3',
      })*/
      toast.current.show({severity: 'success', summary: 'Sæki þátt', detail: 'Ekki loka glugganum. Niðurhal getur tekið allt að mínútu.'});
      console.log('Fyrir download');
    },
    // audio reload handle
    onAudioReload(audioInfo) {
      setcurrentTime(0);
    },
    // audio load failed error handle
    onAudioLoadError(e) {
      console.error('audio load err', e);
    },
    /* onAudioListsChange(currentPlayId, audioLists, audioInfo) {
      console.log('Breyti um lag:', audioInstance.currentTime);
      console.log('[currentPlayId] audio lists change:', currentPlayId);
      console.log('[audioLists] audio lists change:', audioLists);
      console.log('[audioInfo] audio lists change:', audioInfo);
    },*/
    onAudioPlayTrackChange(currentPlayId, audioLists, audioInfo) {
      /* console.log(
          currentPlayId,
          audioLists,
          audioInfo,
      ); */
      // Þetta er nauðynlegt þegar maður refreshar síðu eða kemur aftur
      // Þá frumstillir þetta staðsetningu í þættinum
      audioInstance.currentTime = audioLists[0].currentTime;
    },
    // custom music player root node
    getContainer() {
      return document.body;
    },

    /**
     * @description get origin audio element instance , you can use it do everything
     * @param {audio} audio
     * @example
     * audio.playbackRate = 1.5  // set play back rate
     * audio.crossOrigin = 'xxx' // config cross origin
     */
    getAudioInstance(audio) {
      // console.log'audio instance', audio);
    },

    // transform audio info like return a Promise

    /**
     * @return
     *  {
     *    src: 'xxx',
     *    filename: 'xxx',
     *    mimeType: 'xxx'
     *  }
     */
    // onBeforeAudioDownload() {
    //   return Promise.resolve({
    //     src: '1.mp3'
    //   })
    // }
  };
  const [mount, setMount] = useState(false);
  const [options, setOptions] = useState(
      {...params,
        autoPlay: false,
        autoPlayInitLoadPlayList: false,
      });
  const checkQueue = () => {
    const queue = JSON.parse(sessionStorage.getItem('queuedEpisodes')) || [];
    if (queue.length != 0) {
      queue[0].autoPlay = true;
      audioList = [queue[0]];
      setcurrentEpisode(queue[0].episodeId);
      localStorage.setItem('lastPlayId', queue[0].episodeId);
      setAudioList(audioList);
      setcurrentTime(queue[0].currentTime || 0);
      queue.pop();
      sessionStorage.setItem('queuedEpisodes', JSON.stringify(queue));
    };
  };


  useEffect( () => {
    let mounted = true;
    if (mounted) {
      if ( audioList.length > 0 ) {
        setOptions({
          ...params,
          audioLists: audioList,
          autoPlay: audioList[0].autoPlay ? true : false,
          autoPlayInitLoadPlayList: audioList[0].autoPlay ? true : false,
          getAudioInstance: (audio) => {
            setAudioInstance(audio);
          },
        });
        setMount(true);
      };
    }
    return () => {
      setOptions({});
      mounted = false;
    };
  }, [audioList, currentTime, audioInstance]);
  return (
    <>
      {children}
      <Toast ref={toast}></Toast>
      {mount ? <ReactJkMusicPlayer
        {...options}
        getAudioInstance={setAudioInstance}
      ></ReactJkMusicPlayer> : null }
    </>
  );
};
Audioplayer.propTypes = {
  children: PropTypes.node.isRequired,
  uData: PropTypes.any,
  options: PropTypes.any,
  authenticated: PropTypes.any,
  audioInstance: PropTypes.any,
  setAudioInstance: PropTypes.any,
  audioList: PropTypes.any,
  unmount: PropTypes.any,
  setaudioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  setAudioList: PropTypes.any,
};
export default Audioplayer;
