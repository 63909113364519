import {ProgressSpinner} from 'primereact/progressspinner';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import './Loading.scss';

const Loading = ({isBlocked, propmessage = '[ ] Sæki gögn'}) => {
  const [show, setShow] = useState();
  const [message, setMEssage] = useState(propmessage);
  const [textlength, setTextlength] = useState(13);
  useEffect( () => {
    // Sæki áskriftarleiðir
    if (isBlocked) {
      setShow(true);
    } else {
      setMEssage(message.replace('[ ]', '[X]'));
      setTimeout(function() {
        setShow(false);
      }, 500);
    };
    return () => {
      // setShow(false);
    };
  }, [isBlocked]);
  useEffect( () => {
    // Sæki áskriftarleiðir
    setMEssage(propmessage);
    if (!!propmessage) {
      setTextlength(message.length + 2);
    };
    return () => {
      // setShow(false);
      setMEssage('[ ] Sæki gögn');
    };
  }, [propmessage]);

  // console.log(message);
  return (
    <>
      {show && <div className="c-sd-loading"><div><ProgressSpinner className="loading-spinner"/><div className="loading" style={{width: textlength+'ch'}} >{message}</div></div></div>}
    </>
  );
};
export default Loading;
Loading.propTypes = {
  isBlocked: PropTypes.any,
  propmessage: PropTypes.any,
};
