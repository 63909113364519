import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import 'react-jinke-music-player/assets/index.css';
import PlaylistItemV2 from '../../components/playlistItemV2/PlaylistItemV2.js';
import {getPodcasts, updateUser} from '../../podcastApi.js';
import './Playlist.scss';
/**
 * @param  {string} string
 */
function clDebug(string) {
  if (window.debug) {
    console.log(string);
  };
};
/**
 * @param  {object} episodes
 * @param  {string} type
 *
 * @return {object} in order
 */
function sort(episodes, type) {
  let returnArr = episodes;
  if (type=='D') {
    returnArr = episodes.sort(
        (objA, objB) => Number(objB.Created.seconds) - Number(objA.Created.seconds),
    );
  };
  if (type=='A') {
    returnArr = episodes.sort(
        (objA, objB) => Number(objA.Created.seconds) - Number(objB.Created.seconds),
    );
  };
  if (type=='R') {
    returnArr = episodes;
    returnArr.sort((a, b) => (a.epNr < b.epNr) ? 1 : ((b.epNr < a.epNr) ? -1 : 0));
  }
  if (type=='P') {
    returnArr = episodes;
    returnArr.sort((a, b) => (a.epNr > b.epNr) ? 1 : ((b.epNr > a.epNr) ? -1 : 0));
  }
  return returnArr;
};
/* /**
 * @param  {object} episodes
 * @param  {string} filtProp
 *
 * @return {object} filtered by filtProp
function filterCategories(episodes, filtProp) {
  let tmpShows = episodes;
  const filteredData = tmpShows.filter((o) => !!o.tags && o.tags.includes(filtProp));
  tmpShows = filteredData;
  return tmpShows;
};
*/
/**
* @return {header}
*/
export default function Playlist({uData, setAudioList, audioInstance, currentEpisode, setcurrentEpisode, audioStatus, setcurrentTime, title = 'Föstudagsþættir', authenticated, setEpisodes}) {
  // const [dispepisodes, updatedispepisodes] = useState(JSON.parse(localStorage.getItem('wcEpisodes')) || [{Name: 'A riðill'}, {Name: 'B riðill'}, {Name: 'C riðill'}, {Name: 'D riðill'}]);
  const [fridayEpisodes, setfridayEpisodes] = useState(JSON.parse(localStorage.getItem('fridayEpisodes')) || [{Name: '#1'}]);
  const [allEpisodes, setAllepisodes] = useState();
  const [episodeCount, setEpisodecount] = useState(5);
  const [filter, setFilter] = useState([]);
  const [userSubscribed, setuserSubscribed] = useState(false);
  // const [loading, setLoading] = useState(true);

  // Gets all the users data
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!uData) {
      // setLoading(true);
      setuserSubscribed(uData?.isSub);
      if (uData.googleData?.isSub !== uData?.isSub) {
        // Rapyd ræður hvort hann sé sub
        const data = {
          isSub: uData.isSub,
        };
        updateUser(data);
        uData.googleData = true;
      };
      // setLoading(false);
      clDebug('Stillti gögn');
    };
  }, [uData]);
  // Episode listener
  useEffect( () => {
    const getData = async () => {
      const data = await getPodcasts();
      let fridayEps = sort(data, 'D');
      fridayEps = fridayEps.map((item, index) => {
        return {...item, epNr: fridayEps.length - index};
      });
      setAllepisodes(fridayEps);
      setfridayEpisodes(fridayEps.slice(0, episodeCount));
      localStorage.setItem('fridayEpisodes', JSON.stringify(fridayEps || {}));
      // setLoading(false);
    };
    getData();
    clDebug('Sótti þættina');
  }, [uData]);
  useEffect( () => {
    if (allEpisodes && setEpisodes) {
      const tmpEps = allEpisodes;
      setEpisodes(sort(tmpEps, 'A'));
    };
  }, [allEpisodes, setEpisodes]);

  const filterEpisodes = (newFilter) => {
    console.log('virkar ekki hér');
  };
  const changeSong = (episode, time, episodeId) => {
    setcurrentEpisode(episodeId);
    localStorage.setItem('lastPlayId', episodeId);
    const newAudioList = episode;
    setcurrentTime(time);
    setAudioList(newAudioList);
  };
  const loadMore = () => {
    let allEpisodesTmp = allEpisodes;
    allEpisodesTmp = sort(allEpisodesTmp, 'D');
    setfridayEpisodes(allEpisodesTmp.slice(0, episodeCount + 5));
    setEpisodecount(episodeCount + 5);
  };
  const fridayMap = fridayEpisodes.map((el, index) => {
    // Default to rendering PlaylistItemV2 if 'type' is not defined or not 'video'
    return (
      <PlaylistItemV2
        key={el.episodeId}
        authenticated={authenticated}
        index={el.epNr}
        audioStatus={audioStatus}
        currentEpisode={currentEpisode}
        setFilter={setFilter}
        isSub={userSubscribed}
        audioInstance={audioInstance}
        changeSong={changeSong}
        filterEpisodes={filterEpisodes}
        filter={filter}
        {...el}
      />
    );
  });
  return (
    <div className="c-hlusta-category">
      <section>
        <div className="c-hlusta-category c-hm-season">
          <div className="c-sd-header">
            <h2 className="c-sd-text">{title}</h2>
          </div>
          <div className="episodes-container">
            <ul>
              {fridayMap}
            </ul>
          </div>
        </div>
      </section>
      <div className="c-episodes-loadmore">
        <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" name="deleteButton" label="Sækja fleiri" rounded text icon="pi pi-plus" onClick={loadMore}/>
      </div>
    </div>
  );
}
Playlist.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  title: PropTypes.any,
  authenticated: PropTypes.any,
  setEpisodes: PropTypes.any,
};
