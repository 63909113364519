/* eslint max-len: [2, 180, 4]*/ // maximum length of 80 characters
/* eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
// import {firebaseLogin as login} from '../../api.js';
import Loading from '../../components/loading/Loading';
import '../../components/login/login.scss';
import Skilmalar from '../../components/skilmalar/Skilmalar.js';
import {addData, googleLoginFirebase, googleLogoutFirebase, resetFirebasePw, firebaseLogin as login} from '../../podcastApi.js';
import Auth from '../../utils/Auth';
import './Login.scss';
/**
 * @param  {string} email
 * @return {boolean} boolean
 */
const validateEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
};
/**
 * @param  {token} setGoogleLoginData
 * @return {reactfragment}
 */
export default function LoginUser({refreshData}) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginErr, setError] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [skilmalarDisp, setskilmalarDisp] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const toast = useRef(null);
  const onHide = () => {
    setDisplayBasic(false);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Hætta við" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
        <Button label="Endurstilla" icon="pi pi-check" onClick={() => resetPassword(username)} autoFocus />
      </div>
    );
  };

  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const from = state ? (state.nextPath || state.from.pathname) : '/forsida';

  const nySkra = () => {
    navigate('/nyskra', {state: {nextPath: '/askriftir', from: location}, replace: false});
  };
  const handleLogout = async () => {
    Auth.deauthenticateUser();
    await googleLogoutFirebase();
    navigate('/', {replace: true});
  };
  const checkAgreement = (userAgrees) => {
    const createUser = async () => {
      setBlockedDocument(true);
      if (!userAgrees) {
        handleLogout();
        setBlockedDocument(false);
        navigate(from, {replace: true});
      } else {
        await refreshData();
        setBlockedDocument(false);
        navigate('/forsida', {replace: true});
      }
      // setBlockedDocument(false);
      // navigate(from, {replace: true});
    };
    createUser();
  };

  const steveCustomLogin = async (e) => {
    setBlockedDocument(true);
    setLoading1(true);
    e.preventDefault();

    const token = await login(
        username,
        password,
    );
    if (token.success) {
      await refreshData();
      navigate(from, {replace: true});
      setLoading1(false);
    } else {
      setError(true);
      setLoading1(false);
    };
    setBlockedDocument(false);
  };
  /**
   * @param  {loginInfo} googleData
   */
  const googleFbLogin = async () => {
    setBlockedDocument(true);
    const res = await googleLoginFirebase();
    if (res.success) {
      if (res.dbStatus == 'USER_DOES_NOT_EXIST') {
        const addeddata = await addData(res.userInfo.email, res.userInfo.name);
        if (addeddata) {
          setskilmalarDisp(true);
        };
      } else {
        await refreshData();
        navigate(from, {replace: true, state: res.userInfo});
      }
    }
    setBlockedDocument(false);
  };
  const resetPassword = async (email) => {
    if (validateEmail(email)) {
      const res = await resetFirebasePw(email);
      if ( res.success ) {
        toast.current.show({severity: 'success', summary: 'Póstur sendur á '+ username, detail: 'Ef þú finnur ekki póstinn, leitaðu í ruslinu', life: 3000});
        setDisplayBasic(false);
      } else {
        toast.current.show({severity: 'error', summary: 'Villa kom upp', detail: 'Notandi ekki til eða vitlaust slegið inn', life: 3000});
      };
    } else {
      toast.current.show({severity: 'warn', summary: 'Netfang ekki gilt', detail: 'Athugaðu hvort það vanti @ eða . ', life: 3000});
    };
  };
  // <img src={logo} alt="hyper" height="500" className="mb-3" />
  return (
    <div className="sd-dark-theme surface-card p-4 shadow-2 border-round w-full lg:w-4 sd-font c-login-frm">
      {<Loading isBlocked={blockedDocument} propmessage='Skrái þig inn'></Loading>}
      <div className="text-center mb-5">
        <div className="text-900 text-3xl font-medium mb-3">Velkomin aftur</div>
        <Button className="p-button-outlined p-button-warning w-full sd-exclude sd-google-login" onClick={googleFbLogin}>
          <i className="pi pi-google px-2"></i><span className="px-3">Skrá mig inn með Google</span></Button>
        <div className="sd-divider">
          <Divider align="center">
            <b>Eða</b>
          </Divider>
        </div>
      </div>
      <form>
        <label htmlFor="email1" className="block text-900 font-medium mb-2">Póstfang</label>
        <Skilmalar openSkilmalar={skilmalarDisp} accept={checkAgreement} setskilmalarDisp={setskilmalarDisp}/>
        <span className="p-input-icon-right w-full">
          <i className="pi pi-envelope" />
          <InputText id="email1" type="text" autoComplete="email"
            className={`w-full ${loginErr ? 'p-invalid' : ''}`} onChange={(e) => setUserName(e.target.value)} />
        </span>
        <label htmlFor="password1" className="block text-900 font-medium mb-2">Lykilorð</label>
        <div className="formgroup-inline mb-2">
          <span className="p-input-icon-right w-full">
            <i className="pi pi-lock" />
            <InputText id="password1" type="password" autoComplete="current-password"
              className={`w-full ${loginErr ? 'p-invalid' : ''}`} onChange={(e) => setPassword(e.target.value)} />
          </span>
          {loginErr ? <small id="username2-help" className="p-error block">Villa í innskráningu.</small> : ''}
        </div>
        <div className="flex align-items-center justify-content-between mb-6">
          <a className="font-medium no-underline ml-2 sd-text-orange text-right cursor-pointer" onClick={setDisplayBasic}>Gleymt lykilorð?</a>
          <Dialog header="Endurstilla lykilorð" className="c-confirm-rpwd" visible={displayBasic} style={{width: '50rem'}}
            footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
            <span>Endurstilla lykilorð fyrir netfang</span>
            <InputText type="text" value={username} onChange={(e) => setUserName(e.target.value)} className="p-inputtext-lg block" placeholder="daemi@gmail.com" />
          </Dialog>
          <Toast ref={toast} />
        </div>
        <Button label="Skrá mig inn" loading={loading1}
          className="w-full p-button-lg p-button-raised p-button--purple sd-font c-login-btn" onClick={steveCustomLogin} />
        <div className="c-sd-nyskra">
          <span className="text-600 font-medium line-height-3">Áttu ekki aðgang?</span>
          <Button label="Nýskráning" className="p-button-link font-medium no-underline ml-2 sd-text-orange cursor-pointer c-nyskr-lnk sd-exclude" onClick={nySkra}/>
        </div>
      </form>
    </div>
  );
}

LoginUser.propTypes = {
  refreshData: PropTypes.any,
};
