/* eslint no-unused-vars: ['error', { 'varsIgnorePattern': '[aA]pp' }]*/
/* eslint-disable no-console */
/* eslint-disable no-unused-vars*/
import {initializeApp} from 'firebase/app';
import {getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut} from 'firebase/auth';
import {addDoc, collection, doc, getDoc, getDocs, getFirestore, query, serverTimestamp, setDoc, Timestamp, updateDoc, where} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {createOrGetRapydUser} from './rapydApi.js';
import {getMessaging, getToken, onMessage, deleteToken, isSupported} from 'firebase/messaging';
// , onMessage
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth();
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
/**
 * @return {app} app
 */
export function getApp() {
  return app;
}
/**
 * @return {app} app
 */
export const storage = getStorage();

/**
 * @param {email} email
 * @return {function} function
 */
export async function resetFirebasePw(email) {
  const auth = getAuth();
  let res;
  try {
    res = await sendPasswordResetEmail(auth, email);
    return {
      success: true,
      data: res,
    };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return {
      success: false,
      errorMessage: errorMessage,
      errorCode: errorCode,
    };
  };
};
/**
 * @return {function} function
 */
export async function googleLogoutFirebase() {
  const auth = getAuth();
  let res;
  try {
    res = await signOut(auth);
    return res;
  } catch (error) {
    return error;
  }
};
/**
 * @return {function} function
 */
export async function googleLoginFirebase() {
  const auth = getAuth();
  let result;
  let dbStatus = 'USER_INFO_OK';
  try {
    result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const userExists = await getUser(result.user.email);

    if (result._tokenResponse.isNewUser || !userExists) {
      dbStatus = 'USER_DOES_NOT_EXIST';
    };
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    return {
      success: true,
      userInfo: {name: user.displayName, picture: user.photoURL, email: result.user.email},
      token: token,
      authType: 'GOOGLE',
      dbStatus: dbStatus,
      email: result.user.email,
    };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    // const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    return {
      success: false,
      errorMessage: errorMessage,
      errorCode: errorCode,
      credential: credential,
    };
  }
};
/**
 * Function that logs in user into firebase
 * @param  {string} email
 * @param  {string} password
 */
export async function firebaseLogin(email, password) {
  const authentication = getAuth();
  let response;
  try {
    response = await signInWithEmailAndPassword(authentication, email, password);
  } catch (error) {
    if (error.code === 'auth/wrong-password') {
      console.log('Please check the Password');
    };
    if (error.code === 'auth/user-not-found') {
      console.log('Please check the Email');
    };
    console.log(error);
    return (error.code);
  }
  const userName = response.user.email;
  return {
    success: true,
    userInfo: {name: userName, picture: null},
    token: response._tokenResponse.idToken,
    authType: 'STEVE',
    dbStatus: 'USER_INFO_OK',
  };
};

/**
 * Function that registers user to fireBase
 * @param  {object} data
 */
export async function firebaseRegister(data) {
  const authentication = getAuth();
  let response;
  try {
    response = await createUserWithEmailAndPassword(authentication, data.email, data.password);
  } catch (error) {
    return (error);
  };
  const addedData = await addData(data.email, data.name);
  console.log('add data', addedData);
  // user was created. Insert additional info:

  return {
    success: true,
    userInfo: {name: data.name, email: data.email, picture: null},
    token: response._tokenResponse.idToken,
    authType: 'STEVE',
    dbStatus: 'USER_INFO_OK',
  };
}

// Create a root reference
// const storage = getStorage(app);
/**
 * @return {auth} auth
 */
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
/**
 * @param {token} token
 * @return {response} auth
 */
export const deleteToken2 = async (token) => {
  const delTok = async (token) => {
    try {
      // const response = await deleteToken(messaging);
      await setDoc(doc(db, 'messagingTokens', auth.currentUser.email), {subscribedToNotifications: false}, {merge: true});
      return response;
    } catch (err) {
      return err;
      // catch error while creating client token
    };
  };
  if (token) {
    return delTok(token);
  } else {
    return -1;
  };
};

export const hasSupport = async () => {
  const isSup = await isSupported();
  return isSup;
};
/**
 * @param {token} token
 * @return {auth} auth
 */
export async function getSubdata() {
  const q = query(collection(db, 'subscriptionsdata'));
  const querySnapshot = await getDocs(q);
  const episodes = [];
  querySnapshot.forEach((doc) => {
    const tmp = doc.data();
    tmp.dataId = doc.id;
    episodes.push(tmp);
  });
  return episodes;
};
/**
 * @param {string} message
 */
export async function logError(message) {
  try {
    const ref = doc(db, 'userErrors', auth.currentUser.email);
    const docs = await getDoc(ref);
    if (docs.exists) {
      const array1 = docs.data()?.errorMessages || [];
      const array2 = [message];
      const mergedArray = Array.from(new Set([...array1, ...array2]));
      // Update the merged array back to Firebase
      const documentRef = doc(db, 'userErrors', auth.currentUser.email);
      await setDoc(documentRef, {errorMessages: mergedArray, lastErrortimestamp: serverTimestamp()}, {merge: true});
    } else {
      console.info('Document does not exist');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
/**
 * @param {token} token
 * @return {auth} auth
 */
async function mergeArraysInFirebase(token) {
  try {
    const ref = doc(db, 'messagingTokens', auth.currentUser.email);
    const docs = await getDoc(ref);

    if (docs.exists) {
      const array1 = docs.data()?.tokens || [];
      const array2 = [token];
      // Example mergeArrays function
      const mergedArray = Array.from(new Set([...array1, ...array2]));

      // Update the merged array back to Firebase
      // await documentRef.update({ mergedArray });
      await setDoc(doc(db, 'messagingTokens', auth.currentUser.email), {tokens: mergedArray, subscribedToNotifications: true}, {merge: true});
    } else {
      console.info('Document does not exist');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

/**
 * @param {token} token
 * @return {auth} auth
 */
export async function getUsertokens() {
  try {
    const ref = doc(db, 'messagingTokens', auth.currentUser.email);
    const docs = await getDoc(ref);

    if (docs.exists) {
      const data = docs.data();
      return data;
    } else {
      console.info('Document does not exist');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
/**
 * @param {setTokenFound} setTokenFound
 * @param {save} save
 * @param {retry} retry
 * @return {auth} auth
 */
export const getToken2 = async (setTokenFound, save) => {
  const getTok = async (setTokenFound, retry) => {
    try {
      const tempMessaging = await getMessaging(app);
      if (tempMessaging) {
        const newSw = await navigator.serviceWorker.register('../firebase-messaging-sw.js');
        const currentToken = await getToken(tempMessaging, {vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY, serviceWorkerRegistration: newSw});
        if (currentToken) {
          console.info('current token for client: ', currentToken);
          const deviceToken = {
            token: currentToken,
            timestamp: serverTimestamp(),
          };
          if (save) {
            mergeArraysInFirebase(currentToken);
          }
          setTokenFound(1);
          return 1;
        } else {
          setTokenFound(0);
          return 0;
        }
      };
    } catch (err) {
      if (!retry) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log('retry');
        return getTok(setTokenFound, true);
      } else {
        setTokenFound(-1);
        return -1;
      };
    };
  };
  /* if ('serviceWorker' in navigator) {
    try {
      const swReg2 = await navigator.serviceWorker.register('../firebase-messaging-sw.js');
      console.log('Firebase Worker is registered', swReg2);
      const ret = await getTok(setTokenFound);
      return ret;
    } catch (error) {
      console.error('Service Worker registration failed:', error);
      setTokenFound(-1);
      return -1;
    }
  }*/
  return getTok(setTokenFound, true);
};
/**
 * @return {auth} auth
 */
/* export async function isLoggedin() {
  return auth.currentUser;
};
*/
/**
 * @return {function} function
 */
export async function getGoogleAuth() {
  return auth;
}
/**
 * @param  {data} data
 * @return {aasd} q
 */
export async function updateListendata(data) {
  if (auth?.currentUser?.email) {
    const field = 'episodeStatus';
    const userRef = doc(db, 'userInfo', auth.currentUser.email);
    const q = await updateDoc(userRef, {
      [field]: data,
    });
    return q;
  };
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} data
 * @param  {string} email
 */
export async function updateUser(data, email) {
  const userMail = email || auth.currentUser.email;
  const ref = doc(db, 'userInfo', userMail);
  await updateDoc(ref, data);
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} email
 * @param  {string} name
 * @return {json} q
 */
export async function addData(email, name) {
  const rapydUser = await createOrGetRapydUser(email, name);
  let rapydUid = null;
  if (rapydUser.status.status=='SUCCESS') {
    rapydUid = rapydUser.data.id || rapydUser.data[0].id;
  };
  const data = {
    uid: auth.currentUser.uid,
    episodeStatus: {},
    isAdmin: false,
    isSub: false,
    name: name,
    created: serverTimestamp(),
    rapyd: {
      id: rapydUid,
    },
  };
  await setDoc(doc(db, 'userInfo', auth.currentUser.email), data);
  return data;
};
/**
 * @param  {string} date
 * @param  {string} description
 * @param  {string} audioUrl
 * @param  {string} audioSize
 * @param  {string} img
 * @param  {string} name
 * @param  {duration} duration
 * @param  {published} published
 * @param  {episodeCount} episodeCount
 * @param  {type} type
 * @param  {videofile} videofile
 */
export async function addEpisodev2(date, description, audioUrl, audioSize, img, name, duration, published, episodeCount, type, videofile) {
  const data = {
    Created: Timestamp.fromDate(date),
    Description: description,
    FileName: audioUrl,
    FileSize: audioSize,
    ImageUrl: img,
    Name: name,
    duration: duration,
    published: published || false,
    tags: ['steve', 'fös'],
    epNr: episodeCount,
    type: type,
    videoName: videofile || '',
  };
  const docRef = await addDoc(collection(db, 'episodes'), data);
  console.log('Document written with ID: ', docRef.id);
  // return data;
};
/**
 * Function that gets userInfo from userInfo collection
 * @return {json} q
 */
export async function getEvent() {
  const ref = doc(db, 'vidburdir', 'pubquiz');
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    // Convert to City object
    const user = docSnap.data();
    // Use a City instance method
    return user;
  } else {
    // user does not exist in db, has not accepted terms
    console.info('Error, could not get user info');
    return null;
  }
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} email
 * @param  {string} name
 * @return {json} q
 */
export async function getUser(email) {
  const userMail = email || auth.currentUser.email;
  const ref = doc(db, 'userInfo', userMail);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    // Convert to City object
    const user = docSnap.data();
    user.email = userMail;
    // Use a City instance method
    return user;
  } else {
    // user does not exist in db, has not accepted terms
    console.info('Error, could not get user info');
    return null;
  }
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} id
 */
export async function getPodcast(id) {
  const snap = await getDoc(doc(db, 'episodes', id));
  if (snap.exists()) {
    return {
      success: true,
      data: snap.data(),
    };
  } else {
    return {
      success: false,
    };
  };
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} cusId
 */
export async function getWebhooks(cusId) {
  const q = query(collection(db, 'webhooks'), where('data.customer_token', '==', cusId));
  const querySnapshot = await getDocs(q);
  const podcast = [];
  querySnapshot.forEach((doc) => {
    const ep = {
      ...doc.data(),
    };
    podcast.push(ep);
  });
  return podcast;
};
/**
 * @param {asdf} fileName
 * @return {asdf} url
 */
export async function getUrl(fileName) {
  // Create a reference to the file we want to download
  const storage = getStorage();
  const starsRef = ref(storage, fileName);

  // Get the download URL
  const url = await getDownloadURL(starsRef);
  return url;
}
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} id
 */
export async function getPodcasts() {
  const q = query(collection(db, 'episodes'), where('published', '==', true));
  const querySnapshot = await getDocs(q);
  const episodes = [];
  querySnapshot.forEach((doc) => {
    const tmp = doc.data();
    tmp.episodeId = doc.id;
    episodes.push(tmp);
  });
  return episodes;
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} id
 */
export async function getUsers() {
  const q = query(collection(db, 'userInfo'));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    const tmp = doc.data();
    tmp.email = doc.id;
    users.push(tmp);
  });
  return users;
};
/**
 * Function that gets userInfo from userInfo collection
 * @param {string} couponCode
 * @param  {string} id
 */
export async function getCoupon(couponCode) {
  const q = query(collection(db, 'coupons'), where('code', '==', couponCode));
  const querySnapshot = await getDocs(q);
  const coupons = [];
  querySnapshot.forEach((doc) => {
    const tmp = doc.data();
    tmp.docId = doc.id;
    tmp.coupon_id = tmp.Description;
    coupons.push(tmp);
  });
  return coupons;
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} id
 */
export async function getCoupons() {
  const q = query(collection(db, 'coupons'));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    const tmp = doc.data();
    tmp.id = doc.id;
    users.push(tmp);
  });
  return users;
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} email
 * @param  {string} name
 * @return {json} q
 */
export async function addEpisode() {
  const data = {
    Name: '#4',
    Description: 'Lýsing 2',
    ImageUrl: 'https://firebasestorage.googleapis.com/v0/b/steve-prod.appspot.com/o/steveicon.png?alt=media&token=9968a4e4-428c-4f4c-9002-16a2d201d3f6',
    duration: 4367,
    fileName: 'ep1.WAV',
    published: true,
    Created: serverTimestamp(),
  };
  const docRef = await addDoc(collection(db, 'episodes'), data);
  console.log('Document written with ID: ', docRef.id);
};
/**
 * Function that gets userInfo from userInfo collection
 * @param  {string} code
 * @param  {string} couponId
 * @return {json} q
 */
export async function addCoupon(code, couponId) {
  const data = {
    code: code,
    Description: couponId,
    redeemed: false,
  };
  const docRef = await addDoc(collection(db, 'coupons'), data);
  console.log('Document written with ID: ', docRef.id);
};
/**
 * @param  {data} docId
 * @return {aasd} q
 */
export async function updateCoupon(docId) {
  const userRef = doc(db, 'coupons', docId);
  const q = await updateDoc(userRef, {
    redeemed: true,
  });
  return q;
};
