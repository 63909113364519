import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import 'react-jinke-music-player/assets/index.css';
import Playlist from '../../components/playlist/Playlist.js';
import {updateUser} from '../../podcastApi.js';
import Retrypayment from '../../components/retrypayment/Retrypayment.js';
import Allownotification from '../../components/notification/Allownotification.js';
/**
 * @param  {string} string
 */
function clDebug(string) {
  if (window.debug) {
    console.log(string);
  };
};
/**
* @return {header}
*/
export default function Hlusta({uData, setAudioList, audioInstance, currentEpisode, setcurrentEpisode, audioStatus, setcurrentTime, refreshData}) {
  // const [name, setName] = useState('');
  const toast = useRef(null);
  const [userSubscribed, setuserSubscribed] = useState(false);
  const [retryPayment, setretryPayment] = useState(false);
  const [popupallowed, setPopupallowed] = useState(false);
  const [loading, setLoading] = useState(true);
  /* const [isTokenFound, setTokenFound] = useState(false);
  useEffect( () => {
    if (isTokenFound != 1) {
      getToken2(setTokenFound);
    };
  }, [isTokenFound]);*/
  // Gets all the users data
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!uData) {
      setuserSubscribed(uData.isSub);
      // Handle unpaid subscriptions
      if ('Offerpayment' in uData ) {
        // Check if the data exists in local session storage
        const storedData = sessionStorage.getItem('payerrorDisplayed'); // replace 'yourKey' with your actual key
        if (!storedData) {
          // If it doesn't exist, store the data and set the state variable to true
          const newData = true; // replace 'yourData' with the actual data you want to store
          sessionStorage.setItem('payerrorDisplayed', newData);
          setretryPayment(true);
          setPopupallowed(false);
        } else {
          // If it exists, do nothing
          setretryPayment(false);
          setPopupallowed(true);
        };
        setMainbutton(<Button className="p-button-lg p-button-danger" onClick={() => {
          setretryPayment(true);
        }}>Ógreiddur reikningur</Button>);
        setLoading(false);
      } else {
        setPopupallowed(true);
        setLoading(false);
      };
      if (uData.googleData.isSub !== uData.isSub) {
        // Rapyd ræður hvort hann sé sub
        const data = {
          isSub: uData.isSub,
        };
        updateUser(data);
        uData.googleData = true;
      };
      clDebug('Stillti gögn');
    } else {
      console.log('uData missing');
    };
  }, [uData]);
  return (
    <div className="c-home podcast-container" style={{width: '100%', height: '100%'}}>
      <Toast ref={toast}></Toast>
      <Allownotification popupallowed={popupallowed}></Allownotification>
      <Retrypayment openRetrypayment={retryPayment} setretryPayment={setretryPayment} uData={uData} refreshData={refreshData} type='modal' redirect={true}></Retrypayment>
      {!loading && <Playlist
        title='Allir þættir'
        uData={uData}
        authenticated={true}
        isSub={userSubscribed}
        setAudioList={setAudioList}
        audioInstance={audioInstance}
        currentEpisode={currentEpisode}
        setcurrentEpisode={setcurrentEpisode}
        audioStatus={audioStatus}
        setcurrentTime={setcurrentTime}/>}
    </div>
  );
}
Hlusta.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  refreshData: PropTypes.any,
};
