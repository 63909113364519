/* eslint-disable valid-jsdoc */
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {getCoupon, updateCoupon} from '../../podcastApi.js';
import {getCoupon as rapydCoupon, updateSubscription} from '../../rapydApi.js';
import './Giftcard.scss';

/**
* @return {header}
*/
function Giftcard({sub, refreshData, setCoupon, setFirebcouponId}) {
  const [loading2, setLoading2] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(false);
  const [message, setMessage] = useState('');
  const [body, setBody] = useState();
  // const navigate = useNavigate();
  // card info
  const [couponVal, setcouponVal] = useState();
  /**
   * sæki hér plans hjá rapyd
   */
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!sub) {
      try {
        if (!!sub.discount && !!sub.discount.valid) {
          setBody(<p>Ert nú þegar með virkt gjafabréf</p>);
        } else {
          setBody(<><InputText value={couponVal} className="w-full" required={true} tabIndex="40" onChange={(e) => setcouponVal(e.target.value)} />
            {message}
            {!sub ? <Button label="Sækja" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={getCard} /> :
          <Button label="Virkja gjafabréf" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={putCoupon} />}
          </>);
        };
      } catch (e) {
        setBody(<><InputText value={couponVal} className="w-full" required={true} tabIndex="40" onChange={(e) => setcouponVal(e.target.value)} />
          {message}
          {!sub ? <Button label="Sækja" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={getCard} /> :
          <Button label="Virkja gjafabréf" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={putCoupon} />}
        </>);
      }
    } else {
      setBody(<><InputText value={couponVal} className="w-full" required={true} tabIndex="40" onChange={(e) => setcouponVal(e.target.value)} />
        {message}
        {!sub ? <Button label="Sækja" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={getCard} /> :
        <Button label="Virkja gjafabréf" tabIndex="5" disabled={!couponVal || disabledBtn ? true : false} className="w-full p-button-lg" loading={loading2} onClick={putCoupon} />}
      </>);
    };
  }, [sub, couponVal, message, loading2]);
  const getCard = () => {
    const getData = async () => {
      setLoading2(true);
      const coupons = await getCoupon(couponVal);
      if (!!coupons[0] && !coupons[0].redeemed) {
        const coupon = await rapydCoupon(coupons[0].coupon_id);
        if (coupon.status.status == 'SUCCESS') {
          if (coupon.data.valid) {
            setFirebcouponId(coupons[0].docId);
            setMessage(<p>Kóði tilbúinn <br></br>{coupon.data.percent_off}% afsláttur í {coupon.data.duration_in_months} {coupon.data.duration_in_months == 1 ? 'mánuð' : 'mánuði'} af áskrift.</p>);
            setdisabledBtn(true);
            setCoupon(coupon.data.id);
          }
        } else {
          setMessage(<p>Gjafabréf fannst ekki. Athugaðu stafsetningu.</p>);
        }
        setLoading2(false);
      } else {
        setMessage(<p>Gjafabréf fannst ekki. Athugaðu stafsetningu.</p>);
      };
      setLoading2(false);
    };
    getData();
  };
  const putCoupon = () => {
    const getData = async () => {
      setLoading2(true);
      const coupons = await getCoupon(couponVal);
      if (!!coupons[0] && !coupons[0].redeemed) {
        const data = {
          'coupon': coupons[0].coupon_id,
        };
        const coupon = await updateSubscription(sub.id, data);
        if (coupon.status.status == 'SUCCESS') {
          refreshData();
          updateCoupon(coupons[0].docId);
        } else {
          setMessage(<p>Ekki tókst að virkja gjafabréf. Skilaboð: {coupon.status.message}</p>);
        }
        setLoading2(false);
      } else {
        setMessage(<p>Gjafabréf fannst ekki. Athugaðu stafsetningu.</p>);
      };
    };
    getData();
  };
  return (
    <>
      <div className="c-giftcard-container">
        <div className="c-giftcard-form">
          {body}
        </div>
      </div>
    </>
  );
};
export default Giftcard;
Giftcard.propTypes = {
  sub: PropTypes.any,
  refreshData: PropTypes.any,
  setCoupon: PropTypes.any,
  setFirebcouponId: PropTypes.any,
};
