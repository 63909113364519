import {TabMenu} from 'primereact/tabmenu';
import React from 'react';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import './Tabmenu.scss';
/**
 * @param  {elements} {children}
 * @return {element} tabmenu
 */
const Tabmenu =() =>{
  const location = useLocation();
  let index = 0;
  if (location.pathname === '/admin') {
    index = 1;
  }
  if (location.pathname === '/admin/thaettir') {
    index = 2;
  }
  if (location.pathname === '/admin/gogn') {
    index = 0;
  }
  if (location.pathname === '/admin/gjafabref') {
    index = 3;
  };
  const activeIndex = index;
  const navigate = useNavigate();
  const items = [
    {
      label: 'Skýrslur',
      icon: 'pi pi-fw pi-chart-line',
      command: (e) => {
        navigate('/admin/gogn', {replace: false});
      },
    },
    {
      label: 'Notendur',
      icon: 'pi pi-fw pi-user',
      command: (e) => {
        navigate('/admin', {replace: false});
      },
    },
    {
      label: 'Bæta við þætti',
      icon: 'pi pi-fw pi-plus',
      command: (e) => {
        navigate('/admin/thaettir', {replace: false});
      },
    },
    {
      label: 'Gjafabréf',
      icon: 'pi pi-fw pi-send',
      command: (e) => {
        navigate('/admin/gjafabref', {replace: false});
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="c-tabmenu-container">
        <TabMenu model={items} activeIndex={activeIndex}/>
        <Outlet />
      </div>
    </React.Fragment>
  );
};
export default Tabmenu;
