import React, {useState, useEffect} from 'react';
import './CountdownTimer.scss';
const CountdownTimer = () => {
  // Set the target date and time (November 24, 00:01)
  const targetDate = new Date('March 29, 2024 00:00:01 GMT+00:00').getTime();

  // Initialize state for the remaining time
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  // Calculate the remaining time until the target date
  /**
 * @return {object} in order
 */
  function calculateRemainingTime() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        days,
        hours,
        minutes,
        seconds,
      };
    } else {
      // If the target date has passed, return all zeros
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  // Update the remaining time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  // Render the countdown timer
  return (
    <div>
      {remainingTime.days + remainingTime.hours + remainingTime.minutes + remainingTime.seconds > 0 ?
        <div className="c-countdown-timer">
          <p>
            Hefst eftir {remainingTime.hours} klukkutíma, {remainingTime.minutes} mínútur,{' '}
            {remainingTime.seconds} sekúndur
          </p>
        </div> : <></>
      }
    </div>
  );
};

export default CountdownTimer;
