import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import bfImage from '../../images/bfsteve.png';
import CountdownTimer from '../../components/countdown/CountdownTimer.js';
import {getCheckout} from '../../rapydApi.js';
import './Giftcard.scss';


const Giftcard = ({uData, authenticated}) => {
  const [email, setEmail] = useState('');
  const [cusId, setCusid] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };
  useEffect(() => {
    if (uData) {
      // setDemoimg(selectedCity.code);
      setCusid(uData?.rapydData?.id);
    };
  }, [uData]);
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Netfang er ekki gilt netfang');
      setLoading(false);
      return;
    }

    // Perform form submission logic here
    const submitData = async (email, cusId) => {
      const rapydData = await getCheckout(email, cusId);
      if (rapydData?.status?.status == 'SUCCESS') {
        window.location.href = rapydData?.data.redirect_url;
        // .current.show({severity: 'success', summary: 'Aðgerð tókst', detail: 'Búið að búa til gjafabréf', life: 3000});
      } else {
        setEmailError(rapydData?.response.status.message);
        // toast.current.show({severity: 'warning', summary: 'Aðgerð tókst ekki', detail: 'Villa hjá rapyd', life: 3000});
      };
      setLoading(false);
    };
    submitData(email, cusId);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };


  return (
    <div className="c-black-fs">
      <div className="c-bf-detail">
        <div className="c-bf-text--orange">
          BLACK FRIDAY <br></br>
          MÁNAÐARAÐGANGUR<br></br>
          AÐ<br></br>
          <div className="c-bf-underlined">DET HELE</div>
          499 KR.<br></br>
        </div>
        <div className="c-bf-prices">
          <div className="sub-price">
            <span className="text-red old-price">1290 KR</span><br></br>
            <span className="text-red">ÞÚ SPARAR 61.3%</span>
            <br></br>
            <CountdownTimer></CountdownTimer>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="giftcard-form">
          <div>
            <div className="sub-cons">
            </div>
            <label htmlFor="cb2" className="p-checkbox-label">Netfang</label>
            <span className="p-float-label">
              <InputText
                id="cb2"
                value={email}
                onChange={handleEmailChange}
                required
                placeholder="Netfang"
                className={`p-inputtext-sm c-steve-text ${emailError ? 'p-invalid' : ''}`}
              />
              {emailError && <small className="p-error">{emailError}</small>}
            </span>
          </div>
          <Button type="submit" label="Kaupa" className={emailError ? 'p-button sd-exclude p-button-orange p-top-margin' : 'p-button sd-exclude p-button-orange'} loading={loading}/>
        </form>
      </div>
      <div className="c-sd-image">
        <img src={bfImage}></img>
      </div>
    </div>
  );
};

export default Giftcard;
Giftcard.propTypes = {
  uData: PropTypes.any,
  authenticated: PropTypes.any,
};
