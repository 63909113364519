
import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from 'primereact/dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import './Skilmalar.scss';

const Skilmalar = (props) => {
  const [displayBasic2, setDisplayBasic2] = useState(false);

  const declinePolicy = async () => {
    props.accept(false);
    props.setskilmalarDisp(false);
  };
  const accept = () => {
    declinePolicy();
  };

  const reject = () => {

  };
  const confirm1 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Ertu viss? Þú munt ekki geta skráð þig inn á stevedagskra.is  ',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
    });
  };

  useEffect( async () => {
    setDisplayBasic2(props.openSkilmalar);
  }, [props.openSkilmalar]);

  const onHide = (name, action, e) => {
    if (action) {
      props.accept(true);
      // dialogFuncMap[`${name}`](false);
      props.setskilmalarDisp(false);
    } else {
      confirm1(e);
    };
  };

  const renderFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Hafna" icon="pi pi-times" onClick={(e) => onHide(name, false, e)} className="p-button-text sd-exclude p-button-normal" />
        <Button label="Í lagi" icon="pi pi-check" onClick={(e) => onHide(name, true, e)} autoFocus className="p-button-success"/>
      </div>
    );
  };
  const renderdispFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Í lagi" icon="pi pi-check" onClick={(e) => props.setskilmalarDisp(false)} autoFocus className="p-button-success"/>
      </div>
    );
  };
  return (
    <Dialog header="Skilmálar Steve Dagskrá" visible={displayBasic2} className="c-privacy-wndw" footer={!!props.accept ? renderFooter('displayBasic2') : renderdispFooter()} onHide={() => onHide('displayBasic2')}>
      <h1>Áskriftarskilmálar</h1>
      <p>Áskriftin veitir áskrifanda aðgang að vikulegum aukaþáttum Steve Dagskrá.<br/><br/> Með því að samþykkja þessa skilmála, ábyrgist áskrifandi að hafa
        heimild og lagalegan rétt til að undirgangast samning þennan samkvæmt lögum og samþykkja að vera bundinn af skilmálunum. </p>
      <br />
      <p>Hlaðvarpsþættirnir sem áskrifandi fær aðgang að eru eingöngu ætlaðir til heimilis og einkanota hér á landi, en ekki til opinberrar birtingar.
        <br/>Opinber birting telst t. d. sýning í hljómfluttningstækjum fjölbýlishúsa, verslunum, veitingahúsum, vinnustöðum, fólksflutningabifreiðum, skipum, flugvélum o. s. frv.,
        hvort sem sýnt er gegn gjaldi eður ei. Fjölföldun  eða að deila áskriftum hlaðvarpsþáttana eru með öllu óheimil.  </p>
      <br/>
      <p>Áskriftin er aðgengilegar á stevedagskra.is og eru áskriftir greiddar fyrir fram, einn mánuð í senn.
        Áskrifandi getur sagt upp áskrift hvenær sem er en hefur samt aðgengi að öllu efni þar til áskriftinni lýkur.  </p>
      <br/>
      <p>Ef svo ólíklega vill til að áskrifandi vilji segja upp áskrift, getur hann gert það í gegnum Mitt Svæði á síðunni.
        Uppsögn tekur gildi þegar að sá mánuður sem viðskiptavinur hefur þegar greitt fyrir er búinn.
        Öll ábyrgð á að slíkar tilkynningar berist til Steve Dagskrá á réttan hátt og á réttum tíma liggur hjá áskrifanda.  </p>
      <br/>
      <p>Áskriftargjald hvers mánaðar ber áskrifanda að greiða fyrir fram samkvæmt þeim verðskrárreglum sem eru í gildi
        á hverjum tíma hjá Steve Dagskrá. Áskrift tekur gildi á þeim degi sem pöntun er framkvæmd.
        Rísi upp ágreiningur um túlkun og skýringu á skilmálum þessum skal leitast við að ná sáttum með einkunnarorðum Steve Dagskrá, heiðarleika og sanngirni. </p>
      <br/>
      <p>Ef allt fer í skrúfuna er hægt að senda kvörtun og eftir atvikum rekið mál fyrir dómstólum.</p>
      <br />
      <br />
    </Dialog>
  );
};
export default Skilmalar;
Skilmalar.propTypes = {
  openSkilmalar: PropTypes.bool,
  accept: PropTypes.func,
  setskilmalarDisp: PropTypes.func,
};
