
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import PropTypes from 'prop-types';
import {logError} from '../../podcastApi.js';
import React, {useEffect, useState} from 'react';
import errimg from '../../images/mcginn.jpg';
import {Image} from 'primereact/image';
import './Errormsg.scss';
const Errormsg = (props) => {
  const [loading, setLoading] = useState(false);
  const [errinfo, setErrinfo] = useState();
  const [show, setShow] = useState(false);
  // Procedure to retry payment. I know, same name as react component
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (props.message) {
      setLoading(true);
      // Handle unpaid subscriptions
      setErrinfo(props.message.userInfo);
      console.log(props.message.error);
      setLoading(false);
      logError(props.message.error);
      setShow(true);
    };
  }, [props.message]);

  const errormsgAction = () => {
    setLoading(true);
    window.location.reload();
    setLoading(false);
  };
  const renderdispFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Endurhlaða síðu" onClick={(e) => errormsgAction()} loading={loading} autoFocus className="p-button-warning w-full"/>
      </div>
    );
  };
  const hide = (msg) => {
    setShow(false);
  };
  const head = () => {
    return (
      <div className="c-error-image">
        <Image src={errimg} template="p-image-action" alt="Image Text" />
      </div>
    );
  };
  const modal = () => {
    return (
      <Dialog visible={show} id="errormsg_id" className="c-error-page" header={head} footer={renderdispFooter()} onHide={() => hide()}>
        <div className="w-full flex flex-column align-items-center">
          <p className="font-bold">Obbosí.. nú klikkaði eitthvað</p>
          <div style={{whiteSpace: 'pre-line'}}>{errinfo}</div>
          <br />
          <br />
        </div>
      </Dialog>
    );
  };
  return (
    <>
      {modal()}
    </>
  );
};
export default Errormsg;
Errormsg.propTypes = {
  openErrormsg: PropTypes.any,
  message: PropTypes.any,
  type: PropTypes.any,
};
