import {Button} from 'primereact/button';
import {Image} from 'primereact/image';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import 'react-jinke-music-player/assets/index.css';
import {useNavigate} from 'react-router-dom';
import sdlogo from '../../images/rammi.svg';
import {getPodcasts, updateUser} from '../../podcastApi.js';
import './Stream.scss';
/**
 * @param  {string} string
 */
function clDebug(string) {
  if (window.debug) {
    console.log(string);
  };
};
/**
* @return {header}
*/
export default function Stream({uData}) {
  // const [name, setName] = useState('');
  const toast = useRef(null);
  // const [dispepisodes, updatedispepisodes] = useState(JSON.parse(localStorage.getItem('wcEpisodes')) || [{Name: 'A riðill'}, {Name: 'B riðill'}, {Name: 'C riðill'}, {Name: 'D riðill'}]);
  const [userSubscribed, setuserSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [episodes, setEpisodes] = useState(true);
  const navigate = useNavigate();
  // redirect to subscription page
  const getSubscriptionPage = () => {
    navigate('/askriftir', {replace: false});
  };
  // Gets all the users data
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!uData) {
      setLoading(true);
      setuserSubscribed(uData.isSub);
      if (uData.googleData.isSub !== uData.isSub) {
        // Rapyd ræður hvort hann sé sub
        const data = {
          isSub: uData.isSub,
        };
        updateUser(data);
        uData.googleData = true;
      };
      setLoading(false);
      clDebug('Stillti gögn');
    };
  }, [uData]);
  // Episode listener
  useEffect( () => {
    const getData = async () => {
      const data = await getPodcasts();
      setEpisodes(data.length);
      setLoading(false);
    };
    if (!!uData) {
      getData();
    };
    clDebug('Sótti þættina');
  }, [uData]);

  return (
    <div className="c-home podcast-container" style={{width: '100%', height: '100%'}}>
      <Toast ref={toast}></Toast>
      <Helmet>
        <title>Streymi</title>
        <meta property="og:url" content="https://stevedagskra.is/streymi" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content="Strákarnir í beinni á Twitch" />
        <meta property="og:image" content="https://stevedagskra.is/twitterstreamimg.png"/>
        <meta property="og:title" content="Extra Steve áskrift" />
        <meta property="twitter:description" content="Strákarnir í beinni á Twitch"/>
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image:src" content="https://stevedagskra.is/twitterstreamimg.png"/>
      </Helmet>
      {!userSubscribed && !loading && <div className="c-sd-home-cover">
        <div className="flex-container">
          <div className="podcast-details">
            <div className="c-sd-title">STEVE ÁSKRIFT</div>
            <p>Nýr heimur afþreyingar er handan við hornið en fyrir litlar 1290kr á mánuði færðu vikulegan aukaþátt þar sem farið verður yfir það helsta úr liðinni viku ásamt öðru aukaefni. </p>
            <ul>
              <li>{episodes} þættir</li>
              <li>6 sjónvarpsþættir</li>
              <li>Forgangur á viðburði</li>
            </ul>
            <div className="button-container">
              <Button className="p-button-lg p-button--purple" onClick={getSubscriptionPage}>Kaupa áskrift</Button>
            </div>
          </div>
          <div className="image-container">
            <Image src={sdlogo} template="p-image-action" alt="Image Text" />
          </div>
        </div>
      </div>}
      <div className="c-hlusta-category">
        <section>
          <div className="c-hlusta-category c-hm-season">
            <div className="c-sd-header">
              <h1 className="c-sd-text">Streymi</h1>
            </div>
            <div className="episodes-container">
              <iframe src="https://player.twitch.tv/?channel=stevedagskra&parent=stevedagskra.is&parent=staging.stevedagskra.is&parent=www.stevedagskra.is&parent=www.staging.stevedagskra.is" frameBorder="0" allowFullScreen="true" scrolling="no" height="378" width="620"></iframe>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
Stream.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
};
