/* eslint-disable indent */
/* eslint-disable quote-props */
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import BlogForm from '../../components/blogform/BlogForm.js';
import Playlist from '../../components/playlist/Playlist.js';
import {getUrl} from '../../podcastApi.js';
import {seconds2HHMM, secsValid, toDateTime} from '../../utils/helperFuncs.js';
import './Blog.scss';
/**
 * @param  {object} jsonObject
 * @param  {string} key
 * @return {boolean} boolean
 */
function doesKeyExistWithValue(jsonObject, key) {
  return (jsonObject.hasOwnProperty(key) && jsonObject[key] !== null && jsonObject[key] !== undefined && jsonObject[key] !== '');
}

/**
 * @return {User} React.fragment
 */
function Blog({uData, setAudioList, audioInstance, currentEpisode, setcurrentEpisode, audioStatus, setcurrentTime, authenticated}) {
  const toast = useRef(null);
  const [playBtn, setplayBtn] = useState(false);
  const [description, setDescription] = useState();
  const [episodes, setEpisodes2] = useState();
  const [name, setName] = useState();
  const [episodeImg, setepisodeImg] = useState();
  const [type, setType] = useState('audio');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [createdTime, setCreatedtime] = useState();
  const [duration, setDuration] = useState('00:00');
  const [songData, setSongData] = useState({});
  const [hasDemourl, sethasDemourl] = useState(false);
  const [episodeId, setEpisodeid] = useState();
  const [fileName, setFilename] = useState();
  const params = useParams();
   const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // Scroll duration in milliseconds
      smooth: 'easeInOutQuart', // Scroll easing
    });
  };
  const setImg = (url) => {
    const getImageurl = async () => {
      try {
        if (url) {
          const tmpImg = await getUrl(url);
          setepisodeImg(tmpImg);
          setImageLoaded(true);
        };
      } catch (e) {
        setepisodeImg('https://firebasestorage.googleapis.com/v0/b/steve-prod.appspot.com/o/steveicon.png?alt=media&token=9968a4e4-428c-4f4c-9002-16a2d201d3f6');
      };
    };
    getImageurl();
  };
  // Episode listener
  useEffect( () => {
    const setData = (data) => {
      // const data = await getPodcast(params.id);
      if (data) {
        setEpisodeid(data.episodeId);
        setSongData(data);
        setDescription(data.Description);
        setName(data.Name);
        setImg(data.ImageUrl);
        setCreatedtime(toDateTime(data.Created.seconds));
        setDuration(seconds2HHMM(data.duration));
        sethasDemourl(doesKeyExistWithValue(data, 'DemoUrl'));
        if (doesKeyExistWithValue(data, 'type') && doesKeyExistWithValue(data, 'videoName')) {
          setType(data.type);
          setFilename(data.videoName);
        } else {
          setType('audio');
          setFilename('');
        };

        // setImageLoaded(true);
        scrollToTop();
      };
    };
    if (!!params.id && !!episodes) {
      // getData();
      let epnr = params.id.replace('ep', '');
      epnr = parseInt(epnr, 10);
      const episodeWithEpNr = episodes.find((episode) => episode.epNr === epnr);
      setData(episodeWithEpNr);
    };
  }, [episodes, params.id]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const changeSong = (episode, time, episodeId) => {
    setcurrentEpisode(episodeId);
    localStorage.setItem('lastPlayId', episodeId);
    const newAudioList = episode;
    setcurrentTime(time);
    setAudioList(newAudioList);
  };
  useEffect(() => {
    if ( episodeId == currentEpisode) {
      if (audioStatus) {
        // setIsRunning(true);
        setplayBtn(false);
      };
      if (!audioStatus) {
        // setIsRunning(false);
        setplayBtn(true);
      };
    } else {
      setplayBtn(true);
    };
  }, [audioStatus, currentEpisode, episodeId, params.id]);

  const updateSong = () => {
    // setplayBtn(true);
    const setSong = async () => {
      const audio = audioInstance;
      if (audio && episodeId == currentEpisode) {
        audio.play();
      } else {
        // audio.pause();
        if (audio) {
          audio.pause();
        };
        const newAudioList = [];
        try {
          const isSub = uData?.isSub;
          const fileUrl = isSub ? songData.FileName : songData.DemoUrl;
          const musicUrl = await getUrl(fileUrl);
          const song = {
            cover: episodeImg,
            musicSrc: musicUrl,
            name: name,
            singer: description,
            currentTime: uData?.googleData?.episodeStatus[episodeId] || 0,
            autoPlay: true,
          };
          newAudioList.unshift(song);
        } catch (e) {
          toast.current.show({severity: 'error', summary: 'Gat ekki sótt þátt', detail: 'Við biðjumst velvirðingar', life: 3000});
        }
        changeSong(newAudioList, uData?.googleData?.episodeStatus[episodeId], episodeId);
      };
    };
    if (secsValid(createdTime)) {
      toast.current.show({severity: 'error', summary: 'Þátturinn er ekki kominn út', detail: 'Mátti reyna', life: 3000});
    } else {
      setSong();
    };
  };
  const pauseSong = () => {
    try {
      const audio = audioInstance;
      audio.pause();
      setplayBtn(true);
    } catch (e) {

    };
  };
  const pauseBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude p-button-lg" label="Pása" name="deleteButton" icon="pi pi-pause" onClick={pauseSong}/>;
  const checkforDemo = () => {
    if (hasDemourl) {
      return playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude p-button-lg" name="deleteButton" label="Spila hljóðbrot" rounded text icon="pi pi-play" onClick={updateSong}/>;
    } else {
      return playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude p-button-lg" disabled name="deleteButton" label="Spila" rounded text icon="pi pi-lock" />;
    };
  };
  let playBtnelement;
  if (uData?.isSub === true) {
    playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude p-button-lg" name="deleteButton" label={type == 'audio' ? 'Spila' : 'Hlusta'} rounded text icon="pi pi-play" onClick={updateSong}/>;
  } else {
    // sýna demo
    playBtnelement = checkforDemo();
  };
  return (
      <div className="c-home c-blog-container">
        <Toast ref={toast} />
        <BlogForm imageLoaded={imageLoaded}
          episodeImg={episodeImg}
          handleImageLoaded={handleImageLoaded}
          name={name}
          playBtn={playBtn}
          playBtnelement={playBtnelement}
          description={description}
          createdTime={createdTime}
          pauseBtnelement={pauseBtnelement}
          duration={duration}
          type={type}
          fileName={fileName}
          isSub={uData?.isSub}
          ></BlogForm>
        <div className="c-blog-playlist">
          <Playlist
            title='Aðrir þættir'
            setAudioList={setAudioList}
            audioInstance={audioInstance}
            currentEpisode={currentEpisode}
            setcurrentEpisode={setcurrentEpisode}
            audioStatus={audioStatus}
            setcurrentTime={setcurrentTime}
            uData={uData}
            authenticated={authenticated}
            setEpisodes={setEpisodes2}/>
        </div>
      </div>
  );
}

export default Blog;
Blog.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  authenticated: PropTypes.any,
};
