import {Button} from 'primereact/button';
import {Image} from 'primereact/image';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import 'react-jinke-music-player/assets/index.css';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Playlist from '../../components/playlist/Playlist.js';
import sdlogo from '../../images/rammi.svg';
import './Home.scss';
/**
* @return {header}
*/
export default function Home({uData, setAudioList, audioInstance, currentEpisode, setcurrentEpisode, audioStatus, setcurrentTime, authenticated}) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  // redirect to subscription page
  const getSubscriptionPage = () => {
    navigate('/nyskra', {state: {nextPath: '/askriftir', from: location}});
  };

  if (authenticated === undefined) {
    return <Loading isBlocked={true}></Loading>;
  };
  if (authenticated) {
    return <Navigate replace to='/forsida' state={{from: location}} />;
  };
  return (
    <div className="c-home podcast-container" style={{width: '100%', height: '100%'}}>
      <Toast ref={toast}></Toast>
      <div className="c-sd-home-cover">
        <div className="flex-container">
          <div className="podcast-details">
            <div className="c-sd-title">STEVE ÁSKRIFT</div>
            <p>Nýr heimur afþreyingar er handan við hornið en fyrir litlar 1290kr á mánuði færðu vikulegan aukaþátt þar sem farið verður yfir það helsta úr liðinni viku ásamt öðru aukaefni. </p>
            <div className="button-container">
              <Button className="p-button-lg p-button--purple" onClick={getSubscriptionPage}>Kaupa áskrift</Button>
            </div>
          </div>
          <div className="image-container">
            <Image src={sdlogo} template="p-image-action" alt="Image Text" />
          </div>
        </div>
      </div>
      <Playlist
        uData={uData}
        authenticated={false}
        isSub={false}
        setAudioList={setAudioList}
        audioInstance={audioInstance}
        currentEpisode={currentEpisode}
        setcurrentEpisode={setcurrentEpisode}
        audioStatus={audioStatus}
        setcurrentTime={setcurrentTime}/>
    </div>
  );
}
Home.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  authenticated: PropTypes.any,
};
