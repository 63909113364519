import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import {getEvent} from '../../podcastApi.js';

const Layout = ({children, uData, setAuthenticated, authenticated, setrefreshKey, refreshKey, isAdmin, payload}) => {
  const [message, setMessage] = useState();
  const [btnmessage, setBtnmessage] = useState();
  const [url, setUrl] = useState();
  const [oururl, setOururl] = useState();
  const [showBanner, setShowBanner] = useState(false); // State to control the visibility of the banner
  const navigate = useNavigate();
  const buttonAction = () => {
    if (oururl) {
      navigate('/' + url, {replace: false});
    } else {
      window.open(url, '_blank');
    };
  };
  useEffect(() => {
    const getData = async () => {
      const data = await getEvent();
      setMessage(data.texti);
      setBtnmessage(data.takkiTexti);
      setUrl(data.url);
      setOururl(data.okkarUrl);
      // Convert Unix timestamp to milliseconds
      const eventDate = new Date(data.dagsetning.seconds * 1000);
      const currentDate = new Date();
      // Check if the event date has passed
      setShowBanner(currentDate < eventDate && window.location.pathname != '/pubquiz');
    };
    getData();
  }, [window.location.pathname]);

  return (
    <>
      <Header category="Steve dagskrá" title="Fannst ekki!" uData={uData} setrefreshKey={setrefreshKey} refreshKey={refreshKey} setAuthenticated={setAuthenticated} authenticated={authenticated} isAdmin={isAdmin} payload={payload} />
      <main>
        <div className="sd-layout">
          {showBanner && (
            <div className="sd-banner">
              <div className="sd-banner-message">{message}</div>
              <div className="sd-banner-action">
                <Button className="p-button p-component p-button-warning p-button-text sd-exclude sd-cancel" onClick={buttonAction}>{btnmessage}</Button>
              </div>
            </div>
          )}
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uData: PropTypes.any,
  setAuthenticated: PropTypes.any,
  authenticated: PropTypes.any,
  setrefreshKey: PropTypes.any,
  refreshKey: PropTypes.any,
  isAdmin: PropTypes.any,
  payload: PropTypes.any,
};

export default Layout;
