import {BlockUI} from 'primereact/blockui';
import {Button} from 'primereact/button';
import {ProgressBar} from 'primereact/progressbar';
import {Skeleton} from 'primereact/skeleton';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getUrl} from '../../podcastApi.js';
import {seconds2HHMM, secsValid, toDateTime} from '../../utils/helperFuncs.js';
import Errormsg from '../../components/errormsg/Errormsg.js';
import './PlaylistItemV2.scss';


const PlaylistItemV2 = (props) => {
  const [iconType, seticonType] = useState('pi pi-lock');
  const toast = useRef(null);
  const [playBtn, setplayBtn] = useState(true);
  const [blockedPanel, setblockedPanel] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [episodeFinished, setEpisodefinished] = useState(false);
  const [episodeImg, setepisodeImg] = useState();
  const [value1, setValue1] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [errormessage, seterrormessage] = useState(false);
  const episodeStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
  const navigate = useNavigate();
  let durationHHMM = '00:00';
  /* window.addEventListener('storage', () => {
    console.log('change to local storage!');
  });*/
  const gotoBlog = (e) => {
    if (e.target.name === 'deleteButton' || e.target.parentElement.name === 'deleteButton') {
      e.preventDefault();
      e.stopPropagation();
    } else {
      navigate('/ep'+props.index, {replace: false});
    }
  };
  const myInterval = useRef();
  useEffect(() => {
    // You had this line to start timer on load
    // but you can just set the initial state to `true`
    // setFlag(true);
    // Clear time on component dismount
    return () => clearInterval(myInterval.current);
  }, []);
  // useEffect that start/stop interval on flag change
  useEffect(() => {
    if (isRunning) {
      myInterval.current = setInterval(
          () => {
            const epStatus = JSON.parse(localStorage.getItem('episodeStatus')) || {};
            setValue1(Math.round((epStatus[props.episodeId] / props.duration)*100));
          },
          1000,
      );
    } else {
      clearInterval(myInterval.current);
      myInterval.current = null;
    }
  }, [isRunning]);
  useEffect( async () => {
    if (props.isSub) {
      if (!secsValid(props.Created.seconds)) {
        setblockedPanel(false);
      } else {
        seticonType('pi pi-calendar-plus');
        setblockedPanel(true);
      };
    } else {
      setblockedPanel(false);
      pauseSong();
    };
  }, [props.Created, props.isSub]);
  useEffect( async () => {
    try {
      if (props.ImageUrl) {
        setDataLoaded(true);
        const tmpImg = await getUrl(props.ImageUrl);
        setepisodeImg(tmpImg);
      };
    } catch (e) {
      setepisodeImg('https://firebasestorage.googleapis.com/v0/b/steve-prod.appspot.com/o/steveicon.png?alt=media&token=9968a4e4-428c-4f4c-9002-16a2d201d3f6');
    };
  }, [props.ImageUrl]);
  // listener fyrir play takkana
  useEffect(() => {
    if ( props.episodeId != props.currentEpisode) {
      setplayBtn(true);
    };
  }, [props.currentEpisode]);
  useEffect(() => {
    setValue1(Math.round((episodeStatus[props.episodeId] / props.duration)*100) || 0);
  }, [props.duration, episodeStatus[props.episodeId]]);
  useEffect(() => {
    if ( props.episodeId == props.currentEpisode) {
      if (props.audioStatus) {
        setIsRunning(true);
        setplayBtn(false);
      };
      if (!props.audioStatus) {
        setIsRunning(false);
        setplayBtn(true);
      };
    } else {
      setplayBtn(true);
    };
  }, [props.audioStatus, props.currentEpisode]);
  useEffect(() => {
    if (value1/100 > 0.98) {
      setEpisodefinished(true);
    }
  }, [value1]);
  const updateSong = () => {
    // setplayBtn(true);
    const setSong = async () => {
      const audio = props.audioInstance;
      if (audio && props.episodeId == props.currentEpisode) {
        audio.play();
      } else {
        // audio.pause();
        if (audio) {
          audio.pause();
        };
        const newAudioList = [];
        try {
          const fileUrl = props.isSub ? props.FileName : props.DemoUrl;
          const musicUrl = await getUrl(fileUrl);
          const song = {
            cover: episodeImg,
            musicSrc: musicUrl,
            name: props.Name,
            singer: props.Description,
            currentTime: episodeStatus[props.episodeId] || 0,
            autoPlay: true,
          };
          newAudioList.unshift(song);
        } catch (e) {
          // toast.current.show({severity: 'error', summary: 'Gat ekki sótt þátt', detail: 'Við biðjumst velvirðingar', life: 3000});
          seterrormessage({userInfo: 'Ekki tókst að sækja þátt. Prufaðu að endurhlaða síðu', error: e.toString()});
        }
        props.changeSong(newAudioList, episodeStatus[props.episodeId] || 0, props.episodeId);
      };
    };
    if (secsValid(props.Created.seconds)) {
      toast.current.show({severity: 'error', summary: 'Þátturinn er ekki kominn út', detail: 'Mátti reyna', life: 3000});
    } else {
      setSong();
    };
  };
  const pauseSong = () => {
    try {
      const audio = props.audioInstance;
      audio.pause();
      setplayBtn(true);
    } catch (e) {
    };
  };
  try {
    durationHHMM = seconds2HHMM(props.duration);
  } catch (e) {
  };
  const createdTime = toDateTime(props.Created.seconds);

  const subTitle = (
    <div className="c-episode-icons">
      <div className="c-episode-icons-container">
        <span className="pi pi-calendar"></span>
        {createdTime}
      </div>
      <div className="c-episode-icons-container">
        <span className="pi pi-clock"></span>
        {durationHHMM}
      </div>
    </div>
  );
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const header = (
    <>
      <img alt="Card" src={episodeImg} style={{display: imageLoaded? 'flex': 'none'}} onLoad={handleImageLoaded}/>
      <Skeleton style={{width: !imageLoaded? '56px': 'none'}} shape="rectangle" width={!imageLoaded? '56px': '0em'} height={!imageLoaded? '56px': '0px'} className={!imageLoaded ? 'mr-2 c-skeleton-margin' : 'mr-2'}></Skeleton>
      <div>
        {dataLoaded ? <div className="c-playlist-title">{props.Name}</div> : <><Skeleton shape="rectangle" width="15em" height="1.5rem" className="mr-2"></Skeleton></>}
        {dataLoaded ? subTitle : <><Skeleton shape="rectangle" width="20em" height="1.2rem" className="mr-2"></Skeleton></>}
      </div>
    </>
  );
  const checkforDemo = () => {
    if (props.DemoUrl) {
      return playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" name="deleteButton" label="Spila hljóðbrot" rounded text icon="pi pi-play" onClick={updateSong}/>;
    } else {
      return playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" disabled name="deleteButton" label="Spila" rounded text icon="pi pi-lock" onClick={updateSong}/>;
    };
  };
  let playBtnelement;
  if (props.authenticated === true) {
    if (props.isSub === true) {
      playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" name="deleteButton" label="Spila" rounded text icon="pi pi-play" onClick={updateSong}/>;
    } else {
      // sýna demo
      playBtnelement = checkforDemo();
    }
  } else if (props.isSub === false) {
    // sýna demo ef til
    playBtnelement = checkforDemo();
  } else {
    playBtnelement = <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" disabled name="deleteButton" label="Spila" rounded text icon="pi pi-lock"/>;
  };
  const footer = (
    <>
      <div className="c-episode-controls">
        {playBtn && playBtnelement}
        {!playBtn && <Button className="p-button-rounded p-button-text p-button-plain sd-exclude" label="Pása" name="deleteButton" icon="pi pi-pause" onClick={pauseSong}/>}
        {props.authenticated && <div className="c-episode-icons-container">
          {!episodeFinished ? <ProgressBar value={value1} showValue={false} style={{width: '60px', height: '5px', borderWidth: '1px', borderStyle: 'solid'}} ></ProgressBar> : <i className="pi pi-check"></i>}
        </div>}
      </div>
    </>
  );
  return (
    <li className="c-playlist-itemv2">
      <Errormsg message={errormessage}></Errormsg>
      <Toast ref={toast} />
      <BlockUI blocked={blockedPanel} onClick={gotoBlog} conClick={updateSong} template={<i className={iconType} style={{'fontSize': '3rem', 'zIndex': '985'}} />}>
        <div className={props.episodeId == props.currentEpisode ? 'c-playlist-info c-active-episode2' : 'c-playlist-info'} >
          <div className="c-playlist-img" onClick={gotoBlog}>
            {header}
          </div>
          <div className="c-episode-info-text">
            {dataLoaded ? <div className="p-card-name-text" onClick={gotoBlog}><p className="m-0" style={{lineHeight: '1.5'}}>{props.Description}</p></div> : <><Skeleton shape="rectangle" width="25em" height="4rem" className="mr-2"></Skeleton></>}
            {footer}
          </div>
        </div>
      </BlockUI>
    </li>
  );
};
export default PlaylistItemV2;
PlaylistItemV2.propTypes = {
  Name: PropTypes.any,
  ImageUrl: PropTypes.any,
  DemoUrl: PropTypes.any,
  Description: PropTypes.any,
  changeSong: PropTypes.any,
  Created: PropTypes.any,
  audioInstance: PropTypes.any,
  FileName: PropTypes.any,
  isSub: PropTypes.any,
  filterEpisodes: PropTypes.any,
  episodeId: PropTypes.any,
  episodeStatus: PropTypes.any,
  duration: PropTypes.any,
  currentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  tags: PropTypes.any,
  filter: PropTypes.any,
  addSong: PropTypes.any,
  authenticated: PropTypes.any,
  index: PropTypes.any,
};
PlaylistItemV2.defaultProps = {
  Description: '',
  isSub: false,
  audioInstance: '',
  episodeId: '',
  episodeStatus: 0,
  currentEpisode: '',
  duration: 100000,
  audioStatus: '',
  tags: '',
  imageUrl: '',
  Created: {seconds: 0},
  authenticated: false,
};
