import PropTypes from 'prop-types';
import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Loading from '../components/loading/Loading';
/**
 * function for protected routes
 * @return {boolean}
 */
export default function RequireAdmin({isAdmin}) {
  const location = useLocation();
  if (isAdmin === undefined) {
    return <Loading isBlocked={true}></Loading>;
  };
  if (!isAdmin) {
    return <Navigate replace to='/' state={{from: location}} />;
  }
  return <Outlet />;
};
RequireAdmin.propTypes = {
  isAdmin: PropTypes.any,
};
