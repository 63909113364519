const ERROR_PROCESSING_CARD = [{number: 'ERROR_PROCESSING_CARD - [1]', name: 'Refer to card issuer', message: 'The request attempted a card operation, but the card issuer refused the transaction. The request was rejected. Corrective action: Advise the customer to use a different payment method and contact the card issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [2]', name: 'Refer to card issuer, special condition', message: 'The request attempted a card operation, but the card issuer refused the transaction. The request was rejected. Corrective action: Advise the customer to use a different payment method and contact the card issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [3]', name: 'Invalid Merchant', message: 'The request attempted a card operation, but there was a problem with the merchant’s status. The request was rejected. Corrective action: Contact the card issuer. Advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [4]', name: 'Pick Up Card, no fraud', message: 'The request attempted a card operation, but the card issuer wants the card returned. The request was rejected. Corrective action: Advise the cardholder to contact the card issuer to resolve the problem. In addition, if you have the physical card, confiscate it and return it to the issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [5]', name: 'Do Not Honor', message: 'The request attempted a card operation, but the card issuer refused the transaction. The request was rejected. Corrective action: Advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [6]', name: 'Error', message: 'The request attempted a card operation, but the payment failed for an unknown reason. The request was rejected. Corrective action: Try again. If the error persists, advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [7]', name: 'Pick Up Card, fraud suspected', message: 'The request attempted a card operation, but the card issuer wants the card returned because of suspected fraud. The request was rejected. Corrective action: Advise the cardholder to contact the card issuer to resolve the problem. In addition, if you have the physical card, confiscate it and return it to the issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [12]', name: 'Invalid Transaction', message: 'The request attempted a card operation, but the card is not authorized for this type of transaction. The request was rejected. Corrective action: Advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [13]', name: 'Invalid Amount', message: 'The request attempted a card operation, but the card is not authorized for online purchases or the amount contains a non-numeric symbol. The request was rejected. Corrective action: Correct the amount or advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [14]', name: 'Invalid Card Number', message: 'The request attempted a card operation, but the card issuer did not recognize the card number. The request was rejected. Corrective action: Correct the card number or advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [15]', name: 'Invalid Issuer', message: 'The request attempted a card operation, but the card number was not valid. The request was rejected. Corrective action: Correct the card number.'},
  {number: 'ERROR_PROCESSING_CARD - [17]', name: 'Customer Cancellation', message: 'The customer canceled the transaction. Corrective action: Create a new payment.'},
  {number: 'ERROR_PROCESSING_CARD - [19]', name: 'Re-enter', message: 'The request attempted a card operation, but the payment failed for an unknown reason. The request was rejected. Corrective action: Try again. If the error persists, advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [41]', name: 'Lost Card, pick up', message: 'The request attempted a card operation, but the card issuer wants the card returned because the cardholder reported it lost. The request was rejected. Corrective action: Advise the cardholder to contact the card issuer to resolve the problem. In addition, if you have the physical card, confiscate it and return it to the issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [42]', name: 'Stolen Card, pick up', message: 'The request attempted a card operation, but the card issuer wants the card returned because the cardholder reported it stolen. The request was rejected. Corrective action: Consider not doing business with this customer. In addition, if you have the physical card, confiscate it and return it to the issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [43]', name: 'Stolen Card, pick up', message: 'The request attempted a card operation, but the card issuer wants the card returned because the cardholder reported it stolen. The request was rejected. Corrective action: Consider not doing business with this customer. In addition, if you have the physical card, confiscate it and return it to the issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [46]', name: 'Closed Account', message: 'The request attempted a card operation, but the account is closed. The request was rejected. Corrective action: Advise the cardholder to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [50]', name: 'Invalid Payment Condition', message: 'Timeout or invalid password. Corrective action: Create a new payment.'},
  {number: 'ERROR_PROCESSING_CARD - [51]', name: 'Insufficient Funds', message: 'The request attempted a card operation, but the transaction would exceed the card limit. The request was rejected. Corrective action: Advise the cardholder to add funds to the account or use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [54]', name: 'Expired Card', message: 'The request attempted a card operation, but the card has expired. The request was rejected. Corrective action: Advise the cardholder to use a different payment method, or contact the card issuer to issue a new card.'},
  {number: 'ERROR_PROCESSING_CARD - [56]', name: 'No Card Record', message: 'The request tried to create a card payment, but there was no record of the card number. The request was rejected. Corrective action: Correct the card number or advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [57]', name: 'Transaction not Permitted to Cardholder', message: 'The request attempted a card operation, but the card is not authorized for this type of transaction. The request was rejected. Corrective action: Advise the customer to contact the card issuer for authorization for the transaction, or use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [58]', name: 'Transaction not Permitted to Acquirer or Terminal', message: 'The request attempted a card operation, but the acquirer or terminal is not authorized for this type of transaction. The request was rejected. Corrective action: Contact Client Support.'},
  {number: 'ERROR_PROCESSING_CARD - [59]', name: 'Suspected Fraud', message: 'The request attempted a card operation, but the card issuer suspects fraud in this transaction. The request was rejected. Corrective action: Consider not doing business with this customer.'},
  {number: 'ERROR_PROCESSING_CARD - [61]', name: 'Withdrawal Amount Limit Exceeded', message: 'The request attempted a card operation, but the transaction would exceed the card limit. The request was rejected. Corrective action: Advise the cardholder to add funds to the account or use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [62]', name: 'Restricted Card', message: 'The request attempted a card operation, but the card is not authorized for use in the country of the merchant. The request was rejected. Corrective action: Advise the cardholder to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [63]', name: 'Security Violation', message: 'The request attempted a card operation, but there was a problem with the card’s security features. The request was rejected. Corrective action: Verify the CVV, or advise the cardholder to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [65]', name: 'Authentication Required or Activity Limit Exceeded', message: 'The request attempted a card operation, but 3DS was not completed or the transaction would exceed the card’s activity limit. The request was rejected. Corrective action: Resubmit with 3DS required or use another payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [68]', name: 'Response Received Too Late', message: 'Late Response. Corrective action: Create a new payment.'},
  {number: 'ERROR_PROCESSING_CARD - [70]', name: 'PIN Required or Contact Issuer', message: 'The request attempted a card operation, but the PIN was not provided or the card issuer requests contact from the cardholder. The request was rejected. Corrective action: Ask the cardholder to enter the PIN or use another payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [75]', name: 'Allowable Number of PIN Tries Exceeded', message: 'The request attempted a card operation, but the customer has entered the PIN code incorrectly too many times. The request was rejected. Corrective action: Advise the cardholder to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [78]', name: 'Blocked, first used', message: 'The request attempted a card operation, but the card is new and has not yet been unblocked. The request was rejected. Corrective action: Advise the cardholder to complete the process for unblocking the card.'},
  {number: 'ERROR_PROCESSING_CARD - [83]', name: 'Security Issue or Suspected Fraud', message: 'The request attempted a card operation, but there was a security issue or the card issuer suspects fraud. The request was rejected. Corrective action: None. Advise the cardholder to contact the card issuer.'},
  {number: 'ERROR_PROCESSING_CARD - [91]', name: 'Authorization Platform or Issuer System Inoperative', message: 'Fraud or security concerns. Corrective action: Advise the customer to contact the issuing bank.'},
  {number: 'ERROR_PROCESSING_CARD - [93]', name: 'Violation, cannot complete', message: 'The request attempted a card operation, but the issuing bank was not available. The request was rejected. Corrective action: Try again. If the error persists, advise the customer to use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [96]', name: 'Internal Error', message: 'The request attempted a card operation, but there was an issue with the customer’s account. The request was rejected. Corrective action: Try again. Advise the customer to contact the card issuer, or use a different payment method.'},
  {number: 'ERROR_PROCESSING_CARD - [94]', name: 'System Malfunction', message: 'Contact Rapyd Client Support.'}];
const OTHER_MESSAGES = [{name: 'ERROR_CARD_AUTHENTICATION_FAILURE', message: 'The request created a card payment that requires 3DS authentication, but the authentication was not completed in the time allowed. The payment was canceled. Corrective action: None. Create another payment.'},
  {name: 'ERROR_CARD_CVV_NOT_VALID', message: 'The request attempted an operation that requires the CVV of a card, and the CVV was correctly formatted, but the number was not valid. The request was rejected. Corrective action: Use the correct CVV value.'},
  {name: 'ERROR_CARD_INFORMATION_NOT_VALID', message: 'The request attempted an operation that requires a card, and the card information submitted was correctly formatted, but the card information was not valid. The request was rejected. Corrective action: Use correct card information.'},
  {name: 'ERROR_CARD_NOT_SUPPORTED_FOR_ECOMMERCE', message: 'The request attempted a card transaction, but the transaction is not supported for eCommerce. The request was rejected. Corrective action: None. Advise the customer to contact the card issuer.'},
  {name: 'ERROR_CARD_NOT_VALID', message: 'The request tried to use a card ID, but the card is not valid. The request was rejected. Corrective action: None. Create a new card ID using "Create Payment, Add Payment Method to Customer or Create Card Token - Hosted Page.'},
  {name: 'ERROR_CARD_TYPE_DISABLED', message: 'The request attempted an operation that requires a card, but the card type is disabled. The request was rejected. Corrective action: Advise the marketplace to enable the card type.'},
  {name: 'ERROR_TRANSACTION_FAILED', message: 'The request attempted a card transaction, but the transaction failed. The request was rejected. Corrective action: None. Advise the customer to contact the card issuer.'},
  {name: 'ERROR_TRANSACTION_REJECTED_BY_CARD_PROCESSOR', message: 'The request attempted a card transaction, but the transaction failed. The request was rejected. Corrective action: The error code includes a numeric code that is standard to the Visa and Mastercard industry. Correct the error condition and submit the request again.'},
  {name: 'ERROR_TRANSACTION_TYPE_NOT_ALLOWED_BY_SELLER', message: 'The request attempted a card transaction, but the transaction is not allowed by the seller. The request was rejected. Corrective action: None. Advise the customer to contact the seller.'},
  {name: 'ERROR_TRANSACTION_TYPE_NOT_SUPPORTED', message: 'The request attempted a card transaction, but the transaction is not supported by the card network. The request was rejected. Corrective action: None. Advise the customer to contact the card issuer.'},
  {name: 'INVALID_CARD_CVV', message: 'The request attempted an operation that requires a card verification value, but the number was not recognized. The request was rejected. Corrective action: Set cvv to a valid value.'},
  {name: 'ERROR_3DS_FAILURE', message: 'Greiðsla var ekki samþykkt í heimabanka'}];
/**
 * function
 *
 * @param  {string} string
 * @return {string} translated to icelandic
 */
export function getErrormessage(string) {
  if (!!string) {
    if (string.indexOf('ERROR_PROCESSING_CARD') != -1) {
      // const arr = string.match(/(?<=\[)[^\][]*(?=])/g)[0];
      // const resp = ERROR_PROCESSING_CARD.find((o) => (o.number == arr ));
      // console.log(ERROR_PROCESSING_CARD);
      try {
        const resp = ERROR_PROCESSING_CARD.find((o) => (o.number == string ));
        return resp.message || string;
      } catch (e) {
      };
      // resp.message || string;
    };
    try {
      const resp2 = OTHER_MESSAGES.find((o) => (o.name == string ));
      return resp2.message;
    } catch (e) {
    };

    return string || 'Óþekkt villuskilaboð. Vinsamlegast hafðu samband við admin@stevedagskra.is';
  };
};
/**
 * function
 *
 * @param  {string} string
 * @return {string} translated to icelandic
 */
export function statusTranslation(string) {
  switch (string) {
    case 'active':
      return 'Virk áskrift';
    case 'canceled':
      return 'Sagt upp';
    case 'past_due':
      return 'Vangoldin';
    case 'trialing':
      return 'Í prufuáskrift';
    case 'unpaid':
      return 'Ógreidd';
    default:
      return string;
  };
};
/**
 * function
 *
 * @param  {string} string
 * @return {string} translated to icelandic
 */
export function paystatusTranslation(string) {
  switch (string) {
    case 'paid':
      return 'Greidd';
    case 'unpaid':
      return 'Ógreidd';
    case 'past_due':
      return 'Vangoldin';
    case 'open':
      return 'Opin';
    case 'draft':
      return 'Drög';
    default:
      return string;
  };
};
/**
 * function
 *
 * @param  {number} seconds
 * @return {string} string
 */
export function seconds2HHMM(seconds) {
  if (seconds >= 3600) {
    return new Date(seconds * 1000).toISOString().substring(11, 16);
  };
  return new Date(seconds * 1000).toISOString().substring(14, 19);
};
/* ****DATES**** */
/**
 * @param  {number} secs
 * @return {date} date from seconds
 */
export function secsToDate(secs) {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
};
/**
 * @param  {number} secs
 * @return {string} string
 */
export function toDateTime(secs) {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return formatDate(t);
}
/**
 * @param  {number} num
 * @return {string} string
 */
export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
};
/**
 * @param  {date} date
 * @return {string} string
 */
export function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
};
/**
 * @param  {date} date
 * @return {string} string
 */
export function formatDateDDMM(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
  ].join('.');
};
/**
 * @param  {number} secs
 * @return {date} date
 */
export function secsToFormatedDate(secs) {
  return formatDate(secsToDate(secs));
}
/**
 * @param  {number} secs
 * @return {date} date
 */
export function secsToDDMM(secs) {
  return formatDateDDMM(secsToDate(secs));
}
/**
 * @return {date} date
 */
export function today() {
  const today = new Date();
  return today;
}
/**
 * @param {number} secs
 * @return {date} secs
 */
export function secsValid(secs) {
  return today() < secsToDate(secs);
}
/**
 * @param {number} secs
 * @return {date} secs
 */
export function timestampAge(secs) {
  return today() - secsToDate(secs);
}
