import React, {Component} from 'react';

/**
 *
 */
export default class NotFound extends Component {
  /**
   *@return {page}
   */
  render() {
    return (
      <React.Fragment>
        <p>Obbosí.. obbosí.. þú átt ekk&apos;að vera hér..</p>
      </React.Fragment>
    );
  }
}
