/* eslint-disable valid-jsdoc */
import {BlockUI} from 'primereact/blockui';
import {ProgressSpinner} from 'primereact/progressspinner';
import React, {useState} from 'react';
import './Episodes.scss';
/**
* @return {header}
*/
export default function Episodes() {
  const [blockedDocument, setBlockedDocument] = useState(false);
  /**
* @return {render}
*/
  setTimeout( () => setBlockedDocument(true), 3000);
  return (
    <div>
      {<BlockUI blocked={blockedDocument} fullScreen >
        <ProgressSpinner className="loading-spinner"/>
      </BlockUI>}
      <h1>Viljum við ?</h1>
    </div>
  );
};
