/* eslint-disable indent */
/* eslint-disable quote-props */
import {BlockUI} from 'primereact/blockui';
import {Skeleton} from 'primereact/skeleton';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useRef, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {ControlBar, Player} from 'video-react';
import {getUrl} from '../../podcastApi.js';
import './BlogForm.scss';
/**
 * @return {User} React.fragment
 */
function BlogForm({imageLoaded, episodeImg, handleImageLoaded, name, playBtn, playBtnelement, description, createdTime, duration, pauseBtnelement, type, fileName, isSub}) {
  const toast = useRef(null);
  const [playerref, setPlayerref] = useState();
  const [player, setPlayer] = useState();
  const epInfo = (
    <div className="c-episode-icons">
      <div className="c-episode-icons-container">
        <span className="pi pi-calendar"></span>
        {createdTime}
      </div>
      <div className="c-episode-icons-container">
        <span className="pi pi-clock"></span>
        {duration}
      </div>
    </div>
  );
  useEffect(() => {
    const workData = async () => {
      try {
        let playertmp;
        const url = await getUrl(fileName);
        if (isSub) {
          playertmp = <div className="c-episode-videoplayer"><Player playsInline
              ref={(player) => {
                setPlayerref(player);
              }} poster={episodeImg}>
              <source
                src={url}
              />
              <ControlBar autoHide={true} />
            </Player></div>;
        } else {
          playertmp =
            <div className="c-episode-videoplayer"><BlockUI blocked={true} template={<i className='pi pi-lock' style={{'fontSize': '3rem', 'zIndex': '985'}} />}><Player playsInline
              ref={(player) => {
                setPlayerref(player);
              }} poster={episodeImg}>
              <source
                src={url}
              />
              <ControlBar autoHide={true} />
            </Player></BlockUI></div>;
        };
        setPlayer(playertmp);
      } catch (e) {
        console.log(e);
        console.log(playerref);
      };
    };
    if (type=='video' && fileName) {
      workData();
    };
  }, [type, fileName, episodeImg, isSub]);
  return (
    <div className="c-blog-episode-controls">
    <Helmet>
      <title>{name}</title>
    </Helmet>
    <Toast ref={toast} />
      <div className="c-blog-header">
        <div className="c-blog-img-container">
          {type == 'video' ? player : (imageLoaded ? <img alt="Card" src={episodeImg} style={{display: imageLoaded? 'flex': 'none'}} onLoad={handleImageLoaded}/> :
          <Skeleton shape="rectangle" className="c-blog-skeleton"></Skeleton>)}
        </div>
      </div>
      <div className="c-blog-info-container">
        <div className="c-blog-container-header c-blog-width">
            <div className="c-blog-header-epinfo">{epInfo}</div>
            <div className="c-blog-header-text">{name}</div>
        </div>
        <div className="c-blog-description c-blog-width">
          <p>
          {description}
          </p>
        </div>
        <div className="c-blog-buttons c-blog-width">
          {playBtn && playBtnelement}
          {!playBtn && pauseBtnelement}
        </div>
      </div>
    </div>
  );
}

export default BlogForm;
BlogForm.propTypes = {
  imageLoaded: PropTypes.any,
  episodeImg: PropTypes.any,
  handleImageLoaded: PropTypes.any,
  name: PropTypes.any,
  playBtn: PropTypes.any,
  playBtnelement: PropTypes.any,
  description: PropTypes.any,
  createdTime: PropTypes.any,
  duration: PropTypes.any,
  pauseBtnelement: PropTypes.any,
  type: PropTypes.any,
  fileName: PropTypes.any,
  isSub: PropTypes.any,
};
