/* eslint-disable indent */
/* eslint-disable quote-props */
// import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {getToken2, deleteToken2, hasSupport, getUsertokens} from '../../podcastApi.js';
import React, {useRef, useState, useEffect} from 'react';
import {Dialog} from 'primereact/dialog';
import './Allownotification.scss';
import {propTypes} from 'react-bootstrap/esm/Image.js';
import {InputSwitch} from 'primereact/inputswitch';
import {ProgressSpinner} from 'primereact/progressspinner';
/**
 * @return {User} React.fragment
 */
function Allownotification({popupallowed, triggeropen, setTriggeropen}) {
  const toast = useRef(null);
  const [isTokenFound, setTokenFound] = useState(0);
  const [visible, setVisible] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [hassup, setHassup] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultmsg = <>Fáðu tilkynningar þegar þættir koma út og við erum með sérstaka viðburði<br></br><br></br>Engar áhyggjur, við munum ekki spamma þig</>;
  const [message, setMessage] = useState(defaultmsg);
  const [areNotificationsAllowed, setareNotificationsAllowed] = useState();
  const [permission, setPermission] = useState();
  const isSupported = () => {
    const getSup = async () => {
      const browsersup = await hasSupport();
      /* return 'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window;*/
      setHassup(browsersup);
      if (browsersup) {
        setPermission(Notification.permission);
        setareNotificationsAllowed(Notification && Notification.permission === 'granted');
      };
      return browsersup;
    };
    getSup();
  };
  // Run isSupported only once when the component mounts
  useEffect(() => {
    isSupported();
  }, []); // Empty dependency array ensures it runs only once
  const isIOS = (function() {
      const iosQuirkPresent = function() {
        const audio = new Audio();
          audio.volume = 0.5;
          return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
      };
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isAppleDevice = navigator.userAgent.includes('Macintosh');
      const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)
      return (isIOS && isAppleDevice && (isTouchScreen || iosQuirkPresent()));
  })();
  // Möguleikar í boði
  /**
   * 1. Notandi er að fá tilkynningu í fyrsta sinn
   * 2. Notandi er búinn að fá tilkynningu og samþykkja
   * 3. Notandi er búinn að fá tilkynningu og neitaði
   */
  /**
   * useEffect sem er einungis til að stilla default gildi á switch
   */
    useEffect(() => {
      // because we don't want to trigger a permission request
      if (!areNotificationsAllowed) return;

      const getTok = async () => {
        // Notifications are allowed, we can safely run getToken to see if they are activated on this browser
        const token = await getToken2(setTokenFound, true);
        if (token!==1) return setChecked1(false);
        if (token===1) {
          const data = await getUsertokens();
          if (data) {
            setChecked1(data.subscribedToNotifications || false);
            if (data.subscribedToNotifications) {
              setMessage(<><p>Þú færð tilkynningar frá okkur</p></>);
            };
          } else {
            setChecked1(false);
          };
        };
      };
      getTok();
    }, [areNotificationsAllowed]);
  useEffect( () => {
    try {
      if (triggeropen) {
        setVisible(true);
        setTriggeropen(false);
      };
    } catch (e) {
    };
  }, [triggeropen]);
  useEffect( () => {
    try {
      if (popupallowed) {
        if (localStorage.getItem('notifPrompt') === null) {
          // If it doesn't exist, set it to a default value (in this case, true)
          localStorage.setItem('notifPrompt', true);
          setVisible(true);
        };
      }
    } catch (e) {
    };
  }, [popupallowed]);
  useEffect( () => {
    if (permission == 'denied') {
      setMessage(<p>Vafrinn hefur lokað á tilkynningar.<br></br><br></br>Breyttu stillingum vafranum til að leyfa okkur að senda þér.</p>);
    };
  }, [permission]);
  /**
  *
  */
  function promptAllownotification() {
    const getTok = async () => {
      setLoading(true);
      // Notifications are allowed, we can safely run getToken to see if they are activated on this browser
      try {
        const token = await getToken2(setTokenFound, true);
        switch (token) {
          case 1:
            setMessage(<><p>Þú færð tilkynningar frá okkur</p></>);
            setChecked1(true);
            setTimeout(() => {
              setVisible(false);
            }, 1500);
            break;
          case 0:
            setChecked1(false);
            break;
          case -1:
            setChecked1(false);
            setMessage(<p>Vafrinn hefur lokað á tilkynningar.<br></br><br></br>Breyttu stillingum vafranum til að leyfa okkur að senda þér.</p>);
            break;
          default:
            break;
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setChecked1(false);
        setMessage(<p>Ekki tókst að skrá tæki. Reyndu einu sinni enn.<br></br>Ef vandamál heldur áfram geturu haft samband við admin@stevedagskra.is</p>);
        setLoading(false);
      }
    };
    getTok();
  };
  const usernotifchange = (value) => {
    try {
      if (value) {
        promptAllownotification();
        setChecked1(true);
      } else {
        setChecked1(false);
        if (isTokenFound == 1) {
          deleteToken2(isTokenFound);
          setMessage(defaultmsg);
          //  setChecked1(true);
        };
      };
    } catch (e) {
    };
  };
  // isTokenFound == 1 || took this out, wan't to enable users to unsubscribe
  const renderdispFooter = () => {
    return (
      <div className="c-notif-btns pt-4 flex flex-row align-items-center justify-content-between">
        <h2>Fá tilkynningar</h2>
        <InputSwitch checked={checked1} disabled={!hassup || loading ? true : false} onChange={(e) => usernotifchange(e.value)} />
      </div>
    );
  };
  const header = () => {
    return (
      <div className="c-notif-header flex flex-row align-items-center">
        <i className="pi pi-bell text-4xl sd-bg-color notif-icon"></i>
        <div className="ml-2">Aldrei missa af þætti</div>
      </div>
    );
  };
  const loadingIconStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // Add other styles for your loading icon
  };
  return (
    <><Toast ref={toast}></Toast>
      <Dialog visible={visible} className="c-notif-container" footer={renderdispFooter()} header={header} position='bottom' onHide={() => setVisible(false)}>
      {loading && <ProgressSpinner style={loadingIconStyles}/>}
        <div className="w-full flex flex-column">
          {message}
          {(isIOS || !hassup) && <p className="text-sm font-semibold">{!hassup && <p className="text-red">Vafri styður ekki tilkynningar.<br></br></p>}*Okkur sýnist þú vera að nota iPhone eða iPad<br></br>
          Taktu eftir að til þess að fá tilkynningar þarftu að sækja Steve Dagskrá vefappið í tækinu þínu (share &rarr; Add to Home Screen) og samþykkja að fá tilkynningar þar
          </p>}
        </div>
      </Dialog>
    </>
  );
}

export default Allownotification;
Allownotification.propTypes = {
  popupallowed: propTypes.any,
  triggeropen: propTypes.any,
  setTriggeropen: propTypes.any,
};
