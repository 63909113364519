import {Button} from 'primereact/button';
import {Image} from 'primereact/image';
import {Toast} from 'primereact/toast';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import 'react-jinke-music-player/assets/index.css';
import {useNavigate} from 'react-router-dom';
import Playlist from '../../components/playlist/Playlist.js';
import sdlogo from '../../images/rammi.svg';
import {updateUser} from '../../podcastApi.js';
import Retrypayment from '../../components/retrypayment/Retrypayment.js';
import Allownotification from '../../components/notification/Allownotification.js';
import './Forsida.scss';
/**
 * @param  {string} string
 */
function clDebug(string) {
  if (window.debug) {
    console.log(string);
  };
};
/**
* @return {header}
*/
export default function Forsida({uData, setAudioList, audioInstance, currentEpisode, setcurrentEpisode, audioStatus, setcurrentTime, refreshData}) {
  // const [name, setName] = useState('');
  const toast = useRef(null);
  const unsubDefaultgreeting = <p>Nýr heimur afþreyingar er handan við hornið en fyrir litlar 1290kr á mánuði færðu vikulegan aukaþátt þar sem farið verður yfir það helsta úr liðinni viku ásamt öðru aukaefni.</p>;
  const subdefaultGreeting = <p><b>Þú ert áskrifandi!</b></p>;
  const [greeting, setGreeting] = useState(unsubDefaultgreeting);
  const [userSubscribed, setuserSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retryPayment, setretryPayment] = useState(false);
  const [popupallowed, setPopupallowed] = useState(false);
  /* const [isTokenFound, setTokenFound] = useState(false);
  useEffect( () => {
    if (isTokenFound != 1) {
      getToken2(setTokenFound);
    };
  }, [isTokenFound]);*/
  // redirect to subscription page
  const navigate = useNavigate();
  const getSubscriptionPage = () => {
    navigate('/askriftir', {replace: false});
  };
  const fpButton = <Button className="p-button-lg p-button--purple" onClick={getSubscriptionPage}>Kaupa áskrift</Button>;
  const [mainbutton, setMainbutton] = useState(fpButton);
  // Gets all the users data
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (!!uData) {
      setLoading(true);
      setuserSubscribed(uData.isSub);
      // Handle unpaid subscriptions
      if ('Offerpayment' in uData ) {
        setGreeting(<><p>Áskrift þín er ógreidd<br></br>Smelltu á hnappinn fyrir meiri upplýsingar</p></>);
        // Check if the data exists in local session storage
        const storedData = sessionStorage.getItem('payerrorDisplayed'); // replace 'yourKey' with your actual key
        if (!storedData) {
          // If it doesn't exist, store the data and set the state variable to true
          const newData = true; // replace 'yourData' with the actual data you want to store
          sessionStorage.setItem('payerrorDisplayed', newData);
          setPopupallowed(false);
          setretryPayment(true);
        } else {
          // If it exists, do nothing
          setretryPayment(false);
          setPopupallowed(true);
        };
        setMainbutton(<Button className="p-button-lg p-button-danger" onClick={() => {
          setretryPayment(true);
        }}>Ógreiddur reikningur</Button>);
        setLoading(false);
      } else {
        setPopupallowed(true);
        setLoading(false);
        if (uData?.isSub) {
          setGreeting(subdefaultGreeting);
          setMainbutton(<></>);
          // getToken2(setTokenFound);
        } else {
          setGreeting(unsubDefaultgreeting);
          setMainbutton(fpButton);
        };
      };
      if (uData.googleData?.isSub !== uData?.isSub) {
        // Rapyd ræður hvort hann sé sub
        const data = {
          isSub: uData.isSub,
        };
        updateUser(data);
        uData.googleData = true;
      };
      clDebug('Stillti gögn');
    };
  }, [uData]);
  return (
    <div className="c-home podcast-container" style={{width: '100%', height: '100%'}}>
      <Toast ref={toast}></Toast>
      <Allownotification popupallowed={popupallowed} triggeropen={false}></Allownotification>
      <Retrypayment openRetrypayment={retryPayment} setretryPayment={setretryPayment} uData={uData} refreshData={refreshData} type='modal' redirect={true}></Retrypayment>
      <div className="c-sd-home-cover">
        <div className="flex-container">
          <div className="podcast-details">
            <div className="c-sd-title">STEVE ÁSKRIFT</div>
            {greeting}
            <div className="button-container">
              {!loading && mainbutton}
            </div>
          </div>
          <div className="image-container">
            <Image src={sdlogo} template="p-image-action" alt="Image Text" />
          </div>
        </div>
      </div>
      <Playlist
        uData={uData}
        authenticated={true}
        isSub={userSubscribed}
        setAudioList={setAudioList}
        audioInstance={audioInstance}
        currentEpisode={currentEpisode}
        setcurrentEpisode={setcurrentEpisode}
        audioStatus={audioStatus}
        setcurrentTime={setcurrentTime}/>
    </div>
  );
}
Forsida.propTypes = {
  uData: PropTypes.any,
  setAudioList: PropTypes.any,
  audioInstance: PropTypes.any,
  currentEpisode: PropTypes.any,
  setcurrentEpisode: PropTypes.any,
  audioStatus: PropTypes.any,
  setcurrentTime: PropTypes.any,
  currentTime: PropTypes.any,
  refreshData: PropTypes.any,
};
