
import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from 'primereact/dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import errimg from '../../images/carderr.png';
import {Image} from 'primereact/image';
import {useNavigate} from 'react-router-dom';
import {permdeleteSubscription, paySubinvoice, updateSubscription, getInvoice} from '../../rapydApi.js';
import {getWebhooks} from '../../podcastApi.js';
import './Retrypayment.scss';
/**
 * @param  {object} dataIdToMatch
 * @param  {yourArray} yourArray
 * @return {yourArray} string
 */
function findPaymentFailedObject(dataIdToMatch, yourArray) {
  // Find the object with type 'CUSTOMER_SUBSCRIPTION_UNPAID' and matching data.id
  const subscriptionUnpaidObject = yourArray.find((obj) => obj.type === 'CUSTOMER_SUBSCRIPTION_UNPAID' && obj.data.id === dataIdToMatch);
  // If the subscriptionUnpaidObject is found, get the trigger_operation_id
  if (subscriptionUnpaidObject) {
    const triggerOperationId = subscriptionUnpaidObject.trigger_operation_id;
    // Find the object with the matching trigger_operation_id and type 'PAYMENT_FAILED'
    const paymentFailedObject = yourArray.find((obj) => obj.trigger_operation_id === triggerOperationId && obj.type === 'PAYMENT_FAILED');
    return paymentFailedObject;
  };
  // Return null if no matching objects are found
  return null;
};

const Retrypayment = (props) => {
  const [message, setMessage] = useState('');
  const [displayHelp, setdisplayHelp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payerrinfo, setpayerrinfo] = useState();
  const navigate = useNavigate();
  const getSubscriptionPage = () => {
    navigate('/askriftir', {replace: false});
  };
  // Procedure to retry payment. I know, same name as react component
  useEffect( () => {
    // await Auth.authenticate();
    // clDebug(await Auth.getAuth());
    if (props.uData) {
      setLoading(true);
      // Handle unpaid subscriptions
      if ('Offerpayment' in props.uData ) {
        const getHooks = async () => {
          const hooks = await getWebhooks(props.uData.rapydData.id);
          const result = findPaymentFailedObject(props.uData.Offerpayment.sub.id, hooks);
          setpayerrinfo(result?.data?.failure_message || result?.data?.failure_code || 'Villa hjá greiðsluaðila');
          setLoading(false);
        };
        getHooks();
      };
    };
  }, [props.uData]);

  const retryPayment = () => {
    setLoading(true);
    const getData = async (id, newcard, subId) => {
      if (newcard && subId) {
        const data = {
          'payment_method': newcard,
        };
        const delSub = await updateSubscription(subId, data);
        if ( delSub.status.status != 'SUCCESS' ) {
          setMessage('Ekki tókst að uppfæra kort.\n Hafðu samband við admin@stevedagskra.is ef vandamál heldur áfram');
          setdisplayHelp(true);
          return;
        };
      }
      const delSub = await paySubinvoice(id);
      if ( delSub.status.status == 'SUCCESS' ) {
        // Add try catch because I don't trust rapyd's response
        try {
          if (delSub.data.payment.redirect_url) {
            setMessage('Bíð eftir staðfestingu');
            window.location.href = delSub.data.payment.redirect_url;
          } else {
            setMessage('Það getur tekið nokkrar mínútur fyrir greiðsluna að fara í gegn.');
            setdisplayHelp(true);
          };
        } catch ( e ) {
          setMessage('Það getur tekið nokkrar mínútur fyrir greiðsluna að fara í gegn.');
          setdisplayHelp(true);
        };
      } else {
        const invoiceDetails = await getInvoice(id);
        if (invoiceDetails && invoiceDetails.data?.payment?.redirect_url) {
          window.location.href = invoiceDetails.data?.payment?.redirect_url;
        } else {
          if (delSub.status.error_code=='ERROR_PAY_INVOICE_PAYMENT_PENDING') {
            setMessage(<p>Þú ert nú þegar með virka greiðslutilraun. <br></br>Hafðu samband við admin@stevedagskra.is ef vandamál heldur áfram.</p>);
          } else {
            setMessage(`Villa kom upp. \n Skilaboð frá greiðsluaðila:\n ${delSub.status.message} \n\n Hafðu samband við admin@stevedagskra.is ef vandamál heldur áfram.`);
          };
        };
        setdisplayHelp(true);
      }
      setLoading(false);
    };
    const unpaidInv = props.uData?.invoices?.filter((invoice) => invoice.status !== 'paid');
    if (unpaidInv) {
      getData(unpaidInv[0].id, props.newcard?.id, props.uData?.Offerpayment?.sub?.id);
    } else {
      setLoading(false);
    };
  };
  // function to delete subscriptions
  const deleteSub = () => {
    const getData = async () => {
      setLoading(true);
      const delSub = await permdeleteSubscription(props.uData?.activeSub?.id);
      if ( delSub.status.status == 'SUCCESS' ) {
        props.refreshData();
        try {
          props.setretryPayment(false);
        } catch (e) {
          console.warn(e);
        };
        // toast.current.show({severity: 'success', summary: 'Áskrift sagt upp', detail: 'Við þökkum viðskiptin. Þú getur enn hlustað til ' + subNextpayment, life: 3000});
      } else {
        setMessage(`Villa kom upp. \n Skilaboð frá greiðsluaðila:\n ${delSub.status.message} \n\n Hafðu samband við admin@stevedagskra.is ef vandamál heldur áfram.`);
        setdisplayHelp(true);
      }
      setLoading(false);
    };
    setLoading(true);
    getData();
    return () => {
    };
  };
  const acceptDelete = () => {
    deleteSub();
  };
  const reject = () => {

  };
  const confirm1 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Viltu segja upp núverandi áskrift?',
      icon: 'pi pi-exclamation-triangle',
      className: 'c-stadfesta',
      accept: acceptDelete,
      reject,
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Já',
      rejectLabel: 'Nei',
    });
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="Í lagi" onClick={() => setdisplayHelp(false)} autoFocus />
      </div>
    );
  };
  const renderdispFooter = (name) => {
    return (
      <div className="c-legal-btns">
        <Button label="Greiða" onClick={(e) => retryPayment()} loading={loading} autoFocus className="p-button-warning w-full"/>
        {!props.redirect ? <Button label="Segja upp áskrift" onClick={(e) => confirm1(e)} disabled={loading} className="p-button-outlined p-button-danger w-full mt-2"/> :
        <Button label="Breyta áskrift" onClick={(e) => getSubscriptionPage()} disabled={loading} className="p-button-outlined p-button-danger w-full mt-2"/>
        }
      </div>
    );
  };
  const modal = () => {
    return (
      <Dialog visible={props.openRetrypayment} className="c-retry-payment" footer={renderdispFooter()} onHide={() => props.setretryPayment(false)}>
        <div className="w-full flex flex-column align-items-center">
          <div className="c-retry-image m-auto">
            <Image src={errimg} template="p-image-action" alt="Image Text" />
          </div>
          <p>Ekki tókst að rukka fyrir áskriftina.</p>
          Ástæða: {payerrinfo}
          <br />
          <br />
        </div>
      </Dialog>
    );
  };
  return (
    <>
      {props.type == 'modal' ? modal() : renderdispFooter()}
      <Dialog className="c-master-err-dialog" header="Skilaboð" visible={displayHelp} style={{width: '95vw'}} footer={renderFooter('displayBasic')} onHide={() => setdisplayHelp(false)}>
        {message}
      </Dialog>
    </>
  );
};
export default Retrypayment;
Retrypayment.propTypes = {
  openRetrypayment: PropTypes.any,
  setretryPayment: PropTypes.any,
  uData: PropTypes.any,
  refreshData: PropTypes.any,
  type: PropTypes.any,
  newcard: PropTypes.any,
  redirect: PropTypes.any,
};
